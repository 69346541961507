import { IInvite, IInviteConfiguration } from "@hulanbv/ssllow-packages";
import { CrudService, IHttpOptions } from "nest-utilities-client";
import { environment } from "../constants/environment.constant";
import { httpService } from "./http.service";

class Service extends CrudService<IInvite> {
  constructor() {
    super([environment.REACT_APP_API_URL, "invite"].join("/"), httpService);
  }

  getByTeam(teamId: string, options?: IHttpOptions) {
    return httpService.get(
      [this.controller, "team", teamId].join("/"),
      options
    );
  }

  sendInvites(body: IInviteConfiguration | FormData) {
    return httpService.post([this.controller, "configuration"].join("/"), body);
  }

  sendInvitationMail(inviteId: string) {
    return httpService.post(
      [this.controller, inviteId, "mail"].join("/"),
      null
    );
  }
}

export const inviteService = new Service();
