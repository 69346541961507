import React, { ButtonHTMLAttributes, FC, useRef, useState } from "react";
import { style } from "typestyle";
import chevronDownIcon from "../../assets/svg/icons/chevron-down.svg";
import { dictionary } from "../../constants/i18n/dictionary";
import { Button } from "./button";
import { Dropdown } from "./dropdown";
import { IOptionTemplate } from "./search";

interface IProps<OptionType> {
  appearance?: "default" | "primary";
  size?: "small" | "normal";
  attributes?: ButtonHTMLAttributes<HTMLButtonElement>;

  label: string;
  options: OptionType[];
  optionTemplate: FC<IOptionTemplate<OptionType>>;
  onClick?: (item: OptionType, event: React.MouseEvent) => void;
  disableStyles?: boolean;
}

export function DropdownButton<OptionType>(props: IProps<OptionType>) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    if (!isOpen) {
      const hideDropdown = (event: MouseEvent) => {
        if (ref.current?.contains(event.target as HTMLDivElement)) {
          return;
        }
        setIsOpen(false);
        document.body.removeEventListener("click", hideDropdown);
      };
      document.body.addEventListener("click", hideDropdown);
    }

    setIsOpen(!isOpen);
  };

  return (
    <div className={styles.buttonDropdown} ref={ref}>
      <Button size={"small"} attributes={{ onClick: toggleDropdown }}>
        {props.label}
        <img
          src={chevronDownIcon}
          alt={dictionary.open}
          width={13}
          style={{ margin: "0 0 -3px 3px" }}
        />
      </Button>
      <Dropdown
        attributes={{
          className: styles.dropdown,
          style: { display: isOpen ? "block" : "none" },
        }}
        options={props.options}
        optionTemplate={props.optionTemplate}
        disableStyles={props.disableStyles}
      />
    </div>
  );
}

const styles = {
  buttonDropdown: style({
    display: "inline-block",
    position: "relative",
  }),
  dropdown: style({
    position: "absolute",
    top: "100%",
    left: 0,
    overflowY: "initial",
  }),
  option: style({
    whiteSpace: "nowrap",
    fontSize: 12,
  }),
};
