import { Role } from "@hulanbv/ssllow-packages";
import React, { FC } from "react";
import { style } from "typestyle";
import { Card } from "../components/containers/card";
import { CrudForm } from "../components/forms/crud-form";
import { OrganisationForm } from "../components/forms/organisation-form";
import { routes } from "../components/routing/routes";
import { dictionary } from "../constants/i18n/dictionary";
import { IViewProps } from "../interfaces/view-props.interface";
import { organisationService } from "../services/organisation.service";
import { satisfiesRoles } from "../utilities/satisfies-roles";

export const OrganisationEditView: FC<IViewProps> = (props) => (
  <Card attributes={{ className: styles.card }}>
    <CrudForm
      messageSubject={dictionary.organisation}
      form={OrganisationForm}
      modelId={props.routing.params.id}
      redirectPath={routes.organisationDetails.path.replace(
        ":id",
        props.routing.params.id
      )}
      service={organisationService}
      readOnly={!satisfiesRoles(Role.ADMIN, Role.EXPERT)}
    />
  </Card>
);

const styles = {
  card: style({
    maxWidth: 550,
    margin: "0 auto",
  }),
};
