/**
 * Transforms a FormData object into a JSON object honoring the same structure.
 * @param data
 */
export function formDataToObject<Type = Object>(data: FormData): Type {
  const object: { [key: string]: any } = {};
  data.forEach((value, key) => {
    let ref = object;
    const path = key.replace(/\]/g, "").split("[");
    for (let i = 0; i < path.length; i++) {
      // set the value if we're at the last iteration
      if (i === path.length - 1) {
        ref[path[i]] = value;
      }
      // define an array if the next key is a number
      else if (isNaN(+path[i + 1]) === false) {
        ref[path[i]] = ref[path[i]] || [];
      }
      // define an object otherwise
      else {
        ref[path[i]] = ref[path[i]] || {};
      }

      // forward the reference
      ref = ref[path[i]];
    }
  });

  return object as Type;
}
