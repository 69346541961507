import { cssRaw, cssRule, fontFace } from "typestyle";
import rnsSanzLight from "./assets/fonts/RNSSanz-Light.otf";
import rnsSanzNormal from "./assets/fonts/RNSSanz-Normal.otf";
import rnsSanzSemiBold from "./assets/fonts/RNSSanz-SemiBold.otf";

export const setBaseStyling = () => {
  fontFace({
    fontFamily: "RNS Sanz",
    fontStyle: "light",
    fontWeight: 300,
    src: `url(${rnsSanzLight})`,
  });
  fontFace({
    fontFamily: "RNS Sanz",
    fontStyle: "normal",
    fontWeight: 400,
    src: `url(${rnsSanzNormal})`,
  });
  fontFace({
    fontFamily: "RNS Sanz",
    fontStyle: "bold",
    fontWeight: 500,
    src: `url(${rnsSanzSemiBold})`,
  });

  cssRaw(`
      html {
        -webkit-print-color-adjust: exact;
        -webkit-filter: blur(0);
      }
      body {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background: #FCFDFF;
        margin: 0;
        padding: 0;
      }

      .rich-text > div * {
        z-index: 0;
      }

      a { 
        color: #3498dbf0;
      }

      p { 
        line-height: 1.6;
      }


      @media screen {
        body {
          font-size: 14px;
        }
      }
      @media print {
        body {
          background: none;
          font-size: 13px;
        }
      }
    `);
  cssRule("strong", {
    fontWeight: 700,
  });

  cssRule("*", {
    boxSizing: "border-box",
    fontFamily: "RNS Sanz, sans-serif",
    fontWeight: 400,
    color: "#707070",

    $nest: {
      "@media print": {
        color: "#000",
      },
    },
  });

  /**
   * Overrides
   */
  cssRaw(`
  .ql-editor p, 
  .ql-editor ol, 
  .ql-editor ul, 
  .ql-editor pre, 
  .ql-editor blockquote, 
  .ql-editor h1, 
  .ql-editor h2, 
  .ql-editor h3, 
  .ql-editor h4, 
  .ql-editor h5, 
  .ql-editor h6 {
    padding: 0 !important;
    margin: 1em !important;
  }
  .ql-editor {
    padding: 0 !important;
  }
  .ql-editor::before {
    margin: 1em !important;
  }
    `);
};
