import React, { FC, useEffect, useState } from "react";
import { classes, style } from "typestyle";
import outIcon from "../../assets/svg/icons/arrow-out-circle.svg";
import brandLogo from "../../assets/svg/icons/logo-white.svg";
import { dictionary } from "../../constants/i18n/dictionary";
import { routeListener } from "../../observables/route-listener";
import { Navigation } from "../controls/navigation";
import { NavigationItem } from "../controls/navigation-item";
import { routes } from "../routing/routes";

// keep track of pages without navigation
const navlessPaths = [routes.login.path, routes.logout.path];

export const SideBar: FC = () => {
  const [hasNavigation, setHasNavigation] = useState<boolean>(true);

  useEffect(() => {
    const listener = routeListener.subscribe((route) => {
      setHasNavigation(!navlessPaths.includes(route.path));
    });

    return () => listener.unsubscribe();
  }, []);

  if (!hasNavigation) {
    return <></>;
  }

  return (
    <div className={styles.sideBar}>
      <div>
        <div className={styles.brand} />
        <Navigation />
      </div>

      <NavigationItem route={routes.logout}>
        <img src={outIcon} alt={dictionary.logout} /> {dictionary.logout}
      </NavigationItem>
    </div>
  );
};

const styles = {
  sideBar: classes(
    "hide-on-print",
    style({
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      background: "#180430",
      width: 255,
      padding: 50,
    })
  ),

  brand: style({
    width: 134,
    height: 62,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center center",
    backgroundImage: `url(${brandLogo})`,
    margin: "0 auto 70px auto",
  }),
};
