import {
  ICategory,
  IGenre,
  IReportTemplateSection,
  SectionType,
} from "@hulanbv/ssllow-packages";
import React, { FC, useCallback, useEffect, useState } from "react";
import { classificationLabels } from "../../constants/classification-labels";
import { reportContextCodes } from "../../constants/context-code-trees";
import { dictionary } from "../../constants/i18n/dictionary";
import { resultTypeLabels } from "../../constants/result-type-labels";
import { sectionTypeLabels } from "../../constants/section-type-labels";
import { IFormProps } from "../../interfaces/form-props.interface";
import { IOption } from "../../interfaces/option.interface";
import { categoryService } from "../../services/category.service";
import { genreService } from "../../services/genre.service";
import { searchOption } from "../../utilities/search.utils";
import { Button } from "../controls/button";
import { Checkbox } from "../controls/checkbox";
import { Input } from "../controls/input";
import { OptionOption } from "../controls/option-templates/option-option";
import { RichText } from "../controls/rich-text";
import { Search } from "../controls/search";
import { Select } from "../controls/select";
import { TemplateSectionSortSelect } from "../elements/template-section-sort-select";
import { Form } from "./form";
import { FormRow } from "./form-row";

export const ReportTemplateSectionForm: FC<
  IFormProps<IReportTemplateSection>
> = (props) => {
  const [categories, setCategories] = useState<ICategory[] | null>(null);
  const [genres, setGenres] = useState<IGenre[] | null>(null);
  const [type, setType] = useState<SectionType | null>(
    props.model?.type ?? null
  );

  const handleSubmit = useCallback(
    async (formData: FormData, model: IReportTemplateSection) => {
      // cast all string from the formData to their respective types
      model.type = +model.type;
      model.pageBreakBefore =
        (model.pageBreakBefore ?? false).toString() === "true";
      model.pageBreakAfter =
        (model.pageBreakAfter ?? false).toString() === "true";
      model.ignorePreviousSectionQuestions =
        (model.ignorePreviousSectionQuestions ?? false).toString() === "true";
      model.centerDescription =
        (model.centerDescription ?? false).toString() === "true";
      model.showPartnerResultType =
        (model.showPartnerResultType ?? false).toString() === "true";

      props.onSubmit?.(formData, model);
    },
    [props.onSubmit]
  );

  useEffect(() => {
    const fetchCategories = async () => {
      const { data } = await categoryService.getAll();
      setCategories(data);
    };
    const fetchGenres = async () => {
      const { data } = await genreService.getAll();
      setGenres(data);
    };

    fetchCategories();
    fetchGenres();
  }, [props.model]);

  if (!categories || !genres) {
    return <></>;
  }

  const categoryOptions = categories.map((category) => ({
    key: category.id,
    value: category.name,
  }));
  const genreOptions = genres.map((genre) => ({
    key: genre.id,
    value: genre.name,
  }));
  const classificationOptions = Object.keys(classificationLabels).map(
    (key) => ({
      key,
      value: (classificationLabels as any)[key],
    })
  );
  const resultTypeOptions = Object.keys(resultTypeLabels).map((key) => ({
    key,
    value: (resultTypeLabels as any)[key],
  }));

  return (
    <Form {...{ ...props, onSubmit: handleSubmit }}>
      <FormRow>
        <Select
          options={sectionTypeLabels}
          label={dictionary.type}
          attributes={{
            disabled: props.disabled,
            defaultValue: props.model?.type,
            name: "type",
            required: true,
            onChange: ({ currentTarget }) => setType(+currentTarget.value),
          }}
        />
      </FormRow>

      <FormRow>
        <Input
          label={dictionary.title}
          attributes={{
            disabled: props.disabled,
            defaultValue: props.model?.title,
            name: "title",
          }}
        />
      </FormRow>
      <FormRow>
        <div>
          <RichText
            outputType="html"
            label={dictionary.content}
            defaultValue={props.model?.description}
            name={"description"}
            selectionTree={reportContextCodes}
          />
          <Checkbox
            label={dictionary.center_description}
            attributes={{
              disabled: props.disabled,
              checked: props.model?.centerDescription,
              name: "centerDescription",
            }}
          />
        </div>
      </FormRow>

      <div
        style={{
          display: [SectionType.TEXT, SectionType.LEGEND, null].includes(type)
            ? "none"
            : undefined,
        }}
      >
        <FormRow
          attributes={{
            style: {
              display:
                type === SectionType.DISCUSSION_REPORT ? "none" : undefined,
            },
          }}
        >
          <Select
            options={{
              false: dictionary.participant,
              true: dictionary.partner__team,
            }}
            label={dictionary.visualize_result_types_by}
            attributes={{
              disabled: props.disabled,
              defaultValue: props.model?.showPartnerResultType?.toString(),
              name: "showPartnerResultType",
            }}
            noEmptyOption
          />
        </FormRow>

        <TemplateSectionSortSelect sortBy={props.model?.sortBy ?? []} />

        <FormRow>
          <Input
            label={dictionary.maximum_number_of_questions}
            attributes={{
              disabled: props.disabled,
              defaultValue: props.model?.limit,
              type: "number",
              name: "limit",
            }}
          />
        </FormRow>

        <FormRow>
          <Input
            label={
              dictionary.minimal_deviation_between_invitee_and_partner_answer
            }
            attributes={{
              disabled: props.disabled,
              defaultValue: props.model?.minReviewDeviationFilter,
              placeholder: "0",
              type: "number",
              name: "minReviewDeviationFilter",
            }}
          />
        </FormRow>

        <FormRow>
          <Search<IOption>
            keyField="key"
            optionTemplate={OptionOption}
            find={async (q) => searchOption(q, categoryOptions)}
            label={dictionary.filter_on_category}
            name="questionFilters[question.categoryIds]"
            defaultValue={categoryOptions.filter((o) =>
              (
                props.model?.questionFilters?.["question.categoryIds"] ||
                categories.map(({ id }) => id)
              ).includes(o.key)
            )}
            openOnFocus
            multiple
          />
        </FormRow>

        <FormRow>
          <Search<IOption>
            keyField="key"
            optionTemplate={OptionOption}
            find={async (q) => searchOption(q, genreOptions)}
            label={dictionary.filter_on_genre}
            name="questionFilters[question.genreId]"
            defaultValue={genreOptions.filter((o) =>
              (
                props.model?.questionFilters?.["question.genreId"] ||
                genres.map(({ id }) => id)
              ).includes(o.key)
            )}
            openOnFocus
            multiple
          />
        </FormRow>

        <FormRow>
          <Search<IOption>
            keyField="key"
            optionTemplate={OptionOption}
            find={async (q) => searchOption(q, classificationOptions)}
            label={dictionary.filter_on_classification}
            name="questionFilters[question.classification]"
            defaultValue={classificationOptions.filter(({ key }) =>
              (
                props.model?.questionFilters?.["question.classification"] ||
                Object.keys(classificationLabels)
              ).includes(key)
            )}
            openOnFocus
            multiple
          />
        </FormRow>

        <FormRow>
          <Search<IOption>
            keyField="key"
            optionTemplate={OptionOption}
            find={async (q) => searchOption(q, resultTypeOptions)}
            label={dictionary.filter_on_result_type}
            name="questionFilters[resultType]"
            defaultValue={resultTypeOptions.filter((o) =>
              (
                props.model?.questionFilters?.resultType ||
                Object.keys(resultTypeLabels)
              ).includes(o.key)
            )}
            openOnFocus
            multiple
          />
        </FormRow>

        <FormRow>
          <Search<IOption>
            keyField="key"
            optionTemplate={OptionOption}
            find={async (q) => searchOption(q, resultTypeOptions)}
            label={dictionary.filter_on_partner_result_type}
            name="questionFilters[partnerResultType]"
            defaultValue={resultTypeOptions.filter((o) =>
              (
                props.model?.questionFilters?.partnerResultType ||
                Object.keys(resultTypeLabels)
              ).includes(o.key)
            )}
            openOnFocus
            multiple
          />
        </FormRow>

        <FormRow>
          <Checkbox
            label={dictionary.omit_questions_occurring_in_previous_section}
            attributes={{
              disabled: props.disabled,
              checked: props.model?.ignorePreviousSectionQuestions,
              name: "ignorePreviousSectionQuestions",
            }}
          />
        </FormRow>
      </div>

      <FormRow>
        <Checkbox
          label={dictionary.new_page_before}
          attributes={{
            disabled: props.disabled,
            checked: props.model?.pageBreakBefore,
            name: "pageBreakBefore",
          }}
        />
        <Checkbox
          label={dictionary.new_page_after}
          attributes={{
            disabled: props.disabled,
            checked: props.model?.pageBreakAfter,
            name: "pageBreakAfter",
          }}
        />
      </FormRow>

      <FormRow>
        <FormRow>
          <Button
            attributes={{ type: "submit", disabled: props.disabled }}
            appearance="primary"
          >
            {dictionary.submit}
          </Button>
        </FormRow>
      </FormRow>
    </Form>
  );
};
