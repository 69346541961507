import React, { FC } from "react";
import { stylesheet } from "typestyle";
import snailSvg from "../../assets/svg/icons/snail.svg";

interface IProps {
  shellContent?: string;
}

export const Snail: FC<IProps> = (props) => {
  return (
    <div className={styles.shell}>
      <div className={styles.shellContent}>{props.shellContent}</div>
    </div>
  );
};

const styles = stylesheet({
  shell: {
    backgroundImage: `url(${snailSvg})`,
    backgroundRepeat: "no-repeat",
    width: 78,
    height: 48,
  },
  shellContent: {
    padding: "0px 23px 0 15px",
    color: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 42,
  },
});
