import { IInvite } from "@hulanbv/ssllow-packages";
import React, { FC, useEffect, useState } from "react";
import Router from "react-history-router";
import { style } from "typestyle";
import { Card } from "../components/containers/card";
import { ReportForm } from "../components/forms/report-form";
import { routes } from "../components/routing/routes";
import { IViewProps } from "../interfaces/view-props.interface";
import { inviteService } from "../services/invite.service";
import { reportService } from "../services/report.service";

export const InviteReportConfigurationView: FC<IViewProps> = (props) => {
  const [invite, setInvite] = useState<IInvite | null>(null);

  const submitReport = async (formData: FormData) => {
    const { data } = await reportService.post(formData);
    Router.push(routes.reportDetails.path, { id: data.id });
  };

  useEffect(() => {
    const fetchInvite = async () => {
      try {
        const { data } = await inviteService.get(props.routing.params.id, {
          populate: ["user", "organisation", "participations", "survey"],
        });
        setInvite(data);
      } catch {
        Router.push(routes.reports.path);
      }
    };
    fetchInvite();
  }, [props.routing.params.id]);

  if (!invite?.participations) {
    return <></>;
  }
  return (
    <Card attributes={{ className: styles.card }}>
      <ReportForm
        onSubmit={submitReport}
        model={{
          participationIds: invite.participations.map((p) => p.id),
          participations: invite.participations,
          userId: invite.userId,
          user: invite.user,
          organisationId: invite.organisationId,
          organisation: invite.organisation,
          surveyId: invite.surveyId,
          survey: invite.survey,
        }}
      />
    </Card>
  );
};

const styles = {
  card: style({
    maxWidth: 650,
    margin: "0 auto",
  }),
};
