import React, { FC, HTMLAttributes } from "react";
import { style } from "typestyle";

interface IProps {
  attributes?: HTMLAttributes<HTMLDivElement>;
}

export const FormRow: FC<IProps> = (props) => (
  <div
    {...props.attributes}
    className={[styles.formRow, props.attributes?.className].join(" ")}
  >
    {props.children}
  </div>
);

const styles = {
  formRow: style({
    padding: "10px 0",
    display: "flex",
    flexWrap: "wrap",

    $nest: {
      "& > *": {
        flex: 1,
        margin: "0 5px",
      },
    },
  }),
};
