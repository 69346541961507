import React, { FC } from "react";
import { Router } from "react-history-router";
import { routes } from "../components/routing/routes";
import { IViewProps } from "../interfaces/view-props.interface";
import { authenticationService } from "../services/authentication.service";

export const LogoutView: FC<IViewProps> = (props) => {
  // log out of the application and navigate to login
  const logout = async () => {
    if (authenticationService.getSession()) {
      try {
        await authenticationService.logout();
      } catch {}
    }

    Router.replace(routes.login.path);
  };
  logout();

  return <></>;
};
