import { ISurvey } from "@hulanbv/ssllow-packages";
import { CrudService, IResponse } from "nest-utilities-client";
import { environment } from "../constants/environment.constant";
import { httpService } from "./http.service";

class Service extends CrudService<ISurvey> {
  constructor() {
    super([environment.REACT_APP_API_URL, "survey"].join("/"), httpService);
  }

  duplicate(
    baseSurveyId: string,
    survey: FormData | ISurvey
  ): Promise<IResponse<ISurvey>> {
    return httpService.post(
      [this.controller, baseSurveyId, "duplicate"].join("/"),
      survey
    );
  }
}

export const surveyService = new Service();
