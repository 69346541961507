import { dictionary } from "../../constants/i18n/dictionary";
import { CategoriesView } from "../../views/categories.view";
import { CategoryEditView } from "../../views/category-edit.view";
import { GenreEditView } from "../../views/genre-edit.view";
import { GenresView } from "../../views/genres.view";
import { HomeView } from "../../views/home.view";
import { InviteEditView } from "../../views/invite-edit.view";
import { InviteReportConfigurationView } from "../../views/invite-report-configuration.view";
import { InviteView } from "../../views/invite.view";
import { InvitesView } from "../../views/invites.view";
import { LoginView } from "../../views/login.view";
import { LogoutView } from "../../views/logout.view";
import { MotivationEditView } from "../../views/motivation-edit.view";
import { MotivationsView } from "../../views/motivations.view";
import { NewsEditView } from "../../views/news-edit.view";
import { NewsView } from "../../views/news.view";
import { OrganisationDetailsView } from "../../views/organisation-details.view";
import { OrganisationEditView } from "../../views/organisation-edit.view";
import { OrganisationsView } from "../../views/organisations.view";
import { PublicInviteEditView } from "../../views/public-invite-edit.view";
import { QuestionMicroLearningsView } from "../../views/question-microlearnings.view";
import { ReportDetailsView } from "../../views/report-details.view";
import { ReportEditView } from "../../views/report-edit.view";
import { ReportTemplateEditView } from "../../views/report-template-edit.view";
import { ReportTemplatesView } from "../../views/report-templates.view";
import { SurveyDetailsView } from "../../views/survey-details.view";
import { SurveyEditView } from "../../views/survey-edit.view";
import { SurveysView } from "../../views/surveys.view";
import { TeachingAidEditView } from "../../views/teaching-aid-edit.view";
import { TeachingAidsView } from "../../views/teaching-aids.view";
import { UserDetailsView } from "../../views/user-details.view";
import { UserEditView } from "../../views/user-edit.view";
import { UsersView } from "../../views/users.view";

export const routes = {
  home: {
    path: "/",
    component: HomeView,
    data: {
      title: "Slow",
    },
  },
  createInvite: {
    path: "/invites/new",
    component: InviteView,
    data: {
      title: dictionary.invite,
    },
  },
  editInvite: {
    path: "/invites/:id/edit",
    component: InviteEditView,
    data: {
      title: dictionary.edit_invite,
    },
  },
  categories: {
    path: "/categories",
    component: CategoriesView,
    data: {
      title: dictionary.categories,
    },
  },
  createCategory: {
    path: "/categories/new",
    component: CategoryEditView,
    data: {
      title: dictionary.create_category,
    },
  },
  editCategory: {
    path: "/categories/:id/edit",
    component: CategoryEditView,
    data: {
      title: dictionary.edit_category,
    },
  },
  genres: {
    path: "/genres",
    component: GenresView,
    data: {
      title: dictionary.genres,
    },
  },
  createGenre: {
    path: "/genres/new",
    component: GenreEditView,
    data: {
      title: dictionary.create_genre,
    },
  },
  editGenre: {
    path: "/genres/:id/edit",
    component: GenreEditView,
    data: {
      title: dictionary.edit_genre,
    },
  },
  motivations: {
    path: "/motivations",
    component: MotivationsView,
    data: {
      title: dictionary.motivation,
    },
  },
  createMotivation: {
    path: "/motivations/new",
    component: MotivationEditView,
    data: {
      title: dictionary.create_motivation,
    },
  },
  editMotivation: {
    path: "/motivations/:id/edit",
    component: MotivationEditView,
    data: {
      title: dictionary.edit_motivation,
    },
  },
  teachingAids: {
    path: "/teaching-aids",
    component: TeachingAidsView,
    data: {
      title: dictionary.teaching_aid,
    },
  },
  createTeachingAid: {
    path: "/teaching-aids/new",
    component: TeachingAidEditView,
    data: {
      title: dictionary.create_teaching_aid,
    },
  },
  editTeachingAid: {
    path: "/teaching-aids/:id/edit",
    component: TeachingAidEditView,
    data: {
      title: dictionary.edit_teaching_aid,
    },
  },
  news: {
    path: "/news",
    component: NewsView,
    data: {
      title: dictionary.news,
    },
  },
  createNews: {
    path: "/news/new",
    component: NewsEditView,
    data: {
      title: dictionary.create_news,
    },
  },
  editNews: {
    path: "/news/:id/edit",
    component: NewsEditView,
    data: {
      title: dictionary.edit_news,
    },
  },
  organisations: {
    path: "/organisations",
    component: OrganisationsView,
    data: {
      title: dictionary.organisations,
    },
  },
  createOrganisation: {
    path: "/organisations/new",
    component: OrganisationEditView,
    data: {
      title: dictionary.create_organisation,
    },
  },
  organisationDetails: {
    path: "/organisations/:id",
    component: OrganisationDetailsView,
    data: {
      title: dictionary.organisation_details,
    },
  },
  editOrganisation: {
    path: "/organisations/:id/edit",
    component: OrganisationEditView,
    data: {
      title: dictionary.edit_organisation,
    },
  },
  reportTemplates: {
    path: "/report-templates",
    component: ReportTemplatesView,
    data: {
      title: dictionary.templates,
    },
  },
  reportTemplateEdit: {
    path: "/report-templates/:id",
    component: ReportTemplateEditView,
    data: {
      title: dictionary.edit_template,
    },
  },
  reports: {
    path: "/reports",
    component: InvitesView,
    data: {
      title: dictionary.reports,
    },
  },
  createReport: {
    path: "/reports/new",
    component: ReportEditView,
    data: {
      title: dictionary.create_report,
    },
  },
  reportDetails: {
    path: "/reports/:id",
    component: ReportDetailsView,
    data: {
      title: dictionary.report,
    },
  },
  inviteReportConfiguration: {
    path: "/reports/:id/report",
    component: InviteReportConfigurationView,
    data: {
      title: dictionary.generate_report,
    },
  },
  editReport: {
    path: "/reports/:id/edit",
    component: ReportEditView,
    data: {
      title: dictionary.edit_report,
    },
  },
  editPublicInvite: {
    path: "/public-invites/:id/edit",
    component: PublicInviteEditView,
    data: {
      title: dictionary.invite_links,
    },
  },
  surveys: {
    path: "/surveys",
    component: SurveysView,
    data: {
      title: dictionary.reflectors,
    },
  },
  createSurvey: {
    path: "/surveys/new",
    component: SurveyEditView,
    data: {
      title: dictionary.create_reflector,
    },
  },
  surveyDetails: {
    path: "/surveys/:id",
    component: SurveyDetailsView,
    data: {
      title: dictionary.reflector_details,
    },
  },
  editSurvey: {
    path: "/surveys/:id/edit",
    component: SurveyEditView,
    data: {
      title: dictionary.edit_reflector,
    },
  },
  questionMicrolearnings: {
    path: "/invites/:inviteId/questions/:questionId/microlearnings",
    component: QuestionMicroLearningsView,
    data: {
      title: dictionary.microlearnings,
    },
  },
  users: {
    path: "/users",
    component: UsersView,
    data: {
      title: dictionary.users,
    },
  },
  createUser: {
    path: "/users/new",
    component: UserEditView,
    data: {
      title: dictionary.create_user,
    },
  },
  userDetails: {
    path: "/users/:id",
    component: UserDetailsView,
    data: {
      title: dictionary.user_details,
    },
  },
  editUser: {
    path: "/users/:id/edit",
    component: UserEditView,
    data: {
      title: dictionary.edit_user,
    },
  },
  login: {
    path: "/login",
    component: LoginView,
    data: {
      title: "",
    },
  },
  logout: {
    path: "/logout",
    component: LogoutView,
    data: {
      title: dictionary.logout,
    },
  },
};
