import { ICategory } from "@hulanbv/ssllow-packages";
import React, { FC, HTMLAttributes } from "react";
import { StyledTR } from "../styled-table-row";
import { TD } from "../table-data";

interface IProps {
  category: ICategory;
  attributes?: HTMLAttributes<HTMLDivElement>;
}

export const CategoryRow: FC<IProps> = (props) => (
  <StyledTR attributes={props.attributes}>
    <TD>{props.category.name}</TD>
    <TD>{props.category.subcategories?.length ?? 0}</TD>
  </StyledTR>
);
