import { IQuestionReport } from "@hulanbv/ssllow-packages";
import React, { FC, useEffect, useState } from "react";
import { style } from "typestyle";
import { IReportProps } from "../../interfaces/report-props.interface";
import { Dictionary } from "../../types/dictionary.type";
import { SummaryReportItem } from "./summary-report-item";

export const CategorySummaryReport: FC<IReportProps> = (props) => {
  const [categoryResults, setCategoryResults] = useState<
    Dictionary<IQuestionReport[]>
  >({});

  useEffect(() => {
    setCategoryResults(
      props.results.reduce<Dictionary<IQuestionReport[]>>((prev, curr) => {
        curr.question.categories?.forEach((category) => {
          prev[category.name] = prev[category.name] || [];
          prev[category.name].push(curr);
        });
        return prev;
      }, {})
    );
  }, [props.results]);

  return (
    <div
      {...props.attributes}
      className={[styles.report, props.attributes?.className].join(" ")}
    >
      {Object.entries(categoryResults).map(([categoryName, results], i) => (
        <SummaryReportItem
          key={i}
          attributes={{ className: styles.item }}
          title={categoryName || "-"}
          results={results}
          section={props.section}
          reviewColors={props.reviewColors}
        />
      ))}
    </div>
  );
};

const styles = {
  report: style({
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginBottom: 25,
    pageBreakInside: "avoid",
  }),
  item: style({
    marginBottom: 25,
  }),
};
