import React, {
  FC,
  Fragment,
  HTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { style } from "typestyle";
import { dictionary } from "../../constants/i18n/dictionary";
import { Button } from "../controls/button";
import { Search } from "../controls/search";
import { Label } from "../typography/label";

interface IProps {
  attributes?: HTMLAttributes<HTMLDivElement>;
  onChange: (filter: string) => void | Promise<void>;
  labels: string[];
  defaultFolder?: string;
}

export const FolderStructureFilter: FC<IProps> = (props) => {
  const [currentFolder, setCurrentFolder] = React.useState<string>(
    props.defaultFolder ?? ""
  );
  const [folders, setFolders] = React.useState<string[]>([]);
  const [layerFolders, setLayerFolders] = React.useState<string[]>([]);
  const passedFolderStructure = useMemo(
    () => currentFolder.split("/").filter(Boolean),
    [currentFolder]
  );

  useEffect(() => {
    setFolders(
      Array.from(new Set(props.labels.map((label) => label.toLowerCase())))
    );
  }, [props.labels]);

  useEffect(() => {
    const inScopeFolders = folders.filter(
      (folder) => folder.startsWith(currentFolder) && folder !== currentFolder
    );
    const depth = currentFolder === "" ? 0 : currentFolder.split("/").length;
    const layerFolders = inScopeFolders.map((folder) =>
      folder
        .trim()
        .split("/")
        .slice(0, depth + 1)
        .join("/")
    );

    setLayerFolders(Array.from(new Set(layerFolders)));
  }, [currentFolder, folders]);

  useEffect(() => {
    props.onChange(currentFolder);
  }, [currentFolder, props]);

  const findLayerFolders = useCallback(
    (query) =>
      layerFolders
        .filter((folder) => folder.toLowerCase().includes(query.toLowerCase()))
        .map((folder) => ({ path: folder })),
    [layerFolders]
  );

  return (
    <div {...props.attributes}>
      <Label>{dictionary.filter_on_label}</Label>
      <div className={styles.pathContainer}>
        <Button
          attributes={{
            title: dictionary.go_a_step_back,
            onClick: () => setCurrentFolder(""),
            className: styles.folder,
            disabled: currentFolder === "",
          }}
          appearance={currentFolder === "" ? "primary" : "default"}
        >
          /
        </Button>
        {passedFolderStructure.map((_, i) => {
          const isLastItem = i + 1 === passedFolderStructure.length;
          const folderPath = currentFolder
            .split("/")
            .slice(0, i + 1)
            .join("/");
          return (
            <Fragment key={folderPath}>
              <Button
                attributes={{
                  title: folderPath,
                  onClick: () => setCurrentFolder(folderPath),
                  className: styles.folder,
                  disabled: isLastItem,
                }}
                appearance={isLastItem ? "primary" : "default"}
              >
                {folderPath.split("/").pop()}
              </Button>
              {(!isLastItem || layerFolders.length > 0) && <div>/</div>}
            </Fragment>
          );
        })}
        {layerFolders.length > 0 && (
          <Search<{ path: string }>
            key={currentFolder}
            find={findLayerFolders}
            keyField="path"
            optionTemplate={(item) => <>{item.model.path.split("/").pop()}</>}
            onChange={(item) => setCurrentFolder(item[0])}
            placeholder={dictionary.search_label}
            openOnFocus
          />
        )}
      </div>
    </div>
  );
};

const styles = {
  path: style({
    opacity: 0.78,
    marginBottom: 20,
    marginLeft: 15,
  }),
  folder: style({
    width: "auto",
    minWidth: 100,
    height: 50,

    $nest: {
      "&:disabled": {
        opacity: 1,
      },
    },
  }),

  pathContainer: style({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 15,
  }),
};
