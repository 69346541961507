import React, { FC, HTMLAttributes } from "react";
import { style } from "typestyle";

interface IProps {
  imagePath?: string;
  imageHeight?: number;
  attributes?: HTMLAttributes<HTMLDivElement>;
}

export const Card: FC<IProps> = (props) => (
  <div
    {...props.attributes}
    className={[
      styles.card,
      props.attributes?.onClick ? styles.clickable : "",
      props.attributes?.className,
    ].join(" ")}
  >
    {props.imagePath && (
      <div
        className={styles.image}
        style={{
          backgroundImage: `url(${props.imagePath})`,
          height: props.imageHeight,
        }}
      />
    )}
    <div className={styles.content}>{props.children}</div>
  </div>
);

const styles = {
  card: style({
    borderRadius: 13,
    border: "1px solid #dedede",
    background: "#fff",
  }),
  content: style({
    padding: 20,
  }),
  clickable: style({
    cursor: "pointer",
    $nest: {
      "&:hover": {
        background: "#fafafa",
      },
    },
  }),
  image: style({
    width: "100%",
    height: 130,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    borderRadius: 13,
    transition: ".3s height",
  }),
};
