import React, { FC } from "react";
import { style } from "typestyle";
import { IFilter } from "../../../interfaces/filter.interface";
import { Label } from "../../typography/label";
import { Search } from "../search";
import { Select } from "../select";

interface IProps {
  model: IFilter;
}

export const FilterOption: FC<IProps> = (props) => (
  <div className={styles.option}>
    <Label>{props.model.label}</Label>
    {props.model.selectProps && <Select {...props.model.selectProps} />}
    {props.model.searchProps && <Search {...props.model.searchProps} />}
  </div>
);

const styles = {
  option: style({
    margin: "15px 20px",
    width: 250,
  }),
};
