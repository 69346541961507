import React, { FC } from "react";
import { style } from "typestyle";
import poweredBy from "../../assets/images/powered-by-hulan.png";

export const Footer: FC = () => (
  <footer className={styles.footer}>
    <div className={styles.poweredBy} />
  </footer>
);

const styles = {
  footer: style({
    padding: "50px 0 100px 0",
  }),
  poweredBy: style({
    opacity: 0.8,
    width: 71,
    height: 29,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center center",
    background: `url(${poweredBy})`,
    margin: "0 auto",
  }),
};
