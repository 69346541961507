import { IGenre } from "@hulanbv/ssllow-packages/distribution/interfaces/genre.interface";
import React, { FC } from "react";
import { dictionary } from "../../constants/i18n/dictionary";
import { IFormProps } from "../../interfaces/form-props.interface";
import { Button } from "../controls/button";
import { Input } from "../controls/input";
import { Form } from "./form";
import { FormRow } from "./form-row";

export const GenreForm: FC<IFormProps<IGenre>> = (props) => (
  <Form {...props}>
    <input type="hidden" name="id" value={props.model?.id} />
    <input type="hidden" name="_id" value={props.model?.id} />

    <FormRow>
      <Input
        label={dictionary.name}
        attributes={{
          disabled: props.disabled,
          defaultValue: props.model?.name,
          name: "name",
          required: true,
        }}
      />
    </FormRow>

    <FormRow>
      <FormRow>
        <Button
          attributes={{ type: "submit", disabled: props.disabled }}
          appearance="primary"
        >
          {dictionary.submit}
        </Button>
      </FormRow>
    </FormRow>
  </Form>
);
