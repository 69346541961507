import { ITeachingAid, Role } from "@hulanbv/ssllow-packages";
import React, { FC, useEffect, useState } from "react";
import { Router } from "react-history-router";
import { style } from "typestyle";
import { Button } from "../components/controls/button";
import { FolderStructureFilter } from "../components/elements/folder-structure-filter";
import { routes } from "../components/routing/routes";
import { CrudTable } from "../components/tables/crud-table";
import { TeachingAidRow } from "../components/tables/custom-rows/teaching-aid-row";
import { dictionary } from "../constants/i18n/dictionary";
import { IViewProps } from "../interfaces/view-props.interface";
import { teachingAidService } from "../services/teaching-aid.service";
import { satisfiesRoles } from "../utilities/satisfies-roles";

export const TeachingAidsView: FC<IViewProps> = (props) => {
  const [labels, setLabels] = useState<string[]>([]);
  const [currentFolder, setCurrentFolder] = useState<string>(
    new URLSearchParams(window.location.search).get("filter[label]") || ""
  );

  useEffect(() => {
    (async () => {
      const labels = await teachingAidService.getLabels();
      setLabels(labels);
    })();
  }, []);

  return (
    <>
      <FolderStructureFilter
        labels={labels}
        defaultFolder={currentFolder}
        onChange={setCurrentFolder}
        attributes={{ className: styles.folderFilter }}
      />
      <CrudTable<ITeachingAid>
        service={teachingAidService}
        sortKey="title"
        headings={{
          title: dictionary.title,
          type: dictionary.type,
          duration: dictionary.learning_duration_minutes,
        }}
        options={
          currentFolder
            ? {
                filter: {
                  label: currentFolder,
                },
              }
            : undefined
        }
        customRow={(teachingAid) => (
          <TeachingAidRow
            key={teachingAid.id}
            teachingAid={teachingAid}
            attributes={{
              onClick: () =>
                Router.push(routes.editTeachingAid.path, {
                  id: teachingAid.id,
                }),
            }}
          />
        )}
        customControls={
          satisfiesRoles(Role.ADMIN, Role.EXPERT) && (
            <Button
              attributes={{
                onClick: () => Router.push(routes.createTeachingAid.path),
              }}
              appearance={"primary"}
            >
              {dictionary.new}
            </Button>
          )
        }
      />
    </>
  );
};

const styles = {
  folderFilter: style({
    marginBottom: 20,
  }),
};
