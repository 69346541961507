import {
  IQuestionReport,
  IQuestionReview,
  QuestionType,
} from "@hulanbv/ssllow-packages";
import React, { FC, HTMLAttributes } from "react";
import { style } from "typestyle";
import { colors } from "../../constants/colors";
import { UserPicture } from "../elements/user-picture";

export interface IProps {
  attributes?: HTMLAttributes<HTMLDivElement>;
  result: IQuestionReport;
  direction: "left" | "right";
  review: IQuestionReview;
}

export const DiscussionReportUserScore: FC<IProps> = (props) => {
  const scorePositions = props.result.question.points.map((point) =>
    props.result.question.type === QuestionType.DESTRUCTIVE
      ? point + 100
      : point
  );
  return (
    <div
      {...props.attributes}
      className={[styles.report, props.attributes?.className].join(" ")}
      style={{
        flexDirection: props.direction === "left" ? "row" : "row-reverse",
      }}
    >
      <div className={styles.scoreContainer}>
        {Array(5)
          .fill(null)
          .map((x, i) => (
            <div className={styles.scoreLine} key={i} />
          ))}

        <div
          className={styles.scoreBar}
          style={{
            left: props.direction === "right" ? 0 : undefined,
            right: props.direction === "left" ? 0 : undefined,
            width: `${Math.max(
              1,
              scorePositions.indexOf(props.review.score) * 25
            )}%`,
            background: colors.resultTypesPosOrNeg[props.review.resultType],
          }}
        >
          <UserPicture
            color={colors.resultTypesPosOrNeg[props.review.resultType]}
            size={20}
            attributes={{
              className: styles.scoreBubble,
              style: {
                left: props.direction === "left" ? -10 : undefined,
                right: props.direction === "right" ? -10 : undefined,
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

const styles = {
  report: style({
    display: "flex",
    alignItems: "center",
    flex: 1,
  }),
  scoreContainer: style({
    flex: 1,
    justifyContent: "space-between",
    display: "flex",
    margin: "0 30px",
    position: "relative",
  }),
  scoreLine: style({
    width: 1,
    height: 50,
    background: "#eee",
  }),
  scoreBar: style({
    height: 5,
    position: "absolute",
    top: "50%",
    marginTop: -2.5,
  }),
  scoreBubble: style({
    position: "absolute",
    marginTop: -10,
    top: "50%",
  }),
};
