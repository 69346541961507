import { IParticipant, IUser } from "@hulanbv/ssllow-packages";
import React, { FC, HTMLAttributes } from "react";
import { classes, style } from "typestyle";
import { UserPicture } from "./user-picture";

interface IProps {
  attributes?: HTMLAttributes<HTMLDivElement>;
  user: IUser | IParticipant;
  color?: string;
  secondaryText?: string;
  isReversed?: boolean;
}

export const UserTag: FC<IProps> = (props) => (
  <div
    {...{
      ...props.attributes,
      className: classes(
        styles.userTag,
        props.attributes?.className,
        props.isReversed && styles.isReversed
      ),
    }}
  >
    <UserPicture user={props.user} color={props.color} />

    <div className={styles.userInfo}>
      <div
        className={styles.userName}
        style={{ textAlign: props.isReversed ? "right" : undefined }}
      >
        {props.user.firstName} {props.user.lastName}
      </div>
      {props.secondaryText && (
        <div
          className={styles.secondaryText}
          style={{ textAlign: props.isReversed ? "right" : undefined }}
        >
          {props.secondaryText}
        </div>
      )}
    </div>
  </div>
);
const styles = {
  userTag: style({
    display: "flex",
    alignItems: "center",
  }),
  profilePicture: style({
    minWidth: 35,
    height: 35,
    borderRadius: 35,
    border: "2px solid #c4bec4",
    position: "relative",
    flex: 0,
    backgroundSize: "cover",
  }),
  miniShell: style({
    position: "absolute",
    right: -2,
    bottom: -2,
    width: "40%",
    height: "40%",
    backgroundColor: "white",
    borderRadius: "100%",
  }),
  bigShell: style({
    minWidth: 35,
    height: 35,
  }),
  userInfo: style({
    flex: 1,
    margin: "0 15px",
  }),
  userName: style({}),
  secondaryText: style({
    marginTop: 5,
    opacity: 0.54,
  }),

  isReversed: style({
    flexDirection: "row-reverse",
  }),
};
