import React, { FC, HTMLAttributes } from "react";
import { style } from "typestyle";

interface IProps {
  attributes?: HTMLAttributes<HTMLDivElement>;
}

export const TD: FC<IProps> = (props) => (
  <div
    {...props.attributes}
    className={[styles.td, props.attributes?.className].join(" ")}
  >
    {props.children}
  </div>
);

const styles = {
  td: style({
    display: "table-cell",
    verticalAlign: "middle",
  }),
};
