import React, { FC, HTMLAttributes } from "react";
import { style } from "typestyle";
import { Label } from "../typography/label";

interface IProps {
  label?: string;
  attributes?: HTMLAttributes<HTMLDivElement>;
}

export const Row: FC<IProps> = (props) => (
  <div
    {...props.attributes}
    className={[styles.row, props.attributes?.className].join(" ")}
  >
    <Label>{props.label}</Label>
    <div className={styles.content}>{props.children}</div>
  </div>
);

const styles = {
  row: style({
    marginBottom: 20,
  }),
  content: style({
    margin: "0 10px",
  }),
};
