import { IOrganisation } from "@hulanbv/ssllow-packages";
import React, { FC, HTMLAttributes } from "react";
import { style } from "typestyle";
import { StyledTR } from "../styled-table-row";
import { TD } from "../table-data";

interface IProps {
  organisation: IOrganisation;
  attributes?: HTMLAttributes<HTMLDivElement>;
}

export const OrganisationRow: FC<IProps> = (props) => (
  <StyledTR attributes={props.attributes}>
    <TD attributes={{ className: styles.imageTd }}>
      <div
        className={styles.image}
        style={{
          backgroundImage: props.organisation.imagePath
            ? `url(${props.organisation.imagePath})`
            : undefined,
        }}
      />
    </TD>
    <TD>{props.organisation.name}</TD>
    <TD>{props.organisation.city}</TD>
  </StyledTR>
);

const styles = {
  imageTd: style({
    padding: 20,
    paddingRight: 0,
    width: 30,
  }),
  image: style({
    width: 35,
    height: 35,
    backgroundSize: "contain",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
  }),
};
