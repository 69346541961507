import React, { FC } from "react";
import { style } from "typestyle";

interface IProps {
  ascending: boolean;
}

export const SortIcon: FC<IProps> = (props) => (
  <div
    className={[
      styles.icon,
      props.ascending ? styles.ascending : styles.descending,
    ].join(" ")}
  ></div>
);

const styles = {
  icon: style({
    border: "4px solid transparent",
    content: "",
    display: "inline-block",
    height: 0,
    right: 5,
    top: "50%",
    width: 0,
  }),

  ascending: style({
    borderBottomColor: "#666",
    marginTop: -9,
  }),

  descending: style({
    borderTopColor: "#666",
    marginTop: 1,
  }),
};
