import { ITeachingAid } from "@hulanbv/ssllow-packages";
import React, { FC, HTMLAttributes } from "react";
import { teachingAidTypeLabels } from "../../../constants/asset-type-labels";
import { StyledTR } from "../styled-table-row";
import { TD } from "../table-data";

interface IProps {
  teachingAid: ITeachingAid;
  attributes?: HTMLAttributes<HTMLDivElement>;
}

export const TeachingAidRow: FC<IProps> = (props) => (
  <StyledTR attributes={props.attributes}>
    <TD>{props.teachingAid.title}</TD>
    <TD>{teachingAidTypeLabels[props.teachingAid.type]}</TD>
    <TD>{props.teachingAid.duration}</TD>
  </StyledTR>
);
