import React, { FC } from "react";
import { style } from "typestyle";

interface IProps {
  margin?: string;
}
export const HR: FC<IProps> = (props) => (
  <div className={styles.hr} style={{ margin: props.margin }}></div>
);

const styles = {
  hr: style({
    margin: "20px 0 ",
    background: "#eee",
    height: 1,
  }),
};
