import { IParticipation } from "@hulanbv/ssllow-packages";
import React, { FC } from "react";
import { dictionary } from "../../constants/i18n/dictionary";
import { participationService } from "../../services/participation.service";
import { Button } from "../controls/button";
import { ParticipationOption } from "../controls/option-templates/participation-option";
import { RichText } from "../controls/rich-text";
import { Search } from "../controls/search";
import { Form } from "./form";
import { FormRow } from "./form-row";

interface IProps {
  inviteId: string;
  defaultParticipations: IParticipation[];
  defaultValue: string;
  onSubmit: (data: FormData) => any;
}

export const MailParticipantsForm: FC<IProps> = (props) => {
  return (
    <Form {...props} onSubmit={props.onSubmit}>
      <FormRow>
        <Search<IParticipation>
          keyField="id"
          label={dictionary.participants}
          defaultValue={props.defaultParticipations}
          find={async (search) =>
            (
              await participationService.getAll({
                filter: {
                  inviteId: props.inviteId,
                },
                search,
                searchScope: ["participant.firstName", "participant.lastName"],
                sort: ["participant.firstName"],
                limit: 25,
              })
            ).data
          }
          optionTemplate={ParticipationOption}
          name="receiverIds"
          openOnFocus
          multiple
          required
        />
      </FormRow>

      <FormRow>
        <RichText
          defaultValue={props.defaultValue}
          outputType="html"
          label={dictionary.email_without_salutation_and_closing}
          name="content"
        />
      </FormRow>

      <FormRow>
        <FormRow>
          <Button attributes={{ type: "submit" }} appearance="primary">
            {dictionary.submit}
          </Button>
        </FormRow>
      </FormRow>
    </Form>
  );
};
