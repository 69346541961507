import React, { FC, HTMLAttributes } from "react";
import { style } from "typestyle";
import { TD } from "./table-data";

interface IProps {
  attributes?: HTMLAttributes<HTMLDivElement>;
}

export const TH: FC<IProps> = (props) => (
  <TD
    attributes={{
      ...props.attributes,
      className: [styles.th, props.attributes?.className].join(" "),
    }}
  >
    {props.children}
  </TD>
);

const styles = {
  th: style({
    padding: "10px 8px",
  }),
};
