import { ISurvey } from "@hulanbv/ssllow-packages";
import React, { FC } from "react";
import { dictionary } from "../../constants/i18n/dictionary";
import { languageLabels } from "../../constants/language-labels";
import { formatDate } from "../../utilities/date.utils";
import { Row } from "./row";

interface IProps {
  survey: ISurvey;
}

export const SurveyData: FC<IProps> = (props) => (
  <div>
    <Row label={dictionary.name}>{props.survey.name || "-"}</Row>

    <Row label={dictionary.language}>
      {languageLabels[props.survey.language] || "-"}
    </Row>
    <Row label={dictionary.created_at}>
      {formatDate(props.survey.createdAt!.toString(), dictionary.date_format)}
    </Row>
  </div>
);
