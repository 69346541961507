import { ICredentials } from "@hulanbv/ssllow-packages";
import React, { FC } from "react";
import { dictionary } from "../../constants/i18n/dictionary";
import { IFormProps } from "../../interfaces/form-props.interface";
import { Button } from "../controls/button";
import { Input } from "../controls/input";
import { Form } from "./form";
import { FormRow } from "./form-row";

export const LoginForm: FC<IFormProps<ICredentials>> = (props) => (
  <Form {...props}>
    <FormRow>
      <Input
        label={dictionary.username}
        attributes={{
          defaultValue: props.model?.email,
          name: "email",
          required: true,
        }}
      />
    </FormRow>
    <FormRow>
      <Input
        label={dictionary.password}
        attributes={{
          type: "password",
          defaultValue: props.model?.password,
          name: "password",
          required: true,
        }}
      />
    </FormRow>
    <FormRow>
      <FormRow>
        <Button attributes={{ type: "submit" }} appearance="primary">
          {dictionary.login}
        </Button>
      </FormRow>
    </FormRow>
  </Form>
);
