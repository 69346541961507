/* eslint-disable react-hooks/exhaustive-deps */
import {
  IQuestionReport,
  IReport,
  IReportTemplate,
  IReportTemplateSection,
  Role,
  SectionType,
} from "@hulanbv/ssllow-packages";
import { IResponse } from "nest-utilities-client";
import React, { FC, Fragment, useEffect, useMemo, useState } from "react";
import Router from "react-history-router";
import { classes, style } from "typestyle";
import plusIcon from "../assets/svg/icons/plus.svg";
import { Button } from "../components/controls/button";
import { MailReportForm } from "../components/forms/mail-report-form";
import { ReportTemplateForm } from "../components/forms/report-template-form";
import { ReportTemplateSectionForm } from "../components/forms/report-template-section-form";
import { SectionBundle } from "../components/reports/section-bundle";
import { TitlePage } from "../components/reports/title-page";
import { routes } from "../components/routing/routes";
import { colors } from "../constants/colors";
import { dictionary, getLanguage } from "../constants/i18n/dictionary";
import { mailReportMail } from "../constants/templates/mail-report.templates";
import { IViewProps } from "../interfaces/view-props.interface";
import { notificationListener } from "../observables/notification-listener";
import { authenticationService } from "../services/authentication.service";
import { reportTemplateService } from "../services/report-template.service";
import { reportService } from "../services/report.service";
import { dialog } from "../singletons/dialog";
import { formDataToObject } from "../utilities/form.utils";
import { handlePrintStyles } from "../utilities/handle-print-styles.utils";
import { satisfiesRoles } from "../utilities/satisfies-roles";

const bundledSectionTypes: SectionType[] = [];

export const ReportDetailsView: FC<IViewProps> = (props) => {
  const [report, setReport] = useState<IReport | null>(null);
  const [results, setResults] = useState<IQuestionReport[]>([]);
  const [template, setTemplate] = useState<IReportTemplate | null>(null);
  const [, /*dirtyTemplate*/ setDirtyTemplate] = useState<boolean>(false);
  const isPrintView = window.location.search.includes("print");

  const reviewColors = useMemo(
    () =>
      report?.participations?.reduce<Record<string, string>>(
        (prev, { participant }, i) => {
          prev[participant.email] = colors.participantColors[i];
          if (participant.isInvitee) {
            prev[participant.email] = colors.invitee;
          }
          return prev;
        },
        {}
      ) ?? {},
    [report?.participations]
  );

  const fetchReport = async () => {
    try {
      const { data } = await reportService.get(props.routing.params.id, {
        populate: [
          "expert",
          "organisation",
          "participations",
          "questions",
          "survey",
          "team",
          "template.surveys",
          "user",
        ],
      });
      setTemplate(
        data.template || {
          name: dictionary.new_template,
          sections: [],
          isPublic: false,
          requiresPartnerReviews: false,
          surveyIds: [],
        }
      );

      document.head.innerHTML = document.head.innerHTML += `<style>
        @page { 
          @bottom-center {
            content: "${getLanguage(data.survey?.language).report_footer(
              data.user?.firstName ?? data.team?.name ?? "",
              data.organisation?.name ?? "",
              new Date()
            )}";
          }
        }`;

      setReport(data);

      handlePrintStyles();
    } catch {
      Router.push(routes.reports.path);
    }
  };

  const addSection = (section: IReportTemplateSection, index: number) => {
    setTemplate((curr) => {
      const _new = { ...curr! };
      _new.sections = [
        ..._new.sections.slice(0, index),
        section,
        ..._new.sections.slice(index, _new.sections.length),
      ];

      return _new;
    });
    setDirtyTemplate(true);
  };

  const updateSection = (section: IReportTemplateSection, index: number) => {
    setTemplate((curr) => {
      const _new = { ...curr! };
      _new.sections[index] = section;

      return _new;
    });
    setDirtyTemplate(true);
  };

  const deleteSection = (indexes: number[]) => {
    setTemplate((curr) => {
      const _new = { ...curr! };
      indexes.forEach((index) => _new.sections.splice(index, 1));

      return _new;
    });
    setDirtyTemplate(true);
  };

  const deleteTemplate = async () => {
    if (template?.id && window.confirm(dictionary.confirm_deletion)) {
      await reportTemplateService.delete(template.id);
      notificationListener.next({
        message: dictionary.successfully_deleted(dictionary.report),
      });
      Router.push(routes.editReport.path, { id: report?.id || "" });
    }
  };

  const saveTemplate = async (data: IReportTemplate) => {
    // create or patch the template
    data = { ...template, id: undefined, ownerId: undefined, ...data };

    let response: IResponse<IReportTemplate> | null = null;
    if (!data.id) {
      response = await reportTemplateService.post(data);
    } else {
      response = await reportTemplateService.put(data);
    }
    setTemplate(response.data);

    // save the template in a new report
    const { data: newReport } = await reportService.post({
      ...report!,
      templateId: response.data.id,
    });

    if (report?.id === newReport.id) {
      await fetchReport();
    } else {
      Router.push(routes.reportDetails.path, { id: newReport.id });
    }

    setDirtyTemplate(false);
    notificationListener.next({
      message: dictionary.successfully_saved(dictionary.report),
    });
    dialog.unmount();
  };

  const mailReport = async (data: FormData) => {
    await reportService.mailReport(report?.id, data);
    notificationListener.next({ message: dictionary.mail_successfully_sent });
  };

  useEffect(() => {
    const fetchResults = async () => {
      const { data } = await reportService.getResults(props.routing.params.id);
      setResults(data);
    };

    fetchReport();
    fetchResults();
  }, [props.routing.params.id]);

  // create a matrix with all consecutive bundled sections bundled together
  const sections: IReportTemplateSection[][] = [[]];
  template?.sections.reduce<IReportTemplateSection | undefined>(
    (prev, section) => {
      if (
        !bundledSectionTypes.includes(section.type) ||
        prev?.type !== section.type ||
        prev?.pageBreakAfter === true ||
        section.pageBreakBefore === true
      ) {
        sections.push([section]);
      } else {
        sections[sections.length - 1].push(section);
      }

      return section;
    },
    undefined
  );

  const templateSections = sections;

  if (!report || !template) {
    return <></>;
  }

  return (
    <>
      {/* Control bar */}
      {!isPrintView && (
        <div className={styles.controls}>
          {satisfiesRoles(Role.ADMIN) && (
            <div>
              <Button
                appearance="primary"
                size="small"
                attributes={{
                  onClick: () =>
                    dialog.mount({
                      title: dictionary.save_template,
                      children: (
                        <ReportTemplateForm
                          model={template}
                          onSubmit={(data) =>
                            saveTemplate(
                              formDataToObject<IReportTemplate>(data)
                            )
                          }
                        />
                      ),
                    }),
                }}
              >
                {dictionary.save_template}
              </Button>
              {template.id &&
                (template.ownerId === authenticationService.getUser()?.id ||
                  satisfiesRoles(Role.ADMIN)) && (
                  <Button
                    size="small"
                    attributes={{
                      onClick: deleteTemplate,
                    }}
                  >
                    {dictionary.delete_template}
                  </Button>
                )}
            </div>
          )}

          <div>
            {satisfiesRoles(Role.ADMIN, Role.EXPERT) && (
              <Button
                size="small"
                attributes={{
                  onClick: () =>
                    dialog.mount({
                      title: dictionary.send_report,
                      children: (
                        <MailReportForm
                          content={mailReportMail(report.survey?.name)}
                          availableParticipationIds={
                            report.participationIds ?? []
                          }
                          defaultParticipations={report.participations || []}
                          onSubmit={async (data) => {
                            await mailReport(data);
                            dialog.unmount();
                          }}
                        />
                      ),
                    }),
                }}
              >
                {dictionary.send}
              </Button>
            )}

            <Button
              size="small"
              attributes={{
                onClick: () =>
                  Router.push(routes.editReport.path, {
                    id: props.routing.params.id,
                  }),
              }}
            >
              {dictionary.edit}
            </Button>

            <Button
              size="small"
              attributes={{
                onClick: () => reportService.downloadPdf(report.id),
              }}
            >
              {dictionary.download}
            </Button>
          </div>
        </div>
      )}

      {/* Title page */}
      <TitlePage report={{ ...report, template }} reviewColors={reviewColors} />

      {/* Template sections */}
      {templateSections.map((sections, i) => {
        const sectionCount = templateSections
          .slice(0, i + 1)
          .reduce((prev, curr) => prev + curr.length, 0);
        return (
          <Fragment key={i}>
            {!!sections.length && (
              <SectionBundle
                report={report}
                sections={sections}
                results={results}
                reviewColors={reviewColors}
                onUpdate={
                  satisfiesRoles(Role.ADMIN)
                    ? (section) => updateSection(section, sectionCount - 1)
                    : undefined
                }
                onDelete={
                  satisfiesRoles(Role.ADMIN)
                    ? () =>
                        deleteSection(
                          sections.map((_, index) => sectionCount - index - 1)
                        )
                    : undefined
                }
                previousSections={templateSections[i - 1]}
              />
            )}

            {!isPrintView && satisfiesRoles(Role.ADMIN) && (
              <div
                className={styles.addSection}
                onClick={() =>
                  dialog.mount({
                    title: dictionary.add_section,
                    children: (
                      <ReportTemplateSectionForm
                        onSubmit={async (data, model) => {
                          addSection(model, sectionCount);
                          dialog.unmount();
                        }}
                      />
                    ),
                  })
                }
              >
                <img alt="" src={plusIcon} height={18} />
              </div>
            )}
          </Fragment>
        );
      })}
    </>
  );
};

const styles = {
  addSection: classes(
    "hide-on-print",
    style({
      display: "flex",
      justifyContent: "space-around",
      margin: 10,
      cursor: "pointer",
      opacity: 0.4,

      $nest: {
        "&:hover": {
          opacity: 1,
        },
      },
    })
  ),

  controls: classes(
    "hide-on-print",
    style({
      display: "flex",
      justifyContent: "space-between",
      maxWidth: 835,
      margin: "0 auto 30px auto",

      $nest: {
        "& > div": {
          display: "flex",
        },
        "& button": {
          width: "auto",
          marginLeft: 5,
        },
      },
    })
  ),
};
