import React, { FC } from "react";
import { style } from "typestyle";
import { colors } from "../../constants/colors";
import { getLanguage } from "../../constants/i18n/dictionary";
import { IReportProps } from "../../interfaces/report-props.interface";

export const ExtendedLegendReport: FC<IReportProps> = (props) => (
  <div
    {...props.attributes}
    className={[styles.report, props.attributes?.className].join(" ")}
  >
    <div className={styles.row}>
      <div
        className={styles.bar}
        style={{ backgroundColor: colors.constructive }}
      />
      {getLanguage(props.report.survey?.language).cherish_and_starting_points}
    </div>
    <div className={styles.row}>
      <div
        className={styles.bar}
        style={{ backgroundColor: colors.destructive }}
      />
      {
        getLanguage(props.report.survey?.language)
          .development_and_obstacle_points
      }
    </div>
    <div className={styles.row}>
      <div
        className={styles.bar}
        style={{ backgroundColor: colors.starting_point }}
      />
      {getLanguage(props.report.survey?.language).development_space}
    </div>
    <div className={styles.row}>
      <div
        className={styles.bar}
        style={{ backgroundColor: colors.obstacle }}
      />
      {getLanguage(props.report.survey?.language).development_potential}
    </div>
    {props.report.user && (
      <div className={styles.row}>
        <div
          className={styles.bar}
          style={{ backgroundColor: colors.invitee }}
        />
        {props.report.user?.fullName}
      </div>
    )}
  </div>
);

const styles = {
  report: style({
    padding: "30px 0",
  }),
  row: style({
    display: "flex",
    alignItems: "center",
    padding: "5px 0",
  }),
  bar: style({
    height: 5,
    width: 30,
    borderRadius: 10,
    marginRight: 20,
  }),
};
