import {
  IQuestionReport,
  IReportTemplateSection,
} from "@hulanbv/ssllow-packages";
import _round from "lodash/round";
import React, { FC, HTMLAttributes, useEffect, useState } from "react";
import { classes, style } from "typestyle";
import { colors } from "../../constants/colors";
import { IParticipantAverage } from "../../interfaces/participant-average.interface";
import { reportService } from "../../services/report.service";
import { UserPicture } from "../elements/user-picture";

interface IProps {
  attributes?: HTMLAttributes<HTMLDivElement>;
  results: IQuestionReport[];
  title?: string;
  section: IReportTemplateSection;
  size?: "small" | "wide";
  reviewColors: Record<string, string>;
}

export const SummaryReportItem: FC<IProps> = (props) => {
  const [inviteeAvg, setInviteeAvg] = useState<number | null>(null);
  const [partnerAvg, setPartnerAvg] = useState<number | null>(null);
  const [participantAvgs, setParticipantAvgs] = useState<IParticipantAverage[]>(
    []
  );

  useEffect(() => {
    const averages = reportService.getParticipantAverages(props.results);
    const partnerAvgs = averages.filter(
      ({ participant }) => !participant.isInvitee
    );

    setParticipantAvgs(averages);
    setInviteeAvg(
      averages.find((avg) => avg.participant.isInvitee)?.average ?? null
    );
    if (partnerAvgs.length) {
      setPartnerAvg(
        _round(
          partnerAvgs.reduce((prev, curr) => prev + curr.average, 0) /
            partnerAvgs.length,
          1
        )
      );
    }
  }, [props.results]);

  return (
    <div
      {...props.attributes}
      className={classes(
        styles.report,
        styles[props.size ?? "small"],
        props.attributes?.className
      )}
    >
      <div className={styles.title}>{props.title || " "}</div>
      <div className={styles.scores}>
        {inviteeAvg !== null && (
          <>
            <span>{inviteeAvg}%</span>
          </>
        )}
        {partnerAvg !== null && inviteeAvg !== null && " / "}
        {partnerAvg !== null && `${partnerAvg}%`}
      </div>

      <div className={styles.averages}>
        {Array(5)
          .fill(null)
          .map((x, i) => (
            <div
              key={i}
              className={styles.percentageBar}
              style={{
                opacity: props.size !== "wide" && i === 4 ? 0 : 1,
              }}
            >
              <div className={styles.percentage}>{100 - i * 25}</div>{" "}
              <div className={styles.bar} />
            </div>
          ))}

        <div className={styles.participantContainer}>
          {participantAvgs.map((avg, i) => (
            <div
              key={i}
              className={styles.participant}
              style={{
                height: `${avg.average}%`,
                backgroundColor:
                  avg.average > 45 ? colors.cherish : colors.development,
              }}
            >
              <UserPicture
                attributes={{ className: styles.participantHead }}
                size={30}
                user={avg.participant}
                color={props.reviewColors[avg.participant.email]}
              />
            </div>
          ))}
        </div>
      </div>
      {props.size === "wide" && (
        <div className={styles.participantDetailsContainer}>
          {participantAvgs.map((avg, i) => (
            <div className={styles.participantDetails} key={i}>
              <div
                style={{
                  color: avg.participant.isInvitee ? colors.invitee : undefined,
                }}
              >
                {avg.participant.firstName}
              </div>
              <div>{avg.average}%</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const styles = {
  report: style({
    padding: "20px 10px 0 10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: 170,
    borderRadius: 30,
    background: "white",
    // boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
    border: "1px solid #eee",
    pageBreakInside: "avoid",
    "-webkit-filter": "blur(0)",
  }),
  small: style({
    width: "30%",
  }),
  wide: style({
    width: "100%",
    marginBottom: 25,
    padding: "25px 50px",
  }),

  title: style({
    textAlign: "center",
    marginBottom: 10,
  }),

  scores: style({
    textAlign: "center",
    marginBottom: 15,

    $nest: {
      "& span": {
        color: colors.invitee,
      },
    },
  }),

  averages: style({
    height: 80,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  }),
  percentageBar: style({
    display: "flex",
    alignItems: "flex-end",
  }),
  percentage: style({
    maxWidth: 25,
    paddingRight: 10,
    fontSize: 9,
    flex: 1,
    textAlign: "right",
  }),
  bar: style({
    height: 1,
    flex: 1,
    borderBottom: "1px dashed #c4bec4",
  }),

  participantContainer: style({
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    paddingLeft: 30,
    paddingTop: 10,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-around",
  }),
  participant: style({
    width: 3,
    position: "relative",
  }),
  participantHead: style({
    position: "absolute",
    top: -30,
    left: -13,
  }),

  participantDetailsContainer: style({
    marginTop: 10,
    paddingLeft: 30,
    display: "flex",
    alignItems: "flex-end",
  }),
  participantDetails: style({
    flex: 1,
    textAlign: "center",
  }),
};
