import {
  IInvite,
  IQuestion,
  ITeachingAidCompletion,
} from "@hulanbv/ssllow-packages";
import React, { FC, useEffect } from "react";
import { classes, stylesheet } from "typestyle";
import titleBackground from "../assets/images/title-page-background.png";
import { TeachingAid } from "../components/elements/teaching-aid.element";
import { UserTag } from "../components/elements/user-tag";
import { Page } from "../components/reports/page";
import { H1 } from "../components/typography/h1";
import { colors } from "../constants/colors";
import { dictionary, getLanguage } from "../constants/i18n/dictionary";
import { IViewProps } from "../interfaces/view-props.interface";
import { inviteService } from "../services/invite.service";
import { questionService } from "../services/question.service";
import { teachingAidCompletionService } from "../services/teaching-aid-completion.service";
import { handlePrintStyles } from "../utilities/handle-print-styles.utils";

export const QuestionMicroLearningsView: FC<IViewProps> = (props) => {
  const [invite, setInvite] = React.useState<IInvite | null>(null);
  const [question, setQuestion] = React.useState<IQuestion | null>(null);
  const [completions, setCompletions] = React.useState<
    ITeachingAidCompletion[]
  >([]);
  const { questionId, inviteId } = props.routing.params;

  useEffect(() => {
    const fetchData = async () => {
      const { data: invite } = await inviteService.get(inviteId, {
        populate: ["user", "survey"],
      });
      setInvite(invite);

      const { data: question } = await questionService.get(questionId, {
        populate: ["teachingAids.asset"],
      });
      setQuestion(question);

      const { data: completions } = await teachingAidCompletionService.getAll({
        filter: {
          questionId: questionId,
          inviteId: inviteId,
        },
      });
      setCompletions(completions);

      document.head.innerHTML = document.head.innerHTML += `<style>
        @page { 
          @bottom-center {
            content: "${getLanguage(
              invite.survey?.language
            ).microlearning_footer(invite.user?.fullName ?? "", new Date())}";
          }
        }`;

      handlePrintStyles();
    };
    fetchData();
  }, [inviteId, questionId]);

  return (
    <>
      <Page attributes={{ className: classes(styles.page, styles.cover) }}>
        <div className={styles.coverContent}>
          <H1 attributes={{ style: { paddingBottom: 0 } }}>
            {dictionary.microlearning}
          </H1>
          <p className={styles.titleQuestion}>"{question?.question}"</p>
          {invite?.user && (
            <UserTag user={invite?.user} color={colors.invitee} />
          )}
        </div>
      </Page>
      <Page>
        {question?.teachingAids?.map((teachingAid) => (
          <TeachingAid
            key={teachingAid.id}
            aid={teachingAid}
            completion={completions.find(
              (completion) => completion.teachingAidId === teachingAid.id
            )}
          />
        ))}
      </Page>
    </>
  );
};

const styles = stylesheet({
  page: {
    $nest: {
      "@media screen": {
        minHeight: 1180,
      },
    },
  },

  cover: {
    $nest: {
      "@media screen": {
        background: `url(${titleBackground})`,
        backgroundSize: "cover",
      },
    },
  },
  coverContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    pageBreakAfter: "always",
    paddingTop: 350,
  },

  titleQuestion: {
    padding: "15px 0",
  },
});
