import React, { FC, HTMLAttributes } from "react";
import { style } from "typestyle";

interface IProps {
  attributes?: HTMLAttributes<HTMLDivElement>;
}

export const Page: FC<IProps> = (props) => (
  <div
    {...props.attributes}
    className={[styles.page, props.attributes?.className].join(" ")}
  >
    {props.children}
  </div>
);

const styles = {
  page: style({
    padding: "120px 60px",
    background: "#fff",
    borderRadius: 4,
    border: "1px solid #c4bec4",
    width: 835,
    margin: "0 auto",

    $nest: {
      "*": {
        color: "#000",
      },

      "@media print": {
        padding: 0,
        background: "inherit",
        borderRadius: 0,
        borderWidth: 0,
        width: "auto",
        margin: "auto",
      },
    },
  }),
};
