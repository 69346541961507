import { IReport } from "@hulanbv/ssllow-packages";
import React, { FC } from "react";
import { classes, style } from "typestyle";
import titleBackground from "../../assets/images/title-page-background.png";
import { getLanguage } from "../../constants/i18n/dictionary";
import { UserTag } from "../elements/user-tag";
import { H1 } from "../typography/h1";
import { Page } from "./page";

interface IProps {
  report: IReport;
  reviewColors: Record<string, string>;
}

export const TitlePage: FC<IProps> = (props) => (
  <Page attributes={{ className: styles.page }}>
    <div className={styles.surveyInfo}>
      {props.report.organisation?.imagePath && (
        <img
          alt={props.report.organisation?.name}
          className={styles.organisationImg}
          src={props.report.organisation?.imagePath}
        />
      )}

      <H1 attributes={{ className: styles.surveyName }}>
        {props.report.survey?.name}
      </H1>
      <div className={styles.templateName}>{props.report.template?.name}</div>

      {props.report.user && <div>{props.report.user?.fullName}</div>}
      {props.report.team && <div>{props.report.team?.name} </div>}

      <div style={{ margin: "80px 0 45px 0" }}>
        {getLanguage(props.report.survey?.language).participants}/
        {getLanguage(props.report.survey?.language).partners}
      </div>
      <div className={classes(props.report.team && styles.teamContainer)}>
        {props.report.participations?.map(({ participant }, i) => (
          <UserTag
            key={i}
            user={participant}
            color={props.reviewColors[participant.email]}
            attributes={{
              className: classes(
                styles.userTag,
                props.report.team && styles.teamTag
              ),
            }}
          />
        ))}
      </div>
    </div>
  </Page>
);

const styles = {
  page: style({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: 1180,
    pageBreakAfter: "always",

    $nest: {
      "@media screen": {
        background: `url(${titleBackground})`,
        backgroundSize: "cover",
      },
    },
  }),

  teamContainer: style({
    display: "flex",
    flexDirection: "row",
    width: "75%",
    flexWrap: "wrap",
  }),

  surveyInfo: style({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "75px 0 175px 0",
  }),

  organisationImg: style({
    maxHeight: 65,
    marginBottom: 40,
  }),

  surveyName: style({
    marginBottom: 20,
  }),

  templateName: style({
    marginBottom: 5,
  }),

  userTag: style({
    marginBottom: 10,
  }),

  teamTag: style({
    width: "50%",
  }),
};
