import { AssetType, IAsset } from "@hulanbv/ssllow-packages";
import React, { FC, HTMLAttributes, useState } from "react";
import { style } from "typestyle";
import { dictionary } from "../../constants/i18n/dictionary";
import { assetService } from "../../services/asset.service";
import { Input } from "../controls/input";
import { FormRow } from "../forms/form-row";

interface IProps {
  attributes?: HTMLAttributes<HTMLDivElement>;
  name: string;
  asset?: IAsset;
  disabled?: boolean;
  required?: boolean;
}

export const AssetUpload: FC<IProps> = (props) => {
  const [asset, setAsset] = useState<IAsset | undefined>(props.asset);

  return (
    <div {...props.attributes}>
      {asset?.path && (
        <FormRow>
          <div>
            <img className={styles.image} src={asset.path} alt="" />
          </div>
        </FormRow>
      )}
      <FormRow>
        <input type="hidden" name={props.name} value={asset?.id} />
        <Input
          label={dictionary.file}
          attributes={{
            onChange: async (e) => {
              const file = e.target.files?.[0];
              if (file) {
                const formData = new FormData();
                formData.append("file", file);
                formData.append("type", AssetType.IMAGE.toString());
                formData.append("name", file.name);

                const { data: asset } = await assetService.post(formData);
                setAsset(asset);
              } else {
                setAsset(undefined);
              }
            },
            disabled: props.disabled,
            required: props.required && !asset,
            name: "file",
            type: "file",
            accept: "image/*",
          }}
        />
      </FormRow>
    </div>
  );
};

const styles = {
  image: style({
    width: "100%",
    height: "auto",
    maxHeight: 600,
    backgroundSize: "contain",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    margin: "0 auto",
  }),
};
