import React, { FC, HTMLAttributes, useState } from "react";
import { style } from "typestyle";
import { ITabItem } from "../../interfaces/tab-item.interface";

interface IProps {
  attributes?: HTMLAttributes<HTMLDivElement>;

  items: ITabItem[];
}

export const Tabs: FC<IProps> = (props) => {
  const [tabIndex, setTabIndex] = useState<number>(0);

  return (
    <div
      {...{
        ...props.attributes,
        className: [styles.tabs, props.attributes?.className].join(" "),
      }}
    >
      <div className={styles.tabBar}>
        {props.items.map(({ label }, i) => (
          <div
            key={i}
            className={[styles.tab, i === tabIndex ? styles.active : ""].join(
              " "
            )}
            onClick={() => setTabIndex(i)}
          >
            {label}
          </div>
        ))}
      </div>
      <div>{props.items[tabIndex]?.children}</div>
    </div>
  );
};

const styles = {
  tabs: style({
    width: "100%",
  }),
  tabBar: style({
    display: "flex",
    alignItems: "flex-end",
    borderBottom: "1px solid #c4bec4",
    marginBottom: 20,
  }),
  tab: style({
    padding: 20,
    color: "#3498dbf0",

    $nest: {
      "&:hover": {
        cursor: "pointer",
        background: "#fafafa",
      },
    },
  }),
  active: style({
    color: "#707070",
    border: "1px solid #c4bec4",
    borderBottomWidth: 0,
    boxShadow: "0 1px 0 #fff",
    borderRadius: "6px 6px 0 0",

    $nest: {
      "&:hover": {
        cursor: "default",
        background: "#fff",
      },
    },
  }),
};
