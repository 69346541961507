import {
  IQuestionReport,
  IReport,
  IReportTemplateSection,
  ResultType,
} from "@hulanbv/ssllow-packages";
import React, { FC, HTMLAttributes, useMemo } from "react";
import { style } from "typestyle";
import constructiveLabelImage from "../../assets/svg/backgrounds/constructive-label.svg";
import destructiveLabelImage from "../../assets/svg/backgrounds/destructive-label.svg";
import { colors } from "../../constants/colors";
import { getLanguage } from "../../constants/i18n/dictionary";
import { questionService } from "../../services/question.service";
import { hydrateString } from "../../utilities/hydrate-strings.utils";
import { MagnifyingGlass } from "../elements/magnifying-glass";

interface IProps {
  attributes?: HTMLAttributes<HTMLDivElement>;

  result: IQuestionReport;
  section: IReportTemplateSection;
  report: IReport;
}

export const HighlightReportItem: FC<IProps> = (props) => {
  const score = props.section.showPartnerResultType
    ? props.result.averageScore
    : props.result.userScore;
  const resultType = props.section.showPartnerResultType
    ? props.result.partnerResultType
    : props.result.resultType;

  const grade = useMemo(() => {
    if (resultType === undefined) {
      return undefined;
    }

    const dictionary = getLanguage(props.report.survey?.language);
    const grades: Record<ResultType, string> = {
      [ResultType.CHERISH]: dictionary.cherish_point,
      [ResultType.DEVELOPMENT]: dictionary.development_point,
      [ResultType.OBSTACLE]: dictionary.obstacle_point,
      [ResultType.STARTING]: dictionary.starting_point,
      [ResultType.UNDEFINED]: "",
    };

    return grades[resultType][0];
  }, [props.report.survey, resultType]);
  const answer = useMemo(() => {
    const closestAnswer = questionService.getClosestAnswer(
      props.result.question,
      score,
      props.report.survey?.language
    );
    let answer = props.result.question.participantAdviceQuestion.replace(
      /\{answer\}/g,
      closestAnswer.toLowerCase()
    );
    if (!props.report.user) {
      answer = props.result.question.adviceQuestion.replace(
        /\{answer\}/g,
        closestAnswer.toLowerCase()
      );
    }

    return hydrateString(
      answer,
      {
        user: props.report.user,
        expert: props.report.expert,
        organisation: props.report.organisation,
        survey: props.report.survey,
      },
      props.report.survey?.language
    );
  }, [
    props.report.expert,
    props.report.organisation,
    props.report.survey,
    props.report.user,
    props.result.question,
    score,
  ]);

  return (
    <div
      {...props.attributes}
      className={[styles.report, props.attributes?.className].join(" ")}
    >
      <div className={styles.gradeColumn}>
        <MagnifyingGlass
          grade={grade}
          attributes={{ className: styles.grade }}
        />
      </div>
      <div className={styles.questionColumn}>
        <div
          className={styles.questionName}
          style={{
            backgroundImage: `url(${
              [ResultType.CHERISH, ResultType.STARTING].includes(
                resultType ?? ResultType.UNDEFINED
              )
                ? constructiveLabelImage
                : destructiveLabelImage
            })`,
          }}
        >
          {props.result.question.name}
        </div>
        <div className={styles.question}>{answer}</div>
      </div>
    </div>
  );
};

const styles = {
  report: style({
    display: "flex",
  }),
  gradeColumn: style({
    width: 40,
  }),
  grade: style({
    marginTop: 5,
    width: 35,
    height: 35,
  }),
  questionColumn: style({
    width: 165,
  }),
  questionName: style({
    marginBottom: 10,
    color: "white",
    backgroundSize: "165px 40px",
    minWidth: 165,
    minHeight: 40,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }),
  question: style({
    color: colors.invitee,
  }),
};
