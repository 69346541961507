/* eslint-disable react-hooks/exhaustive-deps */
import { IQuestion, ISurvey, IUser, Role } from "@hulanbv/ssllow-packages";
import React, { FC, useEffect, useState } from "react";
import Router from "react-history-router";
import { style } from "typestyle";
import { Card } from "../components/containers/card";
import { Button } from "../components/controls/button";
import { UserOption } from "../components/controls/option-templates/user-option";
import { Search } from "../components/controls/search";
import { SurveyData } from "../components/elements/survey-data";
import { CrudForm } from "../components/forms/crud-form";
import { QuestionForm } from "../components/forms/question-form";
import { routes } from "../components/routing/routes";
import { CrudTable } from "../components/tables/crud-table";
import { QuestionRow } from "../components/tables/custom-rows/question-row";
import { H1 } from "../components/typography/h1";
import { dictionary } from "../constants/i18n/dictionary";
import { IViewProps } from "../interfaces/view-props.interface";
import { questionService } from "../services/question.service";
import { surveyService } from "../services/survey.service";
import { userService } from "../services/user.service";
import { dialog } from "../singletons/dialog";
import { satisfiesRoles } from "../utilities/satisfies-roles";

export const SurveyDetailsView: FC<IViewProps> = (props) => {
  const [survey, setSurvey] = useState<ISurvey | null>(null);
  const [questionCount, setQuestionCount] = useState<number>(0);
  const isEditable = satisfiesRoles(Role.ADMIN);

  const fetchSurvey = async () => {
    try {
      const { data } = await surveyService.get(props.routing.params.id, {
        populate: ["eligibleExperts"],
      });
      setSurvey(data);
    } catch {
      Router.push(routes.surveys.path);
    }
  };

  const addQuestion = async (question: IQuestion) => {
    const { data } = await surveyService.patch({
      ...survey,
      questionIds: (survey?.questionIds || []).concat(question.id),
    });

    setSurvey(data);
  };

  useEffect(() => {
    fetchSurvey();
  }, [props.routing.params.id]);

  if (!survey) {
    return <></>;
  }
  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <Card attributes={{ className: styles.card }}>
          <SurveyData survey={survey} />
          {isEditable && (
            <Button
              attributes={{
                onClick: () =>
                  Router.push(routes.editSurvey.path, {
                    id: survey.id,
                  }),
              }}
            >
              {dictionary.edit_reflector}
            </Button>
          )}
        </Card>

        {satisfiesRoles(Role.ADMIN) && (
          <Card attributes={{ className: styles.card }}>
            <H1>{dictionary.experts_or_spectators}</H1>

            <Search<IUser>
              keyField="id"
              key={survey.eligibleExpertIds.length}
              find={async (search) =>
                (
                  await userService.getAll({
                    search,
                    filter: { role: "!" + Role.USER },
                    sort: ["firstName"],
                    limit: 25,
                  })
                ).data
              }
              defaultValue={survey.eligibleExperts}
              optionTemplate={UserOption}
              multiple
              onChange={(eligibleExpertIds: string[]) =>
                surveyService.patch({ ...survey, eligibleExpertIds })
              }
            />
          </Card>
        )}
      </div>

      <div className={styles.right}>
        <H1>
          <span style={{ whiteSpace: "nowrap" }}>
            {dictionary.questions}{" "}
            <span className={styles.secondary}>- {questionCount}/100</span>
          </span>
          {isEditable && (
            <Button
              size={"small"}
              attributes={{
                onClick: () => {
                  dialog.mount({
                    width: 650,
                    title: dictionary.add_question,
                    children: (
                      <CrudForm
                        messageSubject={dictionary.question}
                        form={QuestionForm}
                        model={{ survey }}
                        service={questionService}
                        onDone={async (question) => {
                          if (question) {
                            await addQuestion(question);
                          }
                          dialog.unmount();
                        }}
                      />
                    ),
                  });
                },
              }}
            >
              {dictionary.add_question}
            </Button>
          )}
        </H1>
        <CrudTable
          headings={{
            name: dictionary.name,
            question: dictionary.question,
            participantQuestion: dictionary.partner_question,
            category: dictionary.category,
            genre: dictionary.genre,
            classification: dictionary.classification.substring(0, 4),
          }}
          options={{ populate: [] }}
          service={questionService}
          customFind={async (options) => {
            const response = await questionService.getBySurvey(
              survey.id,
              options
            );

            setQuestionCount(+(response.headers.get("X-total-count") || 0));
            return response;
          }}
          customRow={(question) => (
            <QuestionRow
              key={question.id}
              question={question}
              attributes={{
                onClick: () =>
                  dialog.mount({
                    width: 650,
                    title: dictionary.edit_question,
                    children: (
                      <CrudForm
                        readOnly={!isEditable}
                        messageSubject={dictionary.question}
                        modelId={question.id}
                        model={{ survey }}
                        form={QuestionForm}
                        service={questionService}
                        usePut
                        onDone={async () => {
                          dialog.unmount();
                          fetchSurvey();
                        }}
                      />
                    ),
                  }),
              }}
            />
          )}
        />
      </div>
    </div>
  );
};

const styles = {
  container: style({
    display: "flex",
  }),
  left: style({
    maxWidth: 300,
    flex: 1,
    margin: 15,
    marginTop: 0,
  }),
  right: style({
    flex: 1,
    margin: 15,
    marginTop: 0,
  }),
  card: style({
    marginBottom: 20,
  }),
  secondary: style({
    opacity: 0.54,
  }),
};
