export const appIntro = () =>
  `Slow staat voor Samen lerend op weg.
  
De Slow reflector geeft aan de hand van stellingen inzicht in wat de persoonlijke en professionele ontwikkeling stimuleert dan wel beperkt.
 
Mocht je het lastig vinden om een stelling te beantwoorden, vul dan in waar je als eerste aan denkt. De uitkomsten worden vertrouwelijk behandeld en uitsluitend gebruikt voor de activiteit waar deze voor bedoeld zijn.
 
Veel succes bij het invullen!`;

export const surveyIntro = () =>
  `De Slow reflector is een hulpmiddel voor reflectie en feedback. De uitkomsten zijn niet 'goed of fout' en zijn een momentopname. De uitkomsten zeggen dan ook niets over het verleden en de toekomst.
 
De Slow reflector geeft aan de hand van ontwikkel-, belemmer-, koester-, uitgangs- en gesprekspunten inzicht in het persoonlijk ontwikkelvermogen en team ontwikkelvermogen. Hiervoor worden de inzichten van de deelnemer en de feedbackgever(s) meegenomen.
 
Wanneer je tussentijds wil stoppen dan kan dat. Als je weer inlogt ga je automatisch verder waar je gebleven bent.
 
Druk op start als je klaar bent om door te gaan met invullen.`;

export const surveyParticipantIntro = () =>
  `De Slow reflector is een hulpmiddel voor reflectie en feedback. De uitkomsten zijn niet 'goed of fout' en zijn een momentopname. De uitkomsten zeggen dan ook niets over het verleden en de toekomst.
 
De Slow reflector geeft aan de hand van ontwikkel-, belemmer-, koester-, uitgangs- en gesprekspunten inzicht in het persoonlijk ontwikkelvermogen en team ontwikkelvermogen. Hiervoor worden de inzichten van de deelnemer en de feedbackgever(s) meegenomen.
 
Druk op start als je klaar bent om door te gaan met invullen.`;

export const surveyEnding = () =>
  `Je hebt alle stellingen doorlopen!
  
De uitkomsten worden gebruikt voor de activiteit waarvoor de reflector is aangevraagd. Afhankelijk van de gemaakte afspraken kun je via 'rapportages' jouw rapportages aanvragen en via 'microlearnings' aan de slag met je eigen uitkomsten.

De uitkomsten worden niet geïnterpreteerd. Wel worden de uitkomsten gewaardeerd tegen het licht van jouw leer- en ontwikkelvermogen.

Bedankt voor het invullen!`;

export const surveyParticipantEnding = () =>
  `Bedankt voor het invullen!
  
Afhankelijk van de gemaakte afspraken zal {user.firstName} contact met je opnemen om over de uitkomsten in gesprek te gaan.

De uitkomsten worden niet bewerkt en geïnterpreteerd. Wel worden de uitkomsten gewaardeerd tegen het licht van het ontwikkelvermogen van {user.firstName}.

Indien je interesse hebt om voor jezelf een reflector in te vullen, stuur dan een berichtje naar info@slowreflector.nl of kijk op www.slowreflector.nl.`;
