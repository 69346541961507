import React, { FC, useEffect, useState } from "react";
import { style } from "typestyle";
import { authenticationService } from "../services/authentication.service";
import { HideOnPrint } from "./elements/hide-on-print";
import { Location } from "./elements/location";
import { NotificationCenter } from "./elements/notification-center";
import { RouterElement } from "./routing/router-element";
import { Footer } from "./statics/footer";
import { SideBar } from "./statics/side-bar";

export const App: FC = () => {
  const [initialized, setInitialized] = useState<boolean>(false);
  useEffect(() => {
    const validate = async () => {
      try {
        // validate stored session on app load
        if (authenticationService.getSession()) {
          await authenticationService.validate();
        }
      } catch {}

      setInitialized(true);
    };

    validate();
  }, []);

  return (
    <>
      <div
        className={styles.container}
        style={{ display: initialized ? undefined : "none" }}
      >
        <SideBar />
        <div className={styles.content}>
          <HideOnPrint>
            <Location />
          </HideOnPrint>
          <div className={styles.routeContent}>
            <RouterElement />
            <HideOnPrint>
              <Footer />
            </HideOnPrint>
          </div>
        </div>
      </div>
      <NotificationCenter />
    </>
  );
};

const styles = {
  container: style({
    display: "flex",
    minHeight: "100vh",
    minWidth: 1024,
    maxWidth: "100vw",
    position: "relative",

    $nest: {
      "@media print": {
        minWidth: "auto",
        minHeight: "auto",
      },
    },
  }),
  content: style({
    flex: 1,
    padding: "40px 80px",

    $nest: {
      "@media print": {
        padding: 0,
      },
    },
  }),
  routeContent: style({
    paddingTop: 80,
    maxWidth: 1600,
    margin: "0 auto",

    $nest: {
      "@media print": {
        padding: 0,
      },
    },
  }),
};
