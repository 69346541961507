/* eslint-disable react-hooks/exhaustive-deps */
import { IInvite, IUser, Role } from "@hulanbv/ssllow-packages";
import React, { FC, useEffect, useState } from "react";
import Router from "react-history-router";
import { style } from "typestyle";
import { Card } from "../components/containers/card";
import { Button } from "../components/controls/button";
import { UserData } from "../components/elements/user-data";
import { routes } from "../components/routing/routes";
import { CrudTable } from "../components/tables/crud-table";
import { InviteRow } from "../components/tables/custom-rows/invite-row";
import { dictionary } from "../constants/i18n/dictionary";
import { IViewProps } from "../interfaces/view-props.interface";
import { notificationListener } from "../observables/notification-listener";
import { inviteService } from "../services/invite.service";
import { participationService } from "../services/participation.service";
import { userService } from "../services/user.service";
import { satisfiesRoles } from "../utilities/satisfies-roles";

export const UserDetailsView: FC<IViewProps> = (props) => {
  const [user, setUser] = useState<IUser | null>(null);
  const [iteration, setIteration] = useState<number>(0);

  const deleteParticipation = async (id: string) => {
    await participationService.delete(id);
    setIteration(iteration + 1);
  };

  const fetchUser = async () => {
    try {
      const { data } = await userService.get(props.routing.params.id, {
        populate: ["plainPassword"],
      });
      setUser(data);
    } catch {
      Router.push(routes.users.path);
    }
  };
  const resetPassword = async () => {
    if (!window.confirm(dictionary.confirm_reset_password)) {
      return;
    }

    await userService.resetPassword(user!.id);
    notificationListener.next({ message: dictionary.password_has_been_reset });
  };

  useEffect(() => {
    fetchUser();
  }, [props.routing.params.id]);

  if (!user) {
    return <></>;
  }
  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <Card>
          <UserData user={user} />
          <Button
            attributes={{
              style: { marginBottom: 15 },
              onClick: () =>
                Router.push(routes.editUser.path, {
                  id: user.id,
                }),
            }}
          >
            {dictionary.edit_user}
          </Button>
          {satisfiesRoles(Role.ADMIN, Role.EXPERT) && (
            <Button attributes={{ onClick: resetPassword }}>
              {dictionary.reset_password}
            </Button>
          )}
        </Card>
      </div>

      <div className={styles.right}>
        <CrudTable<IInvite>
          key={iteration}
          service={inviteService}
          sortKey="createdAt"
          headings={{
            userId: dictionary.user,
            team: dictionary.team,
            organisationId: dictionary.organisation,
            surveyId: dictionary.reflectors,
            accessCode: dictionary.code,
            participations: dictionary.participants,
            createdAt: "",
          }}
          options={{
            filter: { userId: user.id },
            populate: [
              "user.teams",
              "organisation",
              "survey",
              "participations",
            ],
          }}
          customRow={(invite) => (
            <InviteRow
              key={invite.id}
              invite={invite}
              onDeleteParticipation={deleteParticipation}
            />
          )}
        />
      </div>
    </div>
  );
};

const styles = {
  container: style({
    display: "flex",
  }),
  left: style({
    maxWidth: 300,
    flex: 1,
    margin: 15,
    marginTop: 0,
  }),
  right: style({
    flex: 1,
    margin: 15,
    marginTop: 0,
  }),
};
