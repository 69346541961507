import { Role } from "@hulanbv/ssllow-packages";
import React, { FC } from "react";
import { style } from "typestyle";
import { Card } from "../components/containers/card";
import { CrudForm } from "../components/forms/crud-form";
import { dictionary } from "../constants/i18n/dictionary";
import { IViewProps } from "../interfaces/view-props.interface";
import { satisfiesRoles } from "../utilities/satisfies-roles";
import { PublicInviteForm } from "../components/forms/public-invite-form";
import { publicInviteService } from "../services/public-invite.service";

export const PublicInviteEditView: FC<IViewProps> = (props) => (
  <Card attributes={{ className: styles.card }}>
    <CrudForm
      messageSubject={dictionary.invite_links}
      form={PublicInviteForm}
      modelId={props.routing.params.id}
      fetchOptions={{ populate: ["organisation", "survey"] }}
      onDone={() => {
        window.history.back();
      }}
      service={publicInviteService}
      readOnly={!satisfiesRoles(Role.ADMIN, Role.EXPERT)}
      usePut
    />
  </Card>
);

const styles = {
  card: style({
    maxWidth: 650,
    margin: "0 auto",
  }),
};
