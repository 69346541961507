import React, { FC } from "react";
import { IOption } from "../../../interfaces/option.interface";

interface IProps {
  model: IOption;
}

export const OptionOption: FC<IProps> = (props) => (
  <div>{props.model.value}</div>
);
