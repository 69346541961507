import { ISurvey } from "@hulanbv/ssllow-packages";
import React, { FC, HTMLAttributes } from "react";
import { languageLabels } from "../../../constants/language-labels";
import { StyledTR } from "../styled-table-row";
import { TD } from "../table-data";

interface IProps {
  survey: ISurvey;
  attributes?: HTMLAttributes<HTMLDivElement>;
}

export const SurveyRow: FC<IProps> = (props) => (
  <StyledTR attributes={props.attributes}>
    <TD>{props.survey.name}</TD>
    <TD>{languageLabels[props.survey.language]}</TD>
  </StyledTR>
);
