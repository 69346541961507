import { IReportTemplate } from "@hulanbv/ssllow-packages";
import React, { FC, HTMLAttributes } from "react";
import { dictionary } from "../../../constants/i18n/dictionary";
import { StyledTR } from "../styled-table-row";
import { TD } from "../table-data";

interface IProps {
  reportTemplate: IReportTemplate;
  attributes?: HTMLAttributes<HTMLDivElement>;
}

export const ReportTemplateRow: FC<IProps> = (props) => (
  <StyledTR attributes={props.attributes}>
    <TD>{props.reportTemplate.name}</TD>
    <TD>{props.reportTemplate.surveys?.map(({ name }) => name).join(", ")}</TD>
    <TD>{props.reportTemplate.owner?.fullName}</TD>
    <TD>{props.reportTemplate.isPublic ? dictionary.yes : ""}</TD>
  </StyledTR>
);
