import { ICredentials, ISessionToken, IUser } from "@hulanbv/ssllow-packages";
import { IResponse } from "nest-utilities-client";
import { environment } from "../constants/environment.constant";
import { httpService } from "./http.service";

class AuthenticationService {
  private http = httpService;
  private controller = [environment.REACT_APP_API_URL, "authentication"].join(
    "/"
  );
  private readonly session_key = "session";

  /**
   * Log into the application
   * @param credentials
   */
  async login(
    credentials: ICredentials | FormData
  ): Promise<IResponse<ISessionToken>> {
    const response = await this.http.post(
      [this.controller].join("/"),
      credentials
    );

    // store the session in the local storage
    localStorage.setItem(this.session_key, JSON.stringify(response.data));

    return response;
  }

  /**
   * Validate your current session
   */
  async validate(): Promise<IResponse<ISessionToken>> {
    const response = await this.http.post(
      [this.controller, "validate"].join("/"),
      {}
    );

    // store the session in the local storage
    localStorage.setItem(this.session_key, JSON.stringify(response.data));

    return response;
  }

  /**
   * Log out of the application
   */
  async logout(): Promise<IResponse<void>> {
    try {
      const response = await this.http.post(
        [this.controller, "logout"].join("/"),
        {}
      );

      localStorage.removeItem(this.session_key);

      return response;
    } catch (error) {
      localStorage.removeItem(this.session_key);

      throw error;
    }
  }

  /**
   * Get your stored session
   */
  getSession(): ISessionToken | null {
    try {
      return JSON.parse(localStorage[this.session_key]);
    } catch {
      return null;
    }
  }

  /**
   * Get your stored session user
   */
  getUser(): IUser | null {
    return this.getSession()?.user ?? null;
  }
}

export const authenticationService = new AuthenticationService();
