import React, { FC } from "react";
import { style } from "typestyle";
import { IReportProps } from "../../interfaces/report-props.interface";
import { SummaryReportItem } from "./summary-report-item";

export const TotalSummaryReport: FC<IReportProps> = (props) => {
  return (
    <div
      {...props.attributes}
      className={[styles.report, props.attributes?.className].join(" ")}
    >
      <SummaryReportItem
        section={props.section}
        results={props.results}
        title={props.title}
        size="wide"
        reviewColors={props.reviewColors}
      />
    </div>
  );
};

const styles = {
  report: style({
    pageBreakInside: "avoid",
  }),
};
