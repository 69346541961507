import { formatDate } from "../../utilities/date.utils";
import { dictionary } from "../i18n/dictionary";

export const mailPartners = (
  userFirstName: string,
  surveyName: string,
  accessCode: string,
  deadline: Date
) =>
  `Op dit moment heb je als feedbackgever van ${userFirstName} de reflector ${surveyName} nog niet afgerond. Je kunt op ieder gewenst moment verder gaan en tussentijds stoppen. Je toegangscode om in te loggen is ${accessCode}.
<br>Mocht dit niet lukken, dan kun je contact opnemen met de <a href='helpdesk@slowreflector.nl'>helpdesk</a>.
<br>
<br>De deadline voor het invullen is ${formatDate(
    new Date(deadline),
    dictionary.date_format
  )}.
<br>
<br>Alvast bedankt voor het invullen!`;
