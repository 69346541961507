import React, { FC, HTMLAttributes, useState } from "react";
import { style } from "typestyle";
import { dictionary } from "../../constants/i18n/dictionary";
import { getByPath } from "../../utilities/object.utils";
import { Chevron } from "../elements/chevron";
import { Label } from "../typography/label";

interface IProps {
  attributes?: HTMLAttributes<HTMLDivElement>;
  tree: Record<string, string | Record<string, any>>;
  onSelect?: (value: string | null) => void;
}

export const SelectionTree: FC<IProps> = (props) => {
  const [path, setPath] = useState<string[]>([]);

  const back = () => {
    const _path = [...path];
    _path.pop();
    setPath(_path);
  };

  const select = (direction: string) => {
    const _path = [...path];
    _path.push(direction);

    const location = getByPath(_path, props.tree);
    if (!!location && typeof location === "object") {
      setPath(_path);
    } else {
      props.onSelect?.(location);
    }
  };

  return (
    <div
      {...{
        ...props.attributes,
        className: [styles.tree, props.attributes?.className || ""].join(" "),
      }}
    >
      <Label>{dictionary.context_codes}</Label>
      {Object.keys(getByPath(path, props.tree)).map((key, i) => (
        <div className={styles.twig} key={i} onClick={() => select(key)}>
          {key}
        </div>
      ))}
      {!!path.length && (
        <div className={styles.twig} onClick={() => back()}>
          <div className={styles.alignMiddle}>
            <Chevron size={16} direction="left" color="#707070" />{" "}
            {dictionary.back}
          </div>
        </div>
      )}
    </div>
  );
};

const styles = {
  tree: style({}),
  twig: style({
    padding: 10,
    minWidth: 200,
    cursor: "pointer",
    borderRadius: 6,

    $nest: {
      "&:hover": {
        background: "#fafafa",
      },
    },
  }),
  alignMiddle: style({
    display: "inline-flex",
    alignItems: "center",
    opacity: 0.54,
  }),
};
