import React, { ButtonHTMLAttributes, FC } from "react";
import { style } from "typestyle";
import { colors } from "../../constants/colors";

interface IProps {
  appearance?: "default" | "primary" | "ghost";
  size?: "small" | "normal";
  attributes?: ButtonHTMLAttributes<HTMLButtonElement>;
}

export const Button: FC<IProps> = (props) => (
  <button
    type="button"
    {...props.attributes}
    className={[
      styles.button,
      styles[props.appearance || "default"],
      styles[props.size || "normal"],
      props.attributes?.className,
    ].join(" ")}
  >
    {props.children}
  </button>
);

const styles = {
  button: style({
    userSelect: "none",
    outline: 0,
    borderRadius: 5,
    width: "100%",
    transition: ".3s opacity, .1s background",
    cursor: "pointer",
    background: "#FFF",
    border: 0,
    whiteSpace: "nowrap",
    margin: "0 2px",

    $nest: {
      "&:hover": {
        opacity: 0.8,
      },
      "&:disabled": {
        cursor: "default",
        opacity: 0.6,
      },
    },
  }),

  default: style({
    background: "#FFF",
    border: "1px solid #ddd",

    $nest: {
      "&:active": {
        background: "#FAFAFA",
      },
    },
  }),

  small: style({
    padding: "6px 10px",
    fontSize: 10,
  }),
  normal: style({
    fontSize: 12,
    padding: "15px 20px",
  }),

  primary: style({
    background: colors.primary,
    color: "#FFF",

    $nest: {
      "&:active": {
        opacity: 1,
      },
    },
  }),

  ghost: style({
    background: "transparent",
    border: "1px solid #ddd",

    $nest: {
      "&:active": {},
    },
  }),
};
