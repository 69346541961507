/* eslint-disable react-hooks/exhaustive-deps */
import {
  IQuestionReport,
  IQuestionReview,
  IReport,
  IReportTemplateSection,
  QuestionType,
  ResultType,
} from "@hulanbv/ssllow-packages";
import React, { FC, HTMLAttributes, useEffect, useState } from "react";
import { classes, style } from "typestyle";
import { colors } from "../../constants/colors";
import { getLanguage } from "../../constants/i18n/dictionary";
import { getScoreLabels } from "../../constants/score-labels";
import { UserPicture } from "../elements/user-picture";

interface IProps {
  attributes?: HTMLAttributes<HTMLDivElement>;

  report: IReport;
  section: IReportTemplateSection;
  result: IQuestionReport;
  reviewColors: Record<string, string>;

  hideHead?: boolean;
}

export const GenericReportItem: FC<IProps> = (props) => {
  const [scoreReviews, setScoreReviews] = useState<IQuestionReview[][]>([]);

  // get the color of the bar
  const resultType = props.section.showPartnerResultType
    ? props.result.partnerResultType
    : props.result.resultType ?? props.result.partnerResultType;
  let barColor: string | undefined;
  if ([ResultType.CHERISH, ResultType.STARTING].includes(resultType!)) {
    barColor = colors.constructive;
  } else if (
    [ResultType.DEVELOPMENT, ResultType.OBSTACLE].includes(resultType!)
  ) {
    barColor = colors.destructive;
  }

  useEffect(() => {
    const orderReviews = () => {
      const _scoreReviews: typeof scoreReviews = [[], [], [], [], []];
      const scorePoints = props.result.question.points.map(
        (point) =>
          point +
          (props.result.question.type === QuestionType.DESTRUCTIVE ? 100 : 0)
      );

      props.result.reviews.forEach((review) => {
        const scoreIndex = scorePoints.indexOf(review.score);
        if (scoreIndex !== -1) {
          _scoreReviews[scoreIndex].push(review);
        }
      });
      setScoreReviews(_scoreReviews);
    };

    orderReviews();
  }, [props.result, props.report]);

  return (
    <div
      {...{
        ...props.attributes,
        className: classes(
          props.attributes?.className,
          styles.genericReportItem
        ),
      }}
    >
      {!props.hideHead && (
        <div className={[styles.row, styles.head].join(" ")}>
          <div className={styles.indicatorCol}></div>
          <div
            className={
              props.report.team
                ? styles.teamQuestionCol
                : styles.individualQuestionCol
            }
          ></div>
          <div
            className={styles.resultCol}
            style={{ paddingTop: 10, paddingBottom: 10 }}
          >
            <div style={{ width: 16 }} />
            {getScoreLabels(props.report.survey?.language).map((v, i) => (
              <div className={styles.resultHead} key={i}>
                {v.length > 7 ? v.substring(0, 4) + "." : v}
              </div>
            ))}
            <div style={{ width: 16 }} />
          </div>
          {props.report.team && (
            <div className={styles.averageCol}>
              {getLanguage(props.report.survey?.language).avg.toLowerCase()}
            </div>
          )}
        </div>
      )}

      <div className={styles.row}>
        <div className={styles.indicatorCol}>{props.result.question.name}</div>
        <div
          className={
            props.report.team
              ? styles.teamQuestionCol
              : styles.individualQuestionCol
          }
        >
          {props.result.question.question}
        </div>
        <div
          className={styles.resultCol}
          style={{ paddingTop: props.report.team ? 30 : undefined }}
        >
          <div className={styles.reviewsContainer}>
            <div
              className={styles.reviewBar}
              style={{
                background: `linear-gradient(90deg, ${barColor} 0%, ${colors.invitee} 100%)`,
              }}
            />
            <div className={styles.usersContainer}>
              {scoreReviews.map((reviews, i) => {
                // if we are showing a team report, bundle the reviews
                if (props.report.team) {
                  return (
                    <div className={styles.reviewsCol} key={i}>
                      {reviews.length > 0 && (
                        <>
                          <div className={styles.reviewCounter}>
                            {reviews.length}
                          </div>
                          <UserPicture
                            attributes={{ className: styles.review }}
                            key={i}
                            size={30}
                            color={barColor}
                          />
                        </>
                      )}
                    </div>
                  );
                }
                return (
                  <div className={styles.reviewsCol} key={i}>
                    {reviews.map((review, i) => {
                      const offset = 100 / (reviews.length + 1);
                      return (
                        <UserPicture
                          attributes={{
                            className: styles.review,
                            style: {
                              left: (i + 1) * offset + "%",
                              zIndex: reviews.length - i,
                            },
                          }}
                          key={i}
                          size={30}
                          user={review.participant}
                          color={props.reviewColors[review.participant.email]}
                        />
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {props.report.team && (
          <div className={styles.averageCol}>
            {props.result.userScore !== undefined && (
              <div style={{ color: colors.invitee }}>
                {props.result.userScore}%
              </div>
            )}
            {props.result.averageScore !== undefined && (
              <div>{props.result.averageScore}%</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const styles = {
  genericReportItem: style({
    pageBreakInside: "avoid",
  }),
  row: style({
    display: "flex",
    alignItems: "center",
  }),
  head: style({
    flex: 1,
    fontSize: 11,
  }),
  indicatorCol: style({
    flex: 1,
    minWidth: "15%",
    maxWidth: "15%",
    fontWeight: 500,
  }),
  teamQuestionCol: style({
    flex: 1,
    minWidth: "45%",
    maxWidth: "45%",
    padding: "0 10px",
  }),
  individualQuestionCol: style({
    flex: 1,
    minWidth: "50%",
    maxWidth: "50%",
    padding: "0 10px",
  }),
  resultCol: style({
    flex: 1,
    display: "flex",
    justifyContent: "space-between",
    minWidth: "35%",
    maxWidth: "35%",
    padding: "20px 10px",
  }),

  resultHead: style({
    flex: 1,
    textAlign: "center",
  }),

  reviewsContainer: style({
    display: "flex",
    flex: 1,
    alignItems: "center",
    position: "relative",
  }),

  reviewBar: style({
    height: 5,
    flex: 1,
    margin: "0 10px",
    borderRadius: 10,
    background: "#eee",
  }),
  usersContainer: style({
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    padding: "0 10px",
  }),
  reviewsCol: style({
    flex: 1,
    position: "relative",
  }),
  review: style({
    width: 30,
    height: 30,
    backgroundPosition: "center",
    backgroundSize: "cover",
    borderRadius: "100%",
    position: "absolute",
    marginTop: -12,
    marginLeft: -15,
    left: "50%",
  }),
  averageCol: style({
    flex: 1,
    textAlign: "center",

    minWidth: "5%",
    maxWidth: "5%",
  }),
  reviewCounter: style({
    fontSize: 9,
    color: "#fff",
    background: colors.invitee,
    borderRadius: "100%",
    left: "50%",
    position: "absolute",
    marginTop: -28,
    marginLeft: -7,
    width: 15,
    height: 15,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }),
};
