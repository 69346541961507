import { Role } from "@hulanbv/ssllow-packages";
import { authenticationService } from "../services/authentication.service";

/**
 * Returns true if the authenticated user's role equals
 * one of the given roles
 * @param roles
 */
export const satisfiesRoles = (...roles: Role[]) =>
  roles.includes(authenticationService.getUser()?.role!);
