import React, { FC } from "react";
import { Router } from "react-history-router";
import { IRouteOptions } from "../../interfaces/route-options.interface";
import { routeListener } from "../../observables/route-listener";
import { routes } from "./routes";

export const RouterElement: FC = () => (
  <Router
    routes={Object.values(routes)}
    onRouteDidChange={(route: IRouteOptions) => {
      document.title = route.data?.title ?? "Quizzzit";

      routeListener.next(route);
    }}
  />
);
