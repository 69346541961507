import { IUser } from "@hulanbv/ssllow-packages";
import React, { FC } from "react";
import { style } from "typestyle";
import { genderLabels } from "../../constants/gender-labels";
import { dictionary } from "../../constants/i18n/dictionary";
import { languageLabels } from "../../constants/language-labels";
import { roleLabels } from "../../constants/role-labels";
import { formatDate } from "../../utilities/date.utils";
import { Row } from "./row";

interface IProps {
  user: IUser;
}

export const UserData: FC<IProps> = (props) => (
  <div>
    {props.user.imagePath && (
      <div
        className={styles.image}
        style={{
          backgroundImage: `url(${props.user.imagePath})`,
        }}
      />
    )}
    <Row label={dictionary.name}>{props.user.fullName}</Row>
    <Row label={dictionary.gender}>{genderLabels[props.user.gender]}</Row>
    <Row label={dictionary.birthdate}>
      {props.user.dateOfBirth
        ? formatDate(props.user.dateOfBirth?.toString(), dictionary.date_format)
        : "-"}
    </Row>
    <Row label={dictionary.language}>{languageLabels[props.user.language]}</Row>
    <Row label={dictionary.email}>{props.user.email}</Row>
    {props.user.plainPassword && (
      <Row label={dictionary.password}>{props.user.plainPassword.password}</Row>
    )}
    <Row label={dictionary.phone_number}>{props.user.phoneNumber || "-"}</Row>
    <Row label={dictionary.biography}>
      <span dangerouslySetInnerHTML={{ __html: props.user.biography || "-" }} />
    </Row>
    <Row label={dictionary.website}>{props.user.website || "-"}</Row>
    <Row label={dictionary.role}>{roleLabels[props.user.role]}</Row>
    <Row label={dictionary.created_at}>
      {formatDate(props.user.createdAt!.toString(), dictionary.date_format)}
    </Row>
  </div>
);
const styles = {
  image: style({
    width: 150,
    height: 150,
    borderRadius: 150,
    marginBottom: 30,
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    margin: "0 auto",
  }),
};
