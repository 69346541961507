import { INewsItem, Role } from "@hulanbv/ssllow-packages";
import React, { FC } from "react";
import { Router } from "react-history-router";
import { Button } from "../components/controls/button";
import { routes } from "../components/routing/routes";
import { CrudTable } from "../components/tables/crud-table";
import { NewsRow } from "../components/tables/custom-rows/news-row ";
import { dictionary } from "../constants/i18n/dictionary";
import { IViewProps } from "../interfaces/view-props.interface";
import { newsService } from "../services/news.service";
import { satisfiesRoles } from "../utilities/satisfies-roles";

export const NewsView: FC<IViewProps> = (props) => (
  <CrudTable<INewsItem>
    service={newsService}
    sortKey="createdAt"
    sortAscending={false}
    headings={{}}
    options={{ populate: ["author"] }}
    customRow={(newsItem) => (
      <NewsRow
        key={newsItem.id}
        newsItem={newsItem}
        attributes={{
          onClick: satisfiesRoles(Role.ADMIN)
            ? () => Router.push(routes.editNews.path, { id: newsItem.id })
            : undefined,
        }}
      />
    )}
    customControls={
      satisfiesRoles(Role.ADMIN) ? (
        <Button
          attributes={{
            onClick: () => Router.push(routes.createNews.path),
          }}
          appearance={"primary"}
        >
          {dictionary.new}
        </Button>
      ) : undefined
    }
  />
);
