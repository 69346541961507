import React, { FC, HTMLAttributes } from "react";
import { style } from "typestyle";

interface IProps {
  attributes?: HTMLAttributes<HTMLDivElement>;
}
export const InfoRow: FC<IProps> = (props) => (
  <div
    {...props.attributes}
    className={[styles.row, props.attributes?.className].join(" ")}
  >
    {props.children}
  </div>
);

const styles = {
  row: style({
    margin: "30px 0 ",
    opacity: 0.54,
    textAlign: "center",
  }),
};
