import {
  IQuestionReport,
  IReportTemplateSection,
} from "@hulanbv/ssllow-packages";
import React, { FC, HTMLAttributes } from "react";
import { style } from "typestyle";
import { colors } from "../../constants/colors";
import { languages } from "../../constants/i18n/dictionary";
import { questionService } from "../../services/question.service";
import { Globe } from "../elements/globe";

interface IProps {
  attributes?: HTMLAttributes<HTMLDivElement>;
  result: IQuestionReport;
  section: IReportTemplateSection;
  language?: keyof typeof languages | string;
}

export const IndicatorReportItem: FC<IProps> = (props) => {
  const score =
    (props.section.showPartnerResultType
      ? props.result.averageScore
      : props.result.userScore) || 0;
  return (
    <div
      {...props.attributes}
      className={[styles.report, props.attributes?.className].join(" ")}
    >
      <div>
        {
          props.result.reviews.find((r) => r.participant.isInvitee)?.participant
            .firstName
        }
      </div>
      <Globe
        background={[colors.starting_point]}
        foreground={[colors.obstacle]}
        filled={score}
        attributes={{ style: { width: 215, height: 215 } }}
      >
        <div className={styles.info}>
          <div className={styles.name}>{props.result.question.name}</div>
          <div className={styles.question}>
            {props.result.question.question}
          </div>
          <div className={styles.score}>
            {questionService.getClosestAnswer(
              props.result.question,
              score,
              props.language
            )}
          </div>
        </div>
      </Globe>

      <div className={styles.reviews}>
        {props.result.reviews
          .filter(({ participant }) => !participant.isInvitee)
          .map((review, i) => (
            <div key={i} className={styles.reviewContainer}>
              <Globe
                background={[colors.starting_point]}
                foreground={[colors.obstacle]}
                filled={review.score}
                attributes={{ style: { width: 40, height: 40 } }}
              >
                <img
                  className={styles.participantImage}
                  src={review.participant.imageThumbPath}
                  alt=""
                />
              </Globe>
              <div className={styles.reviewScore}>
                {questionService.getClosestAnswer(
                  props.result.question,
                  review.score,
                  props.language
                )}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

const styles = {
  report: style({
    display: "inline-flex",
    padding: 10,
    background: "#eee",
    borderRadius: "110px 110px 10px 10px",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
  }),

  info: style({
    padding: 20,
    fontSize: 16,
    zIndex: 1,

    $nest: {
      "& > div": {
        color: "#fff",
        textAlign: "center",
        textShadow: "0px 1px 0px rgba(0, 0, 0, 0.3)",
      },
    },
  }),

  name: style({
    fontWeight: 500,
  }),

  question: style({
    margin: "10px 0",
  }),

  score: style({
    fontWeight: 500,
  }),

  reviews: style({
    padding: "5px 0",
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
  }),

  participantImage: style({
    objectFit: "cover",
    zIndex: 1,
    width: 26,
    height: 26,
    borderRadius: "100%",
  }),

  reviewContainer: style({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  }),

  reviewScore: style({
    whiteSpace: "nowrap",
    overflow: "hidden",
    maxWidth: 45,
    fontSize: 11,
    marginTop: 5,
    textOverflow: "ellipsis",
  }),
};
