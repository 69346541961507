import React, { FC, HTMLAttributes } from "react";
import { classes, stylesheet } from "typestyle";

interface IProps {
  attributes?: HTMLAttributes<HTMLDivElement>;
}

export const FloatingCard: FC<IProps> = (props) => (
  <div
    {...{
      ...props,
      className: classes(styles.floatingCard, props.attributes?.className),
    }}
  >
    {props.children}
  </div>
);

const styles = stylesheet({
  floatingCard: {
    borderRadius: 30,
    background: "white",
    // boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
    border: "1px solid #eee",
    "-webkit-filter": "blur(0)",
    margin: "15px 0",
  },
});
