import { Role } from "@hulanbv/ssllow-packages";
import React, { FC, useEffect } from "react";
import { Router } from "react-history-router";
import { routes } from "../components/routing/routes";
import { IViewProps } from "../interfaces/view-props.interface";
import { satisfiesRoles } from "../utilities/satisfies-roles";

export const HomeView: FC<IViewProps> = () => {
  useEffect(() => {
    if (satisfiesRoles(Role.ADMIN, Role.EXPERT)) {
      Router.replace(routes.news.path);
    } else if (satisfiesRoles(Role.VIEWER)) {
      Router.replace(routes.reports.path);
    } else {
      Router.replace(routes.login.path);
    }
  }, []);

  return <></>;
};
