import { IAsset } from "@hulanbv/ssllow-packages";
import React, { FC } from "react";
import { style } from "typestyle";

interface IProps {
  model: IAsset;
}

export const AssetOption: FC<IProps> = (props) => (
  <div className={styles.container}>
    <div
      className={styles.asset}
      style={{ backgroundImage: `url(${props.model.path})` }}
    />
    {props.model.name}
  </div>
);

const styles = {
  container: style({
    display: "flex",
    alignItems: "center",
  }),
  asset: style({
    width: 20,
    height: 20,
    backgroundSize: "cover",
    backgroundPosition: "center center",
    marginRight: 10,
    borderRadius: 3,
  }),
};
