/* eslint-disable react-hooks/exhaustive-deps */
import {
  AssetType,
  IAsset,
  IQuestion,
  ITeachingAid,
  ICategory,
} from "@hulanbv/ssllow-packages";
import React, { FC, useEffect, useState } from "react";
import { classificationLabels } from "../../constants/classification-labels";
import { questionContextCodes } from "../../constants/context-code-trees";
import { dictionary } from "../../constants/i18n/dictionary";
import { lidLabels } from "../../constants/lid-labels";
import { questionTypeLabels } from "../../constants/question-type-labels";
import { IFormProps } from "../../interfaces/form-props.interface";
import { assetService } from "../../services/asset.service";
import { categoryService } from "../../services/category.service";
import { genreService } from "../../services/genre.service";
import { questionService } from "../../services/question.service";
import { teachingAidService } from "../../services/teaching-aid.service";
import { Button } from "../controls/button";
import { Input } from "../controls/input";
import { AssetOption } from "../controls/option-templates/asset-option";
import { QuestionOption } from "../controls/option-templates/question-option";
import { TeachingAidOption } from "../controls/option-templates/teaching-aid-option";
import { RichText } from "../controls/rich-text";
import { Search } from "../controls/search";
import { Select } from "../controls/select";
import { Form } from "./form";
import { FormRow } from "./form-row";
import { CategoryOption } from "../controls/option-templates/category-option";

export const QuestionForm: FC<IFormProps<IQuestion>> = (props) => {
  const [lid, setLid] = useState<string>("");
  const [points, setPoints] = useState<number[] | null>(null);
  const [genres, setGenres] = useState<{
    [key: string]: string;
  } | null>(null);

  const changeLid = (newLid: string) => {
    setPoints(lidPoints[newLid] || points);
    setLid(newLid);
  };

  useEffect(() => {
    const fetchGenres = async () => {
      const { data } = await genreService.getAll();
      setGenres(
        data.reduce<Record<string, string>>((prev, curr) => {
          prev[curr.id] = curr.name;
          return prev;
        }, {})
      );
    };

    setPoints(props.model?.points || []);
    fetchGenres();
  }, []);

  return (
    <Form {...props}>
      <input type="hidden" name="id" value={props.model?.id} />
      <input type="hidden" name="_id" value={props.model?.id} />

      <FormRow>
        <Input
          label={dictionary.indicator}
          attributes={{
            disabled: props.disabled,
            defaultValue: props.model?.name,
            name: "name",
            required: true,
          }}
        />
      </FormRow>

      <FormRow>
        <RichText
          disabled={props.disabled}
          defaultValue={props.model?.question}
          label={dictionary.question}
          placeholder={dictionary.question_placeholder}
          name="question"
          selectionTree={questionContextCodes}
          hideToolbar
          required
        />
      </FormRow>

      <FormRow>
        <RichText
          disabled={props.disabled}
          defaultValue={props.model?.participantQuestion}
          label={dictionary.partner_question}
          placeholder={dictionary.partner_question_placeholder}
          name="participantQuestion"
          selectionTree={questionContextCodes}
          hideToolbar
          required
        />
      </FormRow>

      <FormRow>
        <RichText
          disabled={props.disabled}
          defaultValue={props.model?.adviceQuestion}
          label={dictionary.advice_question}
          placeholder={dictionary.advice_question_placeholder}
          name="adviceQuestion"
          selectionTree={questionContextCodes}
          hideToolbar
          required
        />
      </FormRow>

      <FormRow>
        <RichText
          disabled={props.disabled}
          defaultValue={props.model?.participantAdviceQuestion}
          label={dictionary.partner_advice_question}
          placeholder={dictionary.partner_advice_question_placeholder}
          name="participantAdviceQuestion"
          selectionTree={questionContextCodes}
          hideToolbar
          required
        />
      </FormRow>

      <FormRow>
        <Select
          options={questionTypeLabels}
          label={dictionary.question_type}
          attributes={{
            disabled: props.disabled,
            defaultValue: props.model?.type,
            name: "type",
            required: true,
          }}
        />
      </FormRow>

      <FormRow>
        <Search<ICategory>
          keyField="id"
          multiple
          label={dictionary.categories}
          disabled={props.disabled}
          defaultValue={props.model?.categories}
          name={"categoryIds"}
          required={true}
          openOnFocus
          find={async (search) =>
            (
              await categoryService.getAll({
                search,
                sort: ["name"],
                limit: 25,
              })
            ).data
          }
          optionTemplate={CategoryOption}
        />
      </FormRow>

      <FormRow>
        <Select
          key={Object.keys(genres || {}).length}
          options={genres || {}}
          label={dictionary.genre}
          attributes={{
            disabled: props.disabled,
            defaultValue: props.model?.genreId,
            name: "genreId",
            required: true,
          }}
        />
      </FormRow>

      <FormRow>
        <Select
          options={classificationLabels}
          label={dictionary.classification}
          attributes={{
            disabled: props.disabled,
            defaultValue: props.model?.classification,
            name: "classification",
          }}
        />
      </FormRow>

      <FormRow>
        <Select
          options={lidLabels}
          label={dictionary.LID}
          attributes={{
            value: lid,
            onChange: (e) => changeLid(e.currentTarget.value),
            disabled: props.disabled,
          }}
        />
      </FormRow>

      <FormRow key={lid}>
        {points &&
          Array(5)
            .fill(null)
            .map((x, i) => (
              <Input
                key={i}
                label={scoreLabels[i]}
                attributes={{
                  disabled: props.disabled,
                  defaultValue: points[i] || 0,
                  onChange: () => changeLid("manual"),
                  name: `points[${i}]`,
                  type: "number",
                  required: true,
                }}
              />
            ))}
      </FormRow>

      <FormRow>
        {[
          dictionary.derivative,
          dictionary.obstructive,
          dictionary.stimulative,
        ].map((label, i) => (
          <Search<IQuestion>
            keyField="id"
            label={label}
            key={i}
            defaultValue={
              props.model?.triangles?.[i] ? [props.model.triangles[i]] : []
            }
            find={async (search) =>
              (
                await questionService.getMany(
                  props.model?.survey?.questionIds ?? [],
                  { search, sort: ["name"], limit: 25 }
                )
              ).data
            }
            optionTemplate={QuestionOption}
            disabled={props.disabled}
            name={`triangleIds[${i}]`}
          />
        ))}
      </FormRow>

      <FormRow>
        <Search<IAsset>
          keyField="id"
          label={dictionary.motivation}
          defaultValue={
            props.model?.motivationalAsset
              ? [props.model?.motivationalAsset]
              : []
          }
          find={async (search) =>
            (
              await assetService.getAll({
                search,
                filter: { type: AssetType.MOTIVATIONAL.toString() },
                sort: ["name"],
                limit: 25,
              })
            ).data
          }
          optionTemplate={AssetOption}
          disabled={props.disabled}
          name="motivationalAssetId"
        />
      </FormRow>

      <FormRow>
        <Search<IAsset>
          keyField="id"
          label={dictionary.partner_motivation}
          defaultValue={
            props.model?.motivationalPartnerAsset
              ? [props.model?.motivationalPartnerAsset]
              : []
          }
          find={async (search) =>
            (
              await assetService.getAll({
                search,
                filter: { type: AssetType.MOTIVATIONAL.toString() },
                sort: ["name"],
                limit: 25,
              })
            ).data
          }
          optionTemplate={AssetOption}
          disabled={props.disabled}
          name="motivationalPartnerAssetId"
        />
      </FormRow>

      <FormRow>
        <RichText
          disabled={props.disabled}
          label={dictionary.explanation}
          name="details"
          defaultValue={props.model?.details}
          maxLength={200}
        />
      </FormRow>

      <FormRow>
        <RichText
          disabled={props.disabled}
          label={dictionary.advice}
          name="advice"
          defaultValue={props.model?.advice}
          maxLength={200}
        />
      </FormRow>

      <FormRow>
        <Search<ITeachingAid>
          keyField="id"
          label={dictionary.teaching_aids}
          find={async (search) =>
            (
              await teachingAidService.getAll({
                search,
                searchScope: ["label", "title"],
                sort: ["title"],
                limit: 25,
              })
            ).data
          }
          defaultValue={props.model?.teachingAids ?? []}
          optionTemplate={TeachingAidOption}
          disabled={props.disabled}
          name="teachingAidIds"
          multiple
          openOnFocus
        />
      </FormRow>

      <FormRow>
        <FormRow>
          <Button
            attributes={{ type: "submit", disabled: props.disabled }}
            appearance="primary"
          >
            {dictionary.submit}
          </Button>
        </FormRow>
      </FormRow>
    </Form>
  );
};

const scoreLabels = [
  dictionary.never,
  dictionary.incidentally,
  dictionary.sometimes,
  dictionary.mostly,
  dictionary.always,
];

const lidPoints: { [key: string]: number[] } = {
  "1": [0, 20, 40, 80, 100],
  "2": [-100, -80, -60, -20, 0],
  "3": [100, 80, 79, 20, 0],
  "4": [0, -20, -40, -80, -100],
};
