import {
  IQuestionReport,
  IReport,
  IReportTemplateSection,
  ResultType,
} from "@hulanbv/ssllow-packages";
import React, { FC, HTMLAttributes } from "react";
import { style } from "typestyle";
import { colors } from "../../constants/colors";
import { questionService } from "../../services/question.service";
import { hydrateString } from "../../utilities/hydrate-strings.utils";
import { Globe } from "../elements/globe";

interface IProps {
  attributes?: HTMLAttributes<HTMLDivElement>;
  result: IQuestionReport;
  section: IReportTemplateSection;
  isPartnerAdvice?: boolean;
  report: IReport;
}

export const TopReportItem: FC<IProps> = (props) => {
  const score =
    (props.section.showPartnerResultType
      ? props.result.averageScore
      : props.result.userScore) || 0;
  const isConstructive = [ResultType.CHERISH, ResultType.STARTING].includes(
    props.section.showPartnerResultType
      ? props.result.partnerResultType!
      : props.result.resultType!
  );

  const closestAnswer = questionService.getClosestAnswer(
    props.result.question,
    score,
    props.report.survey?.language
  );
  let answer = `${props.result.question.question} <div class="${styles.score}">${closestAnswer}</div>`;
  if (
    props.report.user &&
    props.isPartnerAdvice &&
    props.result.question.participantAdviceQuestion
  ) {
    answer = props.result.question.participantAdviceQuestion.replace(
      /\{answer\}/g,
      `<strong>${closestAnswer?.toLowerCase()}</strong>`
    );
  } else if (props.result.question.adviceQuestion) {
    answer = props.result.question.adviceQuestion.replace(
      /\{answer\}/g,
      `<strong>${closestAnswer?.toLowerCase()}</strong>`
    );
  }

  answer = hydrateString(
    answer,
    {
      user: props.report.user,
      expert: props.report.expert,
      organisation: props.report.organisation,
      survey: props.report.survey,
    },
    props.report.survey?.language
  );

  return (
    <Globe
      attributes={props.attributes}
      background={[colors.invitee]}
      foreground={
        isConstructive
          ? colors.constructiveGradient
          : colors.destructiveGradient
      }
      filled={isConstructive ? score : 100 - score}
    >
      <div className={styles.info}>
        <div className={styles.name}>{props.result.question.name}</div>
        <div
          className={styles.question}
          dangerouslySetInnerHTML={{
            __html: answer,
          }}
        />
      </div>
    </Globe>
  );
};

const styles = {
  info: style({
    padding: 20,
    fontSize: 24,
    zIndex: 1,

    $nest: {
      "& div, & strong": {
        color: "#fff",
        textAlign: "center",
      },
    },
  }),

  name: style({
    fontWeight: 600,
  }),

  question: style({
    margin: "10px 0",
    fontSize: 19,
  }),

  score: style({
    margin: "10px 0",
    fontWeight: 600,
  }),
};
