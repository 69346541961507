import React, { FC, HTMLAttributes } from "react";
import { classes, style } from "typestyle";
import closeIcon from "../../assets/svg/icons/close.svg";
import { dictionary } from "../../constants/i18n/dictionary";

interface IProps {
  size?: "small" | "medium";

  attributes?: HTMLAttributes<HTMLDivElement>;
  onDelete?: () => void;
}

export const Pill: FC<IProps> = (props) => (
  <div
    {...props.attributes}
    className={classes(
      styles.pill,
      styles[props.size ?? "medium"],
      props.attributes?.className
    )}
  >
    {props.children}
    {props.onDelete && (
      <div className={styles.delete} onClick={props.onDelete}>
        <img src={closeIcon} alt={dictionary.delete} />
      </div>
    )}
  </div>
);

const styles = {
  pill: style({
    borderRadius: 5,
    background: "#eee",
    margin: "5px 2px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  }),
  delete: style({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
    opacity: 0.54,
    marginLeft: 8,

    $nest: {
      "&:hover": {
        opacity: 1,
      },
      img: {
        height: 15,
      },
    },
  }),

  medium: style({
    padding: 10,
  }),
  small: style({
    padding: "3px 6px",
    fontSize: 10,
  }),
};
