/* eslint-disable react-hooks/exhaustive-deps */
import {
  IOrganisation,
  IParticipation,
  IQuestion,
  IReport,
  IReportTemplate,
  ISurvey,
  ITeam,
  IUser,
  Role,
} from "@hulanbv/ssllow-packages";
import React, { FC, useEffect, useState } from "react";
import { dictionary } from "../../constants/i18n/dictionary";
import { IFormProps } from "../../interfaces/form-props.interface";
import { authenticationService } from "../../services/authentication.service";
import { inviteService } from "../../services/invite.service";
import { organisationService } from "../../services/organisation.service";
import { participationService } from "../../services/participation.service";
import { reportTemplateService } from "../../services/report-template.service";
import { surveyService } from "../../services/survey.service";
import { teamService } from "../../services/team.service";
import { userService } from "../../services/user.service";
import { satisfiesRoles } from "../../utilities/satisfies-roles";
import { Button } from "../controls/button";
import { OrganisationOption } from "../controls/option-templates/organisation-option";
import { ParticipationOption } from "../controls/option-templates/participation-option";
import { ReportTemplateOption } from "../controls/option-templates/report-template-option";
import { SurveyOption } from "../controls/option-templates/survey-option";
import { TeamOption } from "../controls/option-templates/team-option";
import { UserOption } from "../controls/option-templates/user-option";
import { Search } from "../controls/search";
import { QuestionSelector } from "../elements/question-selector";
import { Form } from "./form";
import { FormRow } from "./form-row";

interface IProps extends IFormProps<Partial<IReport>> {
  isTeamReport?: boolean;
}

export const ReportForm: FC<IProps> = (props) => {
  const [organisationId, setOrganisationId] = useState<string | null>(null);
  const [surveyId, setSurveyId] = useState<string | null>(null);
  const [teamId, setTeamId] = useState<string | null>(null);
  const [userId, setUserId] = useState<string | null>(null);
  const [questions, setQuestions] = useState<IQuestion[]>([]);
  const [participations, setParticipations] = useState<IParticipation[] | null>(
    null
  );

  const fetchQuestions = async (_surveyId?: string) => {
    if (!_surveyId) {
      return setQuestions([]);
    }

    const { data } = await surveyService.get(_surveyId, {
      populate: ["questions"],
    });

    setQuestions(data.questions || []);
  };

  const searchSurveys = async (search: string): Promise<ISurvey[]> => {
    if (!organisationId) {
      return [];
    }
    const { data: invites } = await inviteService.getAll({
      filter: { organisationId },
      search,
      searchScope: ["survey.name"],
      populate: ["survey"],
      distinct: "surveyId",
    });

    return invites
      .filter(({ survey }) => !!survey)
      .map(({ survey }) => survey!);
  };

  const searchParticipations = async (
    search: string
  ): Promise<IParticipation[]> => {
    if (!organisationId || !surveyId || (!teamId && !userId)) {
      return [];
    }

    let participations: IParticipation[] = [];
    if (userId) {
      const invites = (
        await inviteService.getAll({
          filter: { organisationId, userId, surveyId },
          populate: ["participations"],
        })
      ).data;

      participations = invites
        .reduce(
          (prev: IParticipation[], curr) => [
            ...prev,
            ...(curr.participations || []),
          ],
          []
        )
        .filter(({ participant }) =>
          [participant.firstName, participant.lastName, participant.email]
            .join(" ")
            .toLowerCase()
            .includes(search.toLowerCase())
        );
    } else if (teamId) {
      participations = (
        await participationService.getByTeamSurvey(teamId, surveyId, {
          search,
          searchScope: [
            "participant.firstName",
            "participant.lastName",
            "participant.email",
          ],
        })
      ).data;
    }

    return participations;
  };

  useEffect(() => {
    setUserId(props.model?.userId || null);
    setOrganisationId(props.model?.organisationId || null);
    setSurveyId(props.model?.surveyId || null);
    setTeamId(props.model?.teamId || null);

    fetchQuestions(props.model?.surveyId);
  }, []);

  useEffect(() => {
    const fetchParticipations = async () => {
      setParticipations(await searchParticipations(""));
    };
    if (teamId) {
      fetchParticipations();
    }
  }, [teamId]);

  return (
    <Form {...props}>
      <input type="hidden" name="id" value={props.model?.id} />

      {!props.isTeamReport && (
        <FormRow>
          <Search<IUser>
            keyField="id"
            label={dictionary.user}
            defaultValue={props.model?.user ? [props.model?.user] : undefined}
            find={async (search) =>
              (
                await userService.getAll({
                  search,
                  sort: ["firstName"],
                  limit: 25,
                })
              ).data
            }
            optionTemplate={UserOption}
            name="userId"
            openOnFocus
            required
            disabled
          />
        </FormRow>
      )}

      <FormRow>
        <Search<IOrganisation>
          keyField="id"
          label={dictionary.organisation}
          defaultValue={
            props.model?.organisation ? [props.model?.organisation] : undefined
          }
          find={async (search) =>
            (
              await organisationService.getAll({
                search,
                sort: ["name"],
                limit: 25,
              })
            ).data
          }
          onChange={(ids) => {
            setOrganisationId(ids[0] || null);
            setSurveyId(null);
            setQuestions([]);
          }}
          optionTemplate={OrganisationOption}
          name="organisationId"
          openOnFocus
          required
          disabled={!props.isTeamReport}
        />
      </FormRow>

      <FormRow>
        <Search<ISurvey>
          key={organisationId || ""}
          keyField="id"
          label={dictionary.reflector}
          defaultValue={props.model?.survey ? [props.model?.survey] : undefined}
          find={searchSurveys}
          onChange={(ids) => {
            setSurveyId(ids[0] || null);
            fetchQuestions(ids[0]);
            setTeamId(null);
          }}
          optionTemplate={SurveyOption}
          name="surveyId"
          openOnFocus
          required
          disabled={!props.isTeamReport}
        />
      </FormRow>

      <FormRow>
        <Search<IUser>
          keyField="id"
          label={dictionary.in_name_of_expert}
          find={async (search) =>
            (
              await userService.getAll({
                search,
                filter: { role: "!" + Role.USER },
                limit: 15,
              })
            ).data
          }
          disabled={props.disabled || !satisfiesRoles(Role.ADMIN)}
          defaultValue={
            !satisfiesRoles(Role.ADMIN)
              ? [authenticationService.getUser()!]
              : undefined
          }
          optionTemplate={UserOption}
          name="expertId"
          openOnFocus
          required
        />
      </FormRow>

      {props.isTeamReport && (
        <FormRow>
          <Search<ITeam>
            key={surveyId || ""}
            keyField="id"
            label={dictionary.team}
            defaultValue={props.model?.team ? [props.model?.team] : undefined}
            find={async (search) =>
              (
                await teamService.getByOrganisationSurvey(
                  organisationId!,
                  surveyId!,
                  { search, sort: ["name"], limit: 25 }
                )
              ).data
            }
            onChange={async (ids) => setTeamId(ids[0] || null)}
            optionTemplate={TeamOption}
            name="teamId"
            openOnFocus
            required
            disabled={!surveyId || props.disabled}
          />
        </FormRow>
      )}

      <FormRow>
        <Search<IParticipation>
          keyField="id"
          key={(teamId || "") + participations?.length}
          label={dictionary.participants}
          defaultValue={participations || props.model?.participations}
          find={searchParticipations}
          optionTemplate={ParticipationOption}
          name="participationIds"
          disabled={(!userId && !teamId) || props.disabled}
          openOnFocus
          multiple
        />
      </FormRow>

      <FormRow>
        <Search<IReportTemplate>
          keyField="id"
          label={dictionary.choose_report_template}
          defaultValue={
            props.model?.template ? [props.model.template] : undefined
          }
          find={async (search) =>
            (
              await reportTemplateService.getBySurvey(surveyId || "", {
                search,
                searchScope: ["name"],
                sort: ["name"],
                limit: 25,
              })
            ).data
          }
          optionTemplate={ReportTemplateOption}
          name="templateId"
          openOnFocus
          placeholder={dictionary.choose_base_template}
        />
      </FormRow>

      <FormRow>
        <QuestionSelector
          questions={questions}
          selectedQuestionIds={
            props.model?.questionIds ?? questions.map(({ id }) => id)
          }
          selectedPriorityQuestionIds={props.model?.priorityQuestionIds ?? []}
        />
      </FormRow>

      <FormRow>
        <FormRow>
          <Button
            attributes={{ type: "submit", disabled: props.disabled }}
            appearance="primary"
          >
            {dictionary.generate}
          </Button>
        </FormRow>
      </FormRow>
    </Form>
  );
};
