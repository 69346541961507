import { IUser, Role } from "@hulanbv/ssllow-packages";
import React, { FC } from "react";
import { Router } from "react-history-router";
import { Button } from "../components/controls/button";
import { routes } from "../components/routing/routes";
import { CrudTable } from "../components/tables/crud-table";
import { UserRow } from "../components/tables/custom-rows/user-row";
import { dictionary } from "../constants/i18n/dictionary";
import { IViewProps } from "../interfaces/view-props.interface";
import { userService } from "../services/user.service";
import { satisfiesRoles } from "../utilities/satisfies-roles";

export const UsersView: FC<IViewProps> = (props) => (
  <CrudTable<IUser>
    service={userService}
    sortKey="lastName"
    headings={{
      imageThumbPath: "",
      lastName: dictionary.name,
      email: dictionary.email,
      language: dictionary.language,
      role: dictionary.role,
    }}
    customRow={(user) => (
      <UserRow
        key={user.id}
        user={user}
        attributes={{
          onClick: () => Router.push(routes.userDetails.path, { id: user.id }),
        }}
      />
    )}
    customControls={
      <>
        {satisfiesRoles(Role.ADMIN) && (
          <Button
            attributes={{ onClick: () => Router.push(routes.createUser.path) }}
            appearance={"primary"}
          >
            {dictionary.new}
          </Button>
        )}
      </>
    }
  />
);
