import {
  IQuestionReport,
  IReport,
  IReportTemplateSection,
  SectionType,
} from "@hulanbv/ssllow-packages";
import React, { FC, HTMLAttributes } from "react";
import { stylesheet } from "typestyle";
import { IReportProps } from "../../interfaces/report-props.interface";
import { CategoryChartReport } from "./category-chart-report";
import { CategorySummaryReport } from "./category-summary-report";
import { DiscussionReport } from "./discussion-report";
import { DotReport } from "./dot-report";
import { ExtendedLegendReport } from "./extended-legend-report";
import { GenericReport } from "./generic-report";
import { HighlightReport } from "./highlight-report";
import { IndicatorReport } from "./indicator-report";
import { LegendReport } from "./legend-report";
import { OverviewReport } from "./overview-report";
import { QuestionSummaryReport } from "./question-summary-report";
import { TopReport } from "./top-report";
import { TotalSummaryReport } from "./total-summary-report";
import { DevelopmentCapacityReport } from "./development-capacity-report";

interface IProps {
  attributes?: HTMLAttributes<HTMLDivElement>;
  section: IReportTemplateSection;
  results: IQuestionReport[];
  filteredResults: IQuestionReport[];
  report: IReport;
  previousSection?: IReportTemplateSection;
  reviewColors: Record<string, string>;
  bundleIndex: number;
  bundleSize: number;
  title: string;
}

export const SectionReport: FC<IProps> = (props) => {
  const ReportComp = sectionTypeComponents[props.section.type] ?? (() => <></>);

  if (!props.filteredResults.length) {
    return <></>;
  }
  return (
    <ReportComp
      bundleIndex={props.bundleIndex}
      bundleSize={props.bundleSize}
      results={props.filteredResults}
      unfilteredResults={props.results}
      report={props.report}
      section={props.section}
      reviewColors={props.reviewColors}
      attributes={{ className: styles.fullWidth }}
      title={props.title}
    />
  );
};

const sectionTypeComponents: Record<SectionType, FC<IReportProps>> = {
  [SectionType.TEXT]: () => <></>,
  [SectionType.GENERIC_REPORT]: GenericReport,
  [SectionType.INDICATOR_REPORT]: IndicatorReport,
  [SectionType.DISCUSSION_REPORT]: DiscussionReport,
  [SectionType.OVERVIEW_REPORT]: OverviewReport,
  [SectionType.TOP_REPORT]: TopReport,
  [SectionType.QUESTION_SUMMARY_REPORT]: QuestionSummaryReport,
  [SectionType.CATEGORY_SUMMARY_REPORT]: CategorySummaryReport,
  [SectionType.TOTAL_SUMMARY_REPORT]: TotalSummaryReport,
  [SectionType.LEGEND]: LegendReport,
  [SectionType.EXTENDED_LEGEND]: ExtendedLegendReport,
  [SectionType.HIGHLIGHT_REPORT]: HighlightReport,
  [SectionType.DOT_REPORT]: DotReport,
  [SectionType.CATEGORY_CHART_REPORT]: CategoryChartReport,
  [SectionType.DEVELOPMENT_CAPACITY]: DevelopmentCapacityReport,
};

const styles = stylesheet({
  fullWidth: {
    width: "100%",
  },
});
