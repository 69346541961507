import leftPageBackground from "../assets/images/left-page-background.png";
import rightPageBackground from "../assets/images/right-page-background.png";
import titlePageBackground from "../assets/images/title-page-background.png";

export const handlePrintStyles = () => {
  if (!window.location.search.includes("print")) {
    return;
  }

  setTimeout(() => {
    document.head.innerHTML = document.head.innerHTML += `<style>
    @page {
        size: A4;
        margin: 30mm 15mm;

        /* Temporary fix for pixelated svg backgrounds in Chrome  */
        /* https://stackoverflow.com/questions/9562330/svg-pixelation-in-chrome */
        opacity: 0.99;
        background-size: 100% 100%;
    
        @bottom-right-corner {
            content: counter(page);
        }
    }
    @page:first {
        background-image: url('${titlePageBackground}');

        @bottom-right-corner {
            content: none;
        }
    }
    @page:left {
        background-image: url('${leftPageBackground}');
    }
    @page:right {
        background-image: url('${rightPageBackground}');
    }
    .pagedjs_margin-bottom-center .pagedjs_margin-content {
        opacity: 0.54;
    }


    .break-before {
        break-before: page;
    }
    .break-after {
        break-after: page;
    }

    @media print {
        .hide-on-print {
            display: none;
        }
    }
    </style>`;

    import("pagedjs/dist/paged.polyfill" as any);
  }, 500);
};
