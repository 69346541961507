import { IParticipation } from "@hulanbv/ssllow-packages";
import React, { FC } from "react";
import { style } from "typestyle";

interface IProps {
  model: IParticipation;
}

export const ParticipationOption: FC<IProps> = (props) => (
  <div>
    {props.model.participant.firstName} {props.model.participant.lastName}{" "}
    <span className={styles.secondary}>{props.model.participant.email}</span>
  </div>
);

const styles = {
  secondary: style({
    opacity: 0.54,
  }),
};
