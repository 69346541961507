import {
  ITeachingAid,
  ITeachingAidCompletion,
  TeachingAidType,
} from "@hulanbv/ssllow-packages";
import marked from "marked";
import React, { FC, HTMLAttributes } from "react";
import { classes, style } from "typestyle";
import neutralLabelBackground from "../../assets/svg/backgrounds/neutral-label.svg";
import { FloatingCard } from "../containers/floating-card";
import { H1 } from "../typography/h1";

interface IProps {
  attributes?: HTMLAttributes<HTMLDivElement>;
  aid: ITeachingAid;
  completion?: ITeachingAidCompletion;
}

export const TeachingAid: FC<IProps> = (props) => {
  return (
    <FloatingCard
      attributes={{
        ...props.attributes,
        className: classes(props.attributes?.className, styles.teachingAid),
      }}
    >
      {props.completion?.createdAt && (
        <div className={styles.completedRibbon}>
          Afgerond op{" "}
          {new Date(props.completion?.createdAt).toLocaleDateString("nl-NL")}
        </div>
      )}
      <H1 attributes={{ className: styles.h2 }}>{props.aid.title}</H1>

      {props.aid.type === TeachingAidType.IMAGE && props.aid.asset?.path && (
        <img src={props.aid.asset?.path} className={styles.image} alt="" />
      )}

      {props.aid.type === TeachingAidType.VIDEO && props.aid.url && (
        <a
          className={styles.anchor}
          href={props.aid.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {props.aid.url}
        </a>
      )}

      {props.aid.description && (
        <p
          dangerouslySetInnerHTML={{ __html: marked(props.aid.description) }}
        />
      )}

      {props.aid.type === TeachingAidType.LINK && props.aid.url && (
        <a
          className={styles.anchor}
          href={props.aid.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {props.aid.url}
        </a>
      )}
    </FloatingCard>
  );
};

const styles = {
  teachingAid: style({
    pageBreakInside: "avoid",
    padding: 30,
    margin: "40px 0",
    position: "relative",
  }),
  image: style({
    maxWidth: "100%",
    height: "auto",
    borderRadius: 10,
    marginTop: 20,
  }),
  h2: style({
    justifyContent: "space-around",
    fontSize: "1.12rem",
    color: "#000",
    fontWeight: 500,
    paddingBottom: 0,
  }),
  anchor: style({
    margin: "20px 0",
    display: "block",
  }),
  completedRibbon: style({
    background: `url(${neutralLabelBackground})`,
    color: "white !important",
    backgroundSize: "180px 40px",
    minWidth: 180,
    minHeight: 40,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    right: 30,
    top: -20,
  }),
};
