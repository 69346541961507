import { ITeachingAid } from "@hulanbv/ssllow-packages";
import React, { FC } from "react";
import { style } from "typestyle";
import { teachingAidTypeLabels } from "../../../constants/asset-type-labels";
import { routes } from "../../routing/routes";

interface IProps {
  model: ITeachingAid;
}

export const TeachingAidOption: FC<IProps> = (props) => (
  <a
    href={routes.editTeachingAid.path.replace(":id", props.model.id)}
    target="_blank"
    rel="noopener noreferrer"
    className={styles.option}
  >
    {props.model.title}{" "}
    <span className={styles.secondary}>
      - {props.model.label} - {teachingAidTypeLabels[props.model.type]}
    </span>
  </a>
);

const styles = {
  option: style({
    textDecoration: "none",
    color: "inherit",
  }),

  secondary: style({
    opacity: 0.54,
  }),
};
