import { IReportTemplate } from "@hulanbv/ssllow-packages";
import { CrudService, IHttpOptions, IResponse } from "nest-utilities-client";
import { environment } from "../constants/environment.constant";
import { httpService } from "./http.service";

class Service extends CrudService<IReportTemplate> {
  constructor() {
    super(
      [environment.REACT_APP_API_URL, "report-template"].join("/"),
      httpService
    );
  }

  getBySurvey(
    surveyId: string,
    options?: IHttpOptions
  ): Promise<IResponse<IReportTemplate[]>> {
    return httpService.get(
      [this.controller, "survey", surveyId].join("/"),
      options
    );
  }
}

export const reportTemplateService = new Service();
