import React, { FC } from "react";
import { IDropdownOption } from "../../../interfaces/dropdown-option.interface";

interface IProps {
  model: IDropdownOption;
}

export const DropdownOptionOption: FC<IProps> = (props) => (
  <div onMouseDown={() => props.model.onClick?.()}>{props.model.label}</div>
);
