import React, { FC, HTMLAttributes } from "react";
import { style } from "typestyle";
import { SortIcon } from "../statics/sort-icon";
import { StyledTR } from "./styled-table-row";
import { TD } from "./table-data";
import { TH } from "./table-head";
import { TR } from "./table-row";

type Headings<ItemType = any> =
  | {
      [key in keyof ItemType]: any;
    }
  | { [key: string]: any };

export interface ITableProps<ItemType = any> {
  attributes?: HTMLAttributes<HTMLDivElement>;

  headings: Headings<ItemType>;
  items: ItemType[];

  sortKey?: string;
  sortAscending?: boolean;

  onHeadClick?: (key: string) => void;
  onRowClick?: (item: ItemType) => void;
  customHeadRow?: (props: ITableProps<ItemType>) => React.FC;
  customRow?: (
    model: ItemType,
    index: number,
    props: ITableProps<ItemType>
  ) => React.ReactElement;
}

export const Table: FC<ITableProps> = (props) => (
  <div
    {...props.attributes}
    className={[styles.table, props.attributes?.className].join(" ")}
  >
    {/* Table head */}
    {props.customHeadRow ? (
      props.customHeadRow(props)
    ) : (
      <TR>
        {Object.keys(props.headings).map((key, i) => (
          <TH
            key={i}
            attributes={{
              onClick: () => props.onHeadClick?.(key),
            }}
          >
            <div className={styles.thContent}>{props.headings[key]}</div>
            {props.sortKey === key && (
              <SortIcon ascending={props.sortAscending || false} />
            )}
          </TH>
        ))}
      </TR>
    )}

    {/* Table rows */}
    {props.items.map((item, i) =>
      props.customRow ? (
        props.customRow(item, i, props)
      ) : (
        <StyledTR
          key={i}
          attributes={{
            onClick: () => props.onRowClick?.(item),
          }}
        >
          {Object.keys(props.headings).map((key, i) => (
            <TD key={i}>{item[key]}</TD>
          ))}
        </StyledTR>
      )
    )}
  </div>
);

const styles = {
  table: style({
    display: "table",
    width: "100%",
  }),

  thContent: style({
    display: "inline-block",
    marginRight: 10,
    userSelect: "none",
  }),
};
