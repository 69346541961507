import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { Singleton } from "react-singletons";
import { style } from "typestyle";
import { routeListener } from "../observables/route-listener";

interface IProps extends React.PropsWithChildren<{}> {
  target: HTMLElement;
}

const closeOnEscape = (e: KeyboardEvent) => {
  if (e.key === "Escape") {
    popup.unmount();
  }
};

export const Popup: FC<IProps> = (props) => {
  const [visible, setVisible] = useState<boolean>(false);
  const popupRef = useRef<HTMLDivElement>(null);

  const position = useCallback(() => {
    if (!popupRef.current) {
      return;
    }
    // set the popup location
    const dialogActive = document.body.style.overflow === "hidden";
    const offsets = dialogActive
      ? props.target.getBoundingClientRect()
      : {
          left: props.target.offsetLeft,
          top: props.target.offsetTop,
          width: props.target.clientWidth,
        };
    const additiveY = dialogActive ? window.pageYOffset : 0;

    const targetX = offsets.left + offsets.width / 2;
    const targetY = offsets.top + additiveY;
    popupRef.current.style.left = `${Math.floor(
      targetX - popupRef.current.clientWidth / 2
    )}px`;
    popupRef.current.style.top = `${Math.floor(
      targetY - popupRef.current.clientHeight
    )}px`;

    window.setTimeout(() => setVisible(true), 10);

    setTimeout(() => position(), 1000 / 60);
  }, [props.target]);

  const closeOnBodyClick = (e: MouseEvent) => {
    if (
      e.target instanceof Node &&
      !popupRef.current?.contains(e.target) &&
      document.contains(e.target)
    ) {
      popup.unmount();
    }
  };

  useEffect(() => {
    const listener = routeListener.subscribe(() => popup.unmount());
    document.addEventListener("keyup", closeOnEscape);
    document.addEventListener("click", closeOnBodyClick);
    position();

    return () => {
      document.removeEventListener("keyup", closeOnEscape);
      document.removeEventListener("click", closeOnBodyClick);
      listener.unsubscribe();
    };
  }, [position]);

  return (
    <div
      ref={popupRef}
      className={[styles.popup, visible ? styles.visible : ""].join(" ")}
    >
      {props.children}
    </div>
  );
};

const styles = {
  popup: style({
    zIndex: 1000,
    position: "absolute",
    transform: "translateY(10px)",
    opacity: 0,
    transition: ".2s transform, .2s opacity",
    padding: 10,

    background: "#fff",
    borderRadius: 6,
    border: "1px solid #dedede",
    boxShadow: "0 0 3px 0 rgba(0,0,0,.2)",
  }),
  visible: style({
    transform: "translateY(0px)",
    opacity: 1,
  }),
};

export const popup = new Singleton<IProps>(Popup);
