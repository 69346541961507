import { SectionType } from "@hulanbv/ssllow-packages";
import { dictionary } from "./i18n/dictionary";

export const sectionTypeLabels: Record<SectionType, string> = {
  [SectionType.TEXT]: dictionary.text,
  [SectionType.LEGEND]: dictionary.legend,
  [SectionType.EXTENDED_LEGEND]: dictionary.legend_extended,
  [SectionType.GENERIC_REPORT]: dictionary.generic_report,
  [SectionType.INDICATOR_REPORT]: dictionary.indicator_report,
  [SectionType.OVERVIEW_REPORT]: dictionary.overview_report,
  [SectionType.DISCUSSION_REPORT]: dictionary.discussion_report,
  [SectionType.TOP_REPORT]: dictionary.top_report,
  [SectionType.QUESTION_SUMMARY_REPORT]: dictionary.question_summary_report,
  [SectionType.CATEGORY_SUMMARY_REPORT]: dictionary.category_summary_report,
  [SectionType.TOTAL_SUMMARY_REPORT]: dictionary.total_summary_report,
  [SectionType.HIGHLIGHT_REPORT]: dictionary.highlight_report,
  [SectionType.DOT_REPORT]: dictionary.dot_report,
  [SectionType.CATEGORY_CHART_REPORT]: dictionary.category_chart_report,
  [SectionType.DEVELOPMENT_CAPACITY]: dictionary.development_capacity,
};
