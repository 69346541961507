import { HttpService, IResponse } from "nest-utilities-client";
import Router from "react-history-router";
import { IError } from "../interfaces/error.interface";
import { authenticationService } from "./authentication.service";

class CustomHttpService extends HttpService {
  getHeaders(): { [header: string]: string } {
    return {
      Authorization: authenticationService.getSession()?.token ?? "",
    };
  }

  onRequestError(error: IResponse<IError>): void {
    // log out when the user is unauthorized
    if (error.status === 401) {
      Router.push("/logout");
    }
  }
}

export const httpService = new CustomHttpService();
