import { IPublicInvite } from "@hulanbv/ssllow-packages";
import React, { FC, HTMLAttributes } from "react";
import { StyledTR } from "../styled-table-row";
import { TD } from "../table-data";
import { dictionary } from "../../../constants/i18n/dictionary";
import { environment } from "../../../constants/environment.constant";

interface IProps {
  publicInvite: IPublicInvite;
  attributes?: HTMLAttributes<HTMLDivElement>;

  onDeleteParticipation?: (id: string) => any;
}

export const PublicInviteRow: FC<IProps> = (props) => {
  return (
    <StyledTR
      attributes={{
        ...{
          ...props.attributes,
          className: [props.attributes?.className].join(" "),
        },
      }}
    >
      <TD>{props.publicInvite.organisation?.name}</TD>
      <TD>{props.publicInvite.survey?.name}</TD>
      <TD>
        {props.publicInvite.createdAt
          ? new Date(props.publicInvite.createdAt).toLocaleDateString()
          : ""}
      </TD>
      <TD attributes={{ onClick: (e) => e.stopPropagation() }}>
        <a
          href={environment.REACT_APP_APP_REGISTRATION_URL.replace(
            ":publicInviteId",
            props.publicInvite.id ?? ""
          )}
          target="__blank"
        >
          {dictionary.invite}
        </a>
      </TD>
    </StyledTR>
  );
};
