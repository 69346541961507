import React, { FC } from "react";
import { style } from "typestyle";
import { IReportProps } from "../../interfaces/report-props.interface";
import { SummaryReportItem } from "./summary-report-item";

export const QuestionSummaryReport: FC<IReportProps> = (props) => {
  return (
    <div
      {...props.attributes}
      className={[styles.report, props.attributes?.className].join(" ")}
    >
      {props.results.map((result, i) => (
        <SummaryReportItem
          key={i}
          attributes={{ className: styles.item }}
          title={result.question.name || "-"}
          results={[result]}
          section={props.section}
          reviewColors={props.reviewColors}
        />
      ))}
    </div>
  );
};

const styles = {
  report: style({
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginBottom: 25,
  }),
  item: style({
    marginBottom: 25,
  }),
};
