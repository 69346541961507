import { IQuestionReport, ResultType } from "@hulanbv/ssllow-packages";
import React, { FC } from "react";
import { style } from "typestyle";
import { IReportProps } from "../../interfaces/report-props.interface";
import { TopReportItem } from "./top-report-item";

export const TopReport: FC<IReportProps> = (props) => {
  const results: IQuestionReport[] = [];

  const useUserScore = !props.section.showPartnerResultType;
  const scoreKey = useUserScore ? "userScore" : "averageScore";
  const isConstructive = [ResultType.CHERISH, ResultType.STARTING].includes(
    results[0]?.[useUserScore ? "resultType" : "partnerResultType"]!
  );
  const selection = props.results
    .filter((result) => result[scoreKey] !== undefined)
    .slice(0, 10);
  [...selection].forEach((x, i) => {
    if (i % 2 === 0) {
      results.push(selection.shift()!);
    } else {
      results.push(selection.pop()!);
    }
  });
  const scores = results
    .map((result) => result[scoreKey]!)
    .filter((x) => x !== undefined);

  // push at least 1 score to avoid infinity values
  if (scores.length === 0) {
    scores.push(0);
  }

  const max = Math.max(...scores);
  const min = Math.min(...scores);
  const range = max - min || 1; // avoid division by 0
  return (
    <div
      {...props.attributes}
      className={[styles.report, props.attributes?.className].join(" ")}
    >
      {results.map((result, i) => {
        const rotation = Math.max(0, i - 1) * (360 / (results.length - 1 || 1));
        let scale = (max - (result[scoreKey] || 0)) / range;
        if (!isConstructive) {
          scale = 1 - scale;
        }
        return (
          <div
            key={i}
            className={styles.itemContainer}
            style={{
              transform: `translate(-50%, -50%) rotate(${rotation}deg)`,
              height: i === 0 ? 300 : undefined,
            }}
          >
            <TopReportItem
              section={props.section}
              result={result}
              report={props.report}
              isPartnerAdvice={props.section.showPartnerResultType}
              attributes={{
                className: styles.reportItem,
                style: {
                  // move the first item to the center
                  transform: `translate(-50%, 0) rotate(-${rotation}deg) scale(${
                    (i === 0 ? 1 : 0.75) - scale * 0.1
                  })`,
                },
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

const styles = {
  report: style({
    position: "relative",
    height: 800,
    transform: "scale(0.8)",
    pageBreakInside: "avoid",
  }),
  reportItem: style({
    left: "50%",
    width: 300,
    height: 300,
  }),
  itemContainer: style({
    zIndex: 1,
    height: 950,
    width: 300,
    position: "absolute",
    left: "50%",
    top: "50%",
  }),
};
