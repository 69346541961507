import {
  IInviteConfiguration,
  IOrganisation,
  IReportTemplate,
  ISurvey,
  ITeam,
  IUser,
  Role,
} from "@hulanbv/ssllow-packages";
import React, { FC, useState } from "react";
import { inviteContextCodes } from "../../constants/context-code-trees";
import { dictionary } from "../../constants/i18n/dictionary";
import { IFormProps } from "../../interfaces/form-props.interface";
import { organisationService } from "../../services/organisation.service";
import { reportTemplateService } from "../../services/report-template.service";
import { surveyService } from "../../services/survey.service";
import { satisfiesRoles } from "../../utilities/satisfies-roles";
import { Button } from "../controls/button";
import { Checkbox } from "../controls/checkbox";
import { DateInput } from "../controls/date-input";
import { Input } from "../controls/input";
import { OrganisationOption } from "../controls/option-templates/organisation-option";
import { ReportTemplateOption } from "../controls/option-templates/report-template-option";
import { SurveyOption } from "../controls/option-templates/survey-option";
import { TeamOption } from "../controls/option-templates/team-option";
import { UserOption } from "../controls/option-templates/user-option";
import { RichText } from "../controls/rich-text";
import { Search } from "../controls/search";
import { InfoRow } from "../statics/info-row";
import { Form } from "./form";
import { FormRow } from "./form-row";

export const InviteConfigurationForm: FC<IFormProps<IInviteConfiguration>> = (
  props
) => {
  const canEditTexts = satisfiesRoles(Role.ADMIN);
  const [organisation, setOrganisation] = useState<IOrganisation | null>(null);
  const [survey, setSurvey] = useState<string | null>(null);
  const [teams, setTeams] = useState<ITeam[]>([]);
  const [users, setUsers] = useState<IUser[]>([]);
  const [selectedTeams, setSelectedTeams] = useState<string[]>(
    props.model?.teams || []
  );
  const [selectedUsers, setSelectedUsers] = useState<string[]>(
    props.model?.users || []
  );

  /**
   * Fetch the options created by selecting an organisation
   * @param organisationId
   */
  const fetchOptions = async (organisationId: string) => {
    try {
      const { data } = await organisationService.get(organisationId, {
        populate: ["teams", "members"],
      });
      setOrganisation(data || null);
      setUsers(data?.members || []);
      setTeams(data?.teams || []);
    } catch {
      setOrganisation(null);
      setUsers([]);
      setTeams([]);
    }
  };

  return (
    <Form {...props}>
      <FormRow>
        <Search<IOrganisation>
          keyField="id"
          label={dictionary.organisation}
          defaultValue={
            props.model?.invite.organisation
              ? [props.model?.invite.organisation]
              : []
          }
          find={async (search) =>
            (
              await organisationService.getAll({
                search,
                searchScope: ["name"],
                sort: ["name"],
                limit: 25,
              })
            ).data
          }
          onChange={(ids: string[]) => fetchOptions(ids[0])}
          optionTemplate={OrganisationOption}
          disabled={props.disabled}
          name="invite[organisationId]"
          openOnFocus
          required
        />
      </FormRow>

      <FormRow>
        <Search<ISurvey>
          keyField="id"
          label={dictionary.reflector}
          defaultValue={
            props.model?.invite.survey ? [props.model?.invite.survey] : []
          }
          find={async (search) =>
            (await surveyService.getAll({ search, sort: ["name"], limit: 25 }))
              .data
          }
          onChange={(ids: string[]) => setSurvey(ids[0])}
          optionTemplate={SurveyOption}
          disabled={props.disabled}
          name="invite[surveyId]"
          openOnFocus
          required
        />
      </FormRow>

      <FormRow>
        <Search<IReportTemplate>
          keyField="id"
          multiple
          label={dictionary.specific_app_templates}
          defaultValue={[]}
          find={async (search) =>
            (
              await reportTemplateService.getBySurvey(survey ?? "", {
                search,
                sort: ["name"],
                limit: 25,
              })
            ).data
          }
          optionTemplate={ReportTemplateOption}
          disabled={props.disabled || !survey}
          name="invite[reportTemplateIds]"
          openOnFocus
        />
      </FormRow>

      <FormRow>
        <Search<ITeam>
          keyField="id"
          label={dictionary.teams}
          find={async (search) =>
            teams.filter((team) =>
              JSON.stringify(team).toLowerCase().includes(search.toLowerCase())
            )
          }
          onChange={(selection) => setSelectedTeams(selection)}
          optionTemplate={TeamOption}
          disabled={!!selectedUsers.length || props.disabled || !teams.length}
          name="teams"
          multiple
          openOnFocus
        />
      </FormRow>

      <FormRow>
        <Search<IUser>
          keyField="id"
          label={dictionary.individual_persons}
          find={async (search) =>
            users.filter((user) =>
              JSON.stringify(user).toLowerCase().includes(search.toLowerCase())
            )
          }
          optionTemplate={UserOption}
          onChange={(selection) => setSelectedUsers(selection)}
          disabled={!!selectedTeams.length || props.disabled || !users.length}
          name="users"
          multiple
          openOnFocus
        />
      </FormRow>

      <FormRow>
        <Input
          label={dictionary.number_of_participants}
          attributes={{
            type: "number",
            defaultValue: props.model?.invite.maxParticipants,
            name: "invite[maxParticipants]",
            required: true,
            min: 0,
            max: 4,
          }}
        />
      </FormRow>

      <FormRow>
        <DateInput
          label={dictionary.deadline}
          attributes={{
            defaultValue: props.model?.invite.deadline.toString(),
            name: "invite[deadline]",
            required: true,
          }}
        />
      </FormRow>

      {!organisation && <InfoRow>{dictionary.select_an_organisation}</InfoRow>}
      {!!organisation && (
        <>
          <FormRow>
            <RichText
              label={dictionary.app_intro_without_salutation}
              name="invite[appIntro]"
              defaultValue={organisation?.appIntro}
              selectionTree={inviteContextCodes}
              outputType="markdown"
              disabled={!canEditTexts}
              hideToolbar={!canEditTexts}
            />
          </FormRow>

          <FormRow>
            <RichText
              label={dictionary.reflector_intro}
              name="invite[surveyIntro]"
              defaultValue={organisation?.surveyIntro}
              selectionTree={inviteContextCodes}
              outputType="markdown"
              disabled={!canEditTexts}
              hideToolbar={!canEditTexts}
            />
          </FormRow>

          <FormRow>
            <RichText
              label={dictionary.reflector_participant_intro}
              name="invite[surveyParticipantIntro]"
              defaultValue={organisation?.surveyParticipantIntro}
              selectionTree={inviteContextCodes}
              outputType="markdown"
              disabled={!canEditTexts}
              hideToolbar={!canEditTexts}
            />
          </FormRow>

          <FormRow>
            <RichText
              label={dictionary.reflector_ending}
              name="invite[surveyEnding]"
              defaultValue={organisation?.surveyEnding}
              selectionTree={inviteContextCodes}
              outputType="markdown"
              disabled={!canEditTexts}
              hideToolbar={!canEditTexts}
            />
          </FormRow>

          <FormRow>
            <RichText
              label={dictionary.reflector_participant_ending}
              name="invite[surveyParticipantEnding]"
              defaultValue={organisation?.surveyParticipantEnding}
              selectionTree={inviteContextCodes}
              outputType="markdown"
              disabled={!canEditTexts}
              hideToolbar={!canEditTexts}
            />
          </FormRow>

          <FormRow>
            <RichText
              label={dictionary.email_without_salutation_and_closing}
              name="invite[mailContent]"
              outputType="html"
              defaultValue={organisation?.mailContent}
              selectionTree={inviteContextCodes}
              disabled={!canEditTexts}
              hideToolbar={!canEditTexts}
            />
          </FormRow>
        </>
      )}

      <FormRow>
        <Checkbox
          label={dictionary.show_indicator_during_reflector}
          attributes={{
            disabled: props.disabled,
            checked: props.model?.invite.showIndicator,
            name: "invite[showIndicator]",
          }}
        />
      </FormRow>

      <FormRow>
        <Checkbox
          label={dictionary.show_access_code_to_user}
          attributes={{
            disabled: props.disabled,
            checked: props.model?.invite.showAccessCode,
            name: "invite[showAccessCode]",
          }}
        />
      </FormRow>

      <FormRow>
        <Checkbox
          label={dictionary.show_participants_during_reflector}
          attributes={{
            disabled: props.disabled,
            checked: props.model?.invite.showCharacters,
            name: "invite[showCharacters]",
          }}
        />
      </FormRow>

      <FormRow>
        <Checkbox
          label={dictionary.show_participant_results_to_user}
          attributes={{
            disabled: props.disabled,
            checked: props.model?.invite.showReactions,
            name: "invite[showReactions]",
          }}
        />
      </FormRow>

      <FormRow>
        <Checkbox
          label={dictionary.show_action_and_advice}
          attributes={{
            disabled: props.disabled,
            checked: props.model?.invite.showResults,
            name: "invite[showResults]",
          }}
        />
      </FormRow>

      <FormRow>
        <Checkbox
          label={dictionary.enable_reports}
          attributes={{
            disabled: props.disabled,
            checked: props.model?.invite.enableReports,
            name: "invite[enableReports]",
          }}
        />
      </FormRow>

      <FormRow>
        <Checkbox
          label={dictionary.trial_reflector}
          attributes={{
            disabled: props.disabled,
            checked: props.model?.invite.isTrial,
            name: "invite[isTrial]",
          }}
        />
      </FormRow>

      <FormRow>
        <FormRow>
          <Button
            attributes={{ type: "submit", disabled: props.disabled }}
            appearance="primary"
          >
            {dictionary.submit}
          </Button>
        </FormRow>
      </FormRow>
    </Form>
  );
};
