import React, { FC, SelectHTMLAttributes } from "react";
import { style } from "typestyle";
import { Label } from "../typography/label";

export interface ISelectProps {
  attributes?: SelectHTMLAttributes<HTMLSelectElement>;
  label?: string;
  options: { [key: string]: string | number };
  noEmptyOption?: boolean;
  sortOnValue?: boolean;
}

export const Select: FC<ISelectProps> = (props) => {
  const options = Object.entries(props.options).map(([value, label]) => ({
    value,
    label,
  }));

  // sort on label if applicable
  if (!props.sortOnValue) {
    options.sort((a, b) => {
      if (a.label === b.label) {
        return 0;
      }
      return a.label > b.label ? 1 : -1;
    });
  }

  return (
    <div>
      {props.label && (
        <Label required={props.attributes?.required}>{props.label}</Label>
      )}
      <select
        {...props.attributes}
        className={[styles.select, props.attributes?.className].join(" ")}
      >
        {!props.noEmptyOption && <option value="">-</option>}
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

const styles = {
  select: style({
    outline: 0,
    borderRadius: 6,
    border: "1px solid #dedede",
    fontSize: 12,
    padding: "15px 20px",
    fontFamily: "Montserrat, sans-serif",
    width: "100%",
    backgroundColor: "#fff",
    "-webkit-appearance": "none",
    backgroundImage: `linear-gradient(45deg, transparent 50%, gray 50%),
                      linear-gradient(135deg, gray 50%, transparent 50%)`,
    backgroundPosition: `
                      calc(100% - 20px) calc(1em + 8px),
                      calc(100% - 15px) calc(1em + 8px)`,
    backgroundSize: `
                      5px 5px,
                      5px 5px`,
    backgroundRepeat: "no-repeat",
  }),
};
