import {
  IQuestionReport,
  IReport,
  IReportTemplateSection,
  SectionType,
} from "@hulanbv/ssllow-packages";
import React, { FC } from "react";
import { classes, style } from "typestyle";
import pageBreakIcon from "../../assets/svg/icons/page-break.svg";
import { colors } from "../../constants/colors";
import { dictionary } from "../../constants/i18n/dictionary";
import { HideOnPrint } from "../elements/hide-on-print";
import { UserTag } from "../elements/user-tag";
import { H1 } from "../typography/h1";
import { SectionReport } from "./section-report";

interface IProps {
  section: IReportTemplateSection;
  previousSection?: IReportTemplateSection;
  results: IQuestionReport[];
  filteredResults: IQuestionReport[];
  report: IReport;
  reviewColors: Record<string, string>;
  bundleIndex: number;
  bundleSize: number;
}

/**
 * A list of section types which position the title themselves. This is used to
 * determine whether or not to render the title on the page.
 */
const internalTitleSections = [SectionType.TOTAL_SUMMARY_REPORT];

export const SectionBundleItem: FC<IProps> = (props) => {
  /**
   * Hydrate a text with real values
   * @param text
   */
  const hydrate = (text: string) => {
    /**
     * Searches for values in an object. When encountering an array, the
     * algorithm will branch and attempts to fetch the value from each index.
     * @param query
     * @param haystack
     */
    const get = (query: string, haystack: Record<string, any>): string[] => {
      const journey = query.split(".");
      const direction = journey.shift() || "";
      let values: string[] = [];

      const next = haystack[direction];
      if (next) {
        for (const stop of Array.isArray(next) ? next : [next]) {
          if (journey.length > 0) {
            values = values.concat(get(journey.join("."), stop));
          } else {
            values.push(stop);
          }
        }
      }

      return values;
    };

    const matches = Array.from(text.match(/{[^}]*}/g) || []);
    const custom = {
      // all participants who are not the invitee
      participants: props.report.participations
        ?.filter(({ participant }) => !participant.isInvitee)
        .map(({ participant }) => participant),
    };
    for (const match of matches) {
      const needles = get(match.replace(/[{}]/g, ""), {
        ...props.report,
        ...custom,
      });

      let value = "-";
      if (needles.length > 1) {
        value = `${needles.slice(0, -1).join(", ")} ${dictionary.and} ${
          needles[needles.length - 1]
        }`;
      } else if (needles.length === 1) {
        value = needles[0];
      }

      text = text.replace(match, value);
    }
    return text;
  };
  const title = hydrate(props.section.title ?? "");
  return (
    <div
      className={classes(
        props.section.pageBreakBefore && "break-before",
        props.section.pageBreakAfter && "break-after"
      )}
    >
      <HideOnPrint>
        {props.section.pageBreakBefore && (
          <img
            src={pageBreakIcon}
            title={dictionary.force_new_page}
            alt={dictionary.force_new_page}
            className={styles.pageBreak}
            style={{ marginBottom: 30 }}
          />
        )}
      </HideOnPrint>

      {props.section.title &&
        !internalTitleSections.includes(props.section.type) && (
          <div className={styles.fullWidth}>
            <H1 attributes={{ className: styles.center }}>{title}</H1>
            {props.section.type !== SectionType.TEXT && props.report?.user && (
              <UserTag
                user={props.report.user}
                color={colors.invitee}
                attributes={{ className: styles.userTag }}
              />
            )}
          </div>
        )}
      {props.section.description && (
        <div
          className={classes(
            props.section.centerDescription && styles.center,
            styles.fullWidth
          )}
          dangerouslySetInnerHTML={{
            __html: hydrate(props.section.description),
          }}
        />
      )}

      {props.section.type !== SectionType.TEXT && (
        <SectionReport
          title={title}
          reviewColors={props.reviewColors}
          section={props.section}
          results={props.results}
          filteredResults={props.filteredResults}
          report={props.report}
          previousSection={props.previousSection}
          bundleIndex={props.bundleIndex}
          bundleSize={props.bundleSize}
        />
      )}

      {props.section.pageBreakAfter && (
        <HideOnPrint>
          <img
            src={pageBreakIcon}
            title={dictionary.force_new_page}
            alt={dictionary.force_new_page}
            className={styles.pageBreak}
            style={{ marginTop: 40 }}
          />
        </HideOnPrint>
      )}
    </div>
  );
};

const styles = {
  pageBreak: style({
    position: "relative",
    margin: "0 auto",
    display: "block",
  }),
  controls: style({
    display: "flex",
    flexDirection: "row-reverse",

    $nest: {
      "& > *": {
        width: "auto",
        margin: "0 5px",
      },
    },
  }),
  center: style({
    display: "block",
    textAlign: "center",
  }),
  hideOnPrint: "hide-on-print",
  userTag: style({
    position: "relative",
    margin: "0 auto",
    width: "fit-content",
  }),
  fullWidth: style({
    width: "100%",
  }),
};
