import React, { FC } from "react";
import { style } from "typestyle";
import { IReportProps } from "../../interfaces/report-props.interface";
import { GenericReportItem } from "./generic-report-item";

export const GenericReport: FC<IReportProps> = (props) => (
  <div
    {...props.attributes}
    className={[styles.report, props.attributes?.className].join(" ")}
  >
    {props.results.map((result, i) => (
      <GenericReportItem
        key={i}
        hideHead={!!i}
        result={result}
        report={props.report}
        section={props.section}
        reviewColors={props.reviewColors}
      />
    ))}
  </div>
);

const styles = {
  report: style({
    marginBottom: 25,
    fontSize: 14,
  }),
};
