import { Role } from "@hulanbv/ssllow-packages";
import React, { FC } from "react";
import { style } from "typestyle";
import { Card } from "../components/containers/card";
import { CrudForm } from "../components/forms/crud-form";
import { InviteForm } from "../components/forms/invite-form";
import { dictionary } from "../constants/i18n/dictionary";
import { IViewProps } from "../interfaces/view-props.interface";
import { inviteService } from "../services/invite.service";
import { satisfiesRoles } from "../utilities/satisfies-roles";

export const InviteEditView: FC<IViewProps> = (props) => (
  <Card attributes={{ className: styles.card }}>
    <CrudForm
      messageSubject={dictionary.invite}
      form={InviteForm}
      modelId={props.routing.params.id}
      onDone={() => {
        window.history.back();
      }}
      service={inviteService}
      readOnly={!satisfiesRoles(Role.ADMIN, Role.EXPERT)}
      usePut
    />
  </Card>
);

const styles = {
  card: style({
    maxWidth: 650,
    margin: "0 auto",
  }),
};
