/* eslint-disable react-hooks/exhaustive-deps */
import {
  IImportedUser,
  IOrganisation,
  IUser,
  Role,
  IReportTemplate,
} from "@hulanbv/ssllow-packages";
import React, { FC, useEffect, useState } from "react";
import Router from "react-history-router";
import { style } from "typestyle";
import { Card } from "../components/containers/card";
import { Button } from "../components/controls/button";
import { FileSelect } from "../components/controls/file-select";
import { UserOption } from "../components/controls/option-templates/user-option";
import { Search } from "../components/controls/search";
import { OrganisationData } from "../components/elements/organisation-data";
import { TeamData } from "../components/elements/team-data";
import { CrudForm } from "../components/forms/crud-form";
import { TeamForm } from "../components/forms/team-form";
import { UserForm } from "../components/forms/user-form";
import { routes } from "../components/routing/routes";
import { H1 } from "../components/typography/h1";
import { dictionary } from "../constants/i18n/dictionary";
import { IViewProps } from "../interfaces/view-props.interface";
import { notificationListener } from "../observables/notification-listener";
import { organisationService } from "../services/organisation.service";
import { teamService } from "../services/team.service";
import { userService } from "../services/user.service";
import { dialog } from "../singletons/dialog";
import { satisfiesRoles } from "../utilities/satisfies-roles";
import { reportTemplateService } from "../services/report-template.service";
import { ReportTemplateOption } from "../components/controls/option-templates/report-template-option";

export const OrganisationDetailsView: FC<IViewProps> = (props) => {
  const [organisation, setOrganisation] = useState<IOrganisation | null>(null);

  const fetchOrganisation = async () => {
    try {
      const { data } = await organisationService.get(props.routing.params.id, {
        populate: [
          "teams.expert",
          "teams.members",
          "experts",
          "members",
          "contact",
          "reportTemplates",
        ],
      });
      setOrganisation(data);
    } catch {
      Router.push(routes.organisations.path);
    }
  };

  const importUsers = async (files: string[]) => {
    const users: IImportedUser[] = [];
    for (const file of files) {
      const csv = file.split("\n").map((line) => line.split(","));
      const head = (csv.shift() ?? []).map((th) => th.trim());
      for (const row of csv) {
        const user: IImportedUser = {};
        row.forEach((value, columnIndex) => {
          (user as any)[head[columnIndex]] = value.trim();
        });
        if (Object.values(user).join("")) {
          users.push(user);
        }
      }
    }
    if (window.confirm(dictionary.confirm_importing_x_users(users.length))) {
      await userService.createForOrganisation(organisation?.id, users);
      await fetchOrganisation();
      notificationListener.next({
        message: dictionary.successfully_created(dictionary.users),
      });
    }
  };

  const saveOrganisation = async (organisation: IOrganisation) => {
    await organisationService.patch(organisation);
    fetchOrganisation();
  };

  useEffect(() => {
    fetchOrganisation();
  }, [props.routing.params.id]);

  if (!organisation) {
    return <></>;
  }
  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <Card attributes={{ className: styles.card }}>
          <OrganisationData organisation={organisation} />
          <Button
            attributes={{
              onClick: () =>
                Router.push(routes.editOrganisation.path, {
                  id: organisation.id,
                }),
            }}
          >
            {dictionary.edit_organisation}
          </Button>
        </Card>

        {satisfiesRoles(Role.ADMIN) && (
          <Card attributes={{ className: styles.card }}>
            <H1>{dictionary.experts_or_spectators}</H1>
            <Search<IUser>
              keyField="id"
              key={organisation.expertIds.length}
              find={async (search) =>
                (
                  await userService.getAll({
                    search,
                    filter: { role: "!" + Role.USER },
                    sort: ["firstName"],
                    limit: 25,
                  })
                ).data
              }
              defaultValue={organisation.experts}
              optionTemplate={UserOption}
              multiple
              onChange={(expertIds: string[]) =>
                saveOrganisation({ ...organisation, expertIds })
              }
            />
          </Card>
        )}

        {satisfiesRoles(Role.ADMIN) && (
          <Card attributes={{ className: styles.card }}>
            <H1>Toeschouwer sjablonen</H1>
            <Search<IReportTemplate>
              keyField="id"
              key={organisation.reportTemplateIds.length}
              find={async (search) =>
                (
                  await reportTemplateService.getAll({
                    search,
                    sort: ["name"],
                    limit: 25,
                  })
                ).data
              }
              defaultValue={organisation.reportTemplates}
              optionTemplate={ReportTemplateOption}
              multiple
              openOnFocus
              onChange={(reportTemplateIds: string[]) =>
                saveOrganisation({ ...organisation, reportTemplateIds })
              }
            />
          </Card>
        )}

        <Card attributes={{ className: styles.card }}>
          <H1>{dictionary.users}</H1>

          <Search<IUser>
            keyField="id"
            disabled={!satisfiesRoles(Role.ADMIN, Role.EXPERT)}
            key={organisation.memberIds.length}
            find={async (search) =>
              (
                await userService.getAll({
                  search,
                  filter: { role: Role.USER },
                  sort: ["firstName"],
                  limit: 25,
                })
              ).data
            }
            defaultValue={organisation.members}
            optionTemplate={UserOption}
            multiple
            onChange={(memberIds: string[]) =>
              saveOrganisation({ ...organisation, memberIds })
            }
          />
          {satisfiesRoles(Role.ADMIN, Role.EXPERT) && (
            <>
              <Button
                size="small"
                attributes={{
                  style: { margin: "20px 0 10px 0" },
                  onClick: () =>
                    dialog.mount({
                      title: dictionary.create_user,
                      children: (
                        <UserForm
                          availableRoles={[Role.USER]}
                          onSubmit={async (body) => {
                            const { data: user } = await userService.post(body);
                            if (user) {
                              await organisationService.patch({
                                id: organisation.id,
                                memberIds: organisation.memberIds.concat(
                                  user.id
                                ),
                              });
                              fetchOrganisation();
                            }
                            dialog.unmount();
                          }}
                        />
                      ),
                    }),
                }}
              >
                {dictionary.add_new_user}
              </Button>
              <FileSelect accept="csv" onChange={importUsers}>
                {dictionary.import_users}
              </FileSelect>
            </>
          )}
        </Card>
      </div>

      <div className={styles.right}>
        {(organisation.teams || []).map((team) => (
          <Card key={team.id} attributes={{ className: styles.card }}>
            <TeamData
              key={team.memberIds.length}
              team={team}
              disabled={!satisfiesRoles(Role.ADMIN, Role.EXPERT)}
              onChange={() => fetchOrganisation()}
            />
            {satisfiesRoles(Role.ADMIN, Role.EXPERT) && (
              <Button
                size="small"
                attributes={{
                  style: { width: "auto" },
                  onClick: () =>
                    dialog.mount({
                      title: dictionary.create_user,
                      children: (
                        <UserForm
                          availableRoles={[Role.USER]}
                          onSubmit={async (body) => {
                            const { data: user } = await userService.post(body);
                            if (user) {
                              await teamService.patch({
                                id: team.id,
                                memberIds: team.memberIds.concat(user.id),
                              });
                              fetchOrganisation();
                            }
                            dialog.unmount();
                          }}
                        />
                      ),
                    }),
                }}
              >
                {dictionary.add_new_user}
              </Button>
            )}
          </Card>
        ))}

        <div className={styles.alignRight}>
          {satisfiesRoles(Role.ADMIN, Role.EXPERT) && (
            <Button
              appearance="primary"
              attributes={{
                style: { flex: 0 },
                onClick: () =>
                  dialog.mount({
                    title: dictionary.create_team,
                    children: (
                      <CrudForm
                        messageSubject={dictionary.team}
                        form={TeamForm}
                        service={teamService}
                        onDone={async (team) => {
                          if (team) {
                            await organisationService.patch({
                              id: organisation.id,
                              teamIds: organisation.teamIds.concat(team.id),
                            });
                            fetchOrganisation();
                          }
                          dialog.unmount();
                        }}
                      />
                    ),
                  }),
              }}
            >
              {dictionary.create_team}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: style({
    display: "flex",
  }),
  left: style({
    maxWidth: 350,
    flex: 1,
    margin: 15,
    marginTop: 0,
  }),
  right: style({
    flex: 1,
    margin: 15,
    marginTop: 0,
  }),
  alignRight: style({
    display: "flex",
    flexDirection: "row-reverse",
    marginTop: 30,
  }),
  card: style({
    marginBottom: 20,
  }),
};
