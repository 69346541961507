import { AssetType, IAsset, Role } from "@hulanbv/ssllow-packages";
import React, { FC } from "react";
import { Router } from "react-history-router";
import { Button } from "../components/controls/button";
import { routes } from "../components/routing/routes";
import { CrudTable } from "../components/tables/crud-table";
import { MotivationRow } from "../components/tables/custom-rows/motivation-row";
import { dictionary } from "../constants/i18n/dictionary";
import { IViewProps } from "../interfaces/view-props.interface";
import { assetService } from "../services/asset.service";
import { satisfiesRoles } from "../utilities/satisfies-roles";

export const MotivationsView: FC<IViewProps> = (props) => (
  <CrudTable<IAsset>
    service={assetService}
    sortKey="lastName"
    headings={{
      path: "",
      name: dictionary.name,
    }}
    options={{ filter: { type: AssetType.MOTIVATIONAL } }}
    customRow={(asset) => (
      <MotivationRow
        key={asset.id}
        asset={asset}
        attributes={{
          onClick: () =>
            Router.push(routes.editMotivation.path, { id: asset.id }),
        }}
      />
    )}
    customControls={
      satisfiesRoles(Role.ADMIN, Role.EXPERT) && (
        <Button
          attributes={{
            onClick: () => Router.push(routes.createMotivation.path),
          }}
          appearance={"primary"}
        >
          {dictionary.new}
        </Button>
      )
    }
  />
);
