import { IInviteConfiguration, IPublicInvite } from "@hulanbv/ssllow-packages";
import React, { FC, useCallback, useState } from "react";
import { style } from "typestyle";
import { Card } from "../components/containers/card";
import { Tabs } from "../components/controls/tabs";
import { PublicInviteForm } from "../components/forms/public-invite-form";
import { InviteConfigurationForm } from "../components/forms/invite-configuration-form";
import { dictionary } from "../constants/i18n/dictionary";
import { IViewProps } from "../interfaces/view-props.interface";
import { notificationListener } from "../observables/notification-listener";
import { inviteService } from "../services/invite.service";
import { formDataToObject } from "../utilities/form.utils";
import { CrudTable } from "../components/tables/crud-table";
import { PublicInviteRow } from "../components/tables/custom-rows/public-invite-row";
import Router from "react-history-router";
import { routes } from "../components/routing/routes";
import { publicInviteService } from "../services/public-invite.service";

export const InviteView: FC<IViewProps> = (props) => {
  const [view, resetView] = useState<boolean>(true);

  const sendInvite = async (data: FormData) => {
    const config = formDataToObject<IInviteConfiguration>(data);
    if ((config.teams?.length || 0) + (config.users?.length || 0) === 0) {
      notificationListener.next({
        message: dictionary.you_need_to_select_at_least_a_team_or_a_user,
      });
      throw new Error("You need to define a team or a user");
    }

    await inviteService.sendInvites(data);
    notificationListener.next({
      message: dictionary.invites_sent,
    });
    window.scrollTo(0, 0);
    resetView((val) => !val);
  };

  const createInviteLink = useCallback(async (data: FormData) => {
    const { data: invite } = await publicInviteService.post(data);
    Router.push(routes.editPublicInvite.path, { id: invite.id });
  }, []);

  return (
    <Card attributes={{ className: styles.card }} key={view.toString()}>
      <Tabs
        items={[
          {
            label: dictionary.direct_invite,
            children: (
              <InviteConfigurationForm
                model={inviteConfiguration}
                onSubmit={(data) => sendInvite(data)}
              />
            ),
          },
          {
            label: dictionary.invite_links,
            children: (
              <CrudTable<IPublicInvite>
                service={publicInviteService}
                customFind={(options) =>
                  publicInviteService.getAll({
                    ...options,
                    populate: ["organisation", "survey"],
                    searchScope: ["organisation.name", "survey.name"],
                  })
                }
                sortKey="-createdAt"
                headings={{
                  organisationId: dictionary.organisation,
                  surveyId: dictionary.reflector,
                  createdAt: dictionary.created_at,
                  "": "",
                }}
                customRow={(invite) => (
                  <PublicInviteRow
                    key={invite.id}
                    publicInvite={invite}
                    attributes={{
                      onClick: () =>
                        Router.push(routes.editPublicInvite.path, {
                          id: invite.id,
                        }),
                    }}
                  />
                )}
              />
            ),
          },
          {
            label: dictionary.create_invite_link,
            children: <PublicInviteForm onSubmit={createInviteLink} />,
          },
        ]}
      />
    </Card>
  );
};

const styles = {
  card: style({
    maxWidth: 650,
    margin: "0 auto",
  }),
};

const twoWeeksForward = new Date();
twoWeeksForward.setDate(twoWeeksForward.getDate() + 14);
const inviteConfiguration: IInviteConfiguration = {
  teams: [],
  users: [],
  invite: {
    deadline: twoWeeksForward,
    maxParticipants: 4,
    appIntro: "",
    surveyIntro: "",
    surveyParticipantIntro: "",
    surveyEnding: "",
    surveyParticipantEnding: "",
    mailContent: "",
    showAccessCode: false,
    showCharacters: false,
    showIndicator: false,
    showReactions: false,
    showResults: false,
    enableReports: false,
    reportTemplateIds: [],
    open: true,
    accessCode: "",
    organisationId: "",
    surveyId: "",
    userId: "",
    isTrial: false,
  },
};
