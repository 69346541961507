import { IParticipant, IUser } from "@hulanbv/ssllow-packages";
import React, { FC, HTMLAttributes } from "react";
import { style } from "typestyle";
import { Shell } from "./shell";

interface IProps {
  attributes?: HTMLAttributes<HTMLDivElement>;
  user?: IUser | IParticipant;
  color?: string;
  size?: number;
}

export const UserPicture: FC<IProps> = (props) => (
  <div {...props.attributes}>
    {!props.user?.imagePath && (
      <Shell
        color={props.color ?? "#c4bec4"}
        attributes={{
          className: styles.bigShell,
          style: {
            height: props.size ?? 35,
            minWidth: props.size ?? 35,
          },
        }}
      />
    )}
    {props.user?.imagePath && (
      <div
        className={styles.profilePicture}
        style={{
          backgroundImage: `url(${props.user?.imagePath})`,
          borderColor: props.color,
          height: props.size ?? 35,
          minWidth: props.size ?? 35,
        }}
      >
        <Shell
          color={props.color ?? "#c4bec4"}
          attributes={{ className: styles.miniShell }}
        />
      </div>
    )}
  </div>
);
const styles = {
  profilePicture: style({
    borderRadius: "100%",
    border: "2px solid #c4bec4",
    position: "relative",
    flex: 0,
    backgroundSize: "cover",
  }),
  miniShell: style({
    position: "absolute",
    right: -2,
    bottom: -2,
    width: "40%",
    height: "40%",
    backgroundColor: "white",
    borderRadius: "100%",
  }),
  bigShell: style({
    backgroundColor: "white",
    borderRadius: "100%",
  }),
};
