import React, { FC, HTMLAttributes, useEffect, useRef } from "react";
import { classes, style } from "typestyle";
import { IOptionTemplate } from "./search";

interface IProps<OptionType> {
  attributes?: HTMLAttributes<HTMLDivElement>;
  options: OptionType[];
  optionTemplate: FC<IOptionTemplate<OptionType>>;
  onClick?: (item: OptionType, event: React.MouseEvent) => void;

  disableStyles?: boolean;
}

export function Dropdown<OptionType>(props: IProps<OptionType>) {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const { current } = ref;
    if (current) {
      const { x, width } = current.getBoundingClientRect();
      current.style.marginLeft =
        Math.min(document.body.clientWidth - Math.ceil(x + width), 0) + "px";
    }
  }, [props.attributes]);
  return (
    <div
      {...props.attributes}
      className={[styles.dropdown, props.attributes?.className].join(" ")}
      ref={ref}
    >
      {props.options.map((option, i) => (
        <div
          key={i}
          onClick={(e) => props.onClick?.(option, e)}
          className={classes(!props.disableStyles && styles.option)}
        >
          <props.optionTemplate model={option} />
        </div>
      ))}
    </div>
  );
}

const styles = {
  dropdown: style({
    borderRadius: 13,
    zIndex: 1,
    boxShadow: "0 2px 3px rgba(0,0,0,.13)",
    borderTop: "1px solid #c4bec4",
    background: "#fff",
    padding: "10px 0",
    maxHeight: 305,
    overflowY: "auto",
  }),
  option: style({
    padding: "15px 30px",
    cursor: "pointer",
    whiteSpace: "nowrap",
    $nest: { "&:hover": { background: "#fafafa" } },
  }),
};
