import { IReportTemplate } from "@hulanbv/ssllow-packages";
import React, { FC } from "react";

interface IProps {
  model: IReportTemplate;
}

export const ReportTemplateOption: FC<IProps> = (props) => (
  <div>{props.model.name}</div>
);
