import { IInvite, IReport } from "@hulanbv/ssllow-packages";
import React, { FC, useState } from "react";
import { dictionary } from "../../constants/i18n/dictionary";
import { notificationListener } from "../../observables/notification-listener";
import { authenticationService } from "../../services/authentication.service";
import { inviteService } from "../../services/invite.service";
import { Button } from "../controls/button";
import { InviteOption } from "../controls/option-templates/invite-option";
import { Search } from "../controls/search";
import { Form } from "./form";
import { FormRow } from "./form-row";

interface IProps {
  invite: IInvite;
  onSubmit: (report: IReport) => any;
}

export const CompareInvitesForm: FC<IProps> = (props) => {
  const [comparisonInviteId, setComparisonInviteId] = useState<string | null>(
    null
  );

  const submit = async () => {
    const { data: invites } = await inviteService.getMany(
      [comparisonInviteId, props.invite.id],
      {
        populate: ["participations", "survey"],
      }
    );
    const source = invites.find(({ id }) => id === props.invite.id);
    const sourceParticipation = source?.participations?.find(
      ({ participant }) => participant.isInvitee
    );
    const comparison = invites.find(({ id }) => id !== props.invite.id);
    const comparisonParticipation = comparison?.participations?.find(
      ({ participant }) => participant.isInvitee
    );
    if (!source || !sourceParticipation || !comparisonParticipation) {
      notificationListener.next({
        message: dictionary.user_did_not_finish_a_reflector,
      });
      return;
    }

    const report: IReport = {
      expertId: authenticationService.getUser()?.id,
      organisationId: props.invite.organisationId,
      participationIds: [sourceParticipation.id, comparisonParticipation?.id],
      priorityQuestionIds: [],
      questionIds: source.survey?.questionIds ?? [],
      surveyId: source.surveyId,
      userId: source.userId,
    };

    props.onSubmit(report);
  };

  return (
    <Form {...props} onSubmit={() => submit()}>
      <FormRow>
        <Search<IInvite>
          keyField="id"
          label={dictionary.source_report}
          defaultValue={[props.invite]}
          find={async () => (await inviteService.getAll({})).data}
          optionTemplate={InviteOption}
          disabled
        />
      </FormRow>
      <FormRow>
        <Search<IInvite>
          keyField="id"
          label={dictionary.comparison_report}
          onChange={(selection) => setComparisonInviteId(selection[0] ?? null)}
          find={async (search) =>
            (
              await inviteService.getAll({
                filter: {
                  surveyId: props.invite.surveyId,
                  userId: props.invite.userId,
                  _id: "!" + props.invite.id,
                },
                populate: ["participations", "user", "organisation", "survey"],
                search,
                searchScope: [
                  "user.fullName",
                  "user.teams.name",
                  "survey.name",
                  "organisation.name",
                ],
                sort: ["-createdAt"],
                limit: 25,
              })
            ).data
          }
          optionTemplate={InviteOption}
          openOnFocus
          required
        />
      </FormRow>

      <FormRow>
        <FormRow>
          <Button attributes={{ type: "submit" }} appearance="primary">
            {dictionary.submit}
          </Button>
        </FormRow>
      </FormRow>
    </Form>
  );
};
