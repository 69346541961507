import React, { FC, useEffect, useState } from "react";
import { stylesheet } from "typestyle";
import { colors } from "../../constants/colors";
import { IReportProps } from "../../interfaces/report-props.interface";

export const CategoryChartReport: FC<IReportProps> = (props) => {
  const [categoryAverageScores, setCategoryAverageScores] = useState<
    Record<string, number>
  >({});

  useEffect(() => {
    // iterate the results and register each score for each category
    const categoryScores: Record<string, number[]> = {};
    props.results.forEach((result) => {
      const score = props.section.showPartnerResultType
        ? result.averageScore
        : result.userScore;

      result.question.categories?.forEach((category) => {
        if (category === undefined || score === undefined) {
          return;
        }
        if (!categoryScores[category.name]) {
          categoryScores[category.name] = [];
        }
        categoryScores[category.name].push(score);
      });
    });

    // calculate the average score for each category
    const categoryAverageScores: Record<string, number> = {};
    Object.entries(categoryScores).forEach(([category, scores]) => {
      categoryAverageScores[category] = Math.round(
        scores.reduce((a, b) => a + b, 0) / scores.length
      );
    });
    setCategoryAverageScores(categoryAverageScores);
  }, [props.results, props.section.showPartnerResultType]);

  return (
    <div
      {...props.attributes}
      className={[styles.report, props.attributes?.className].join(" ")}
    >
      {Object.entries(categoryAverageScores).map(([category, average], i) => (
        <div className={styles.item} key={i}>
          <div className={styles.nameColumn}>{category}</div>
          <div className={styles.scoreColumn}>
            <div
              className={styles.scoreBar}
              style={{ width: `${average - 10}%` }}
            />
            <div className={styles.score}>{average}%</div>
          </div>
        </div>
      ))}
    </div>
  );
};

const styles = stylesheet({
  report: {
    paddingTop: 30,
  },
  item: {
    width: "100%",
    display: "flex",
    pageBreakInside: "avoid",

    "&:not(:last-child) div": {
      borderBottomWidth: 0,
    },
  },
  nameColumn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    flex: 1,
    borderRight: `1px solid ${colors.invitee}`,
    padding: 20,
    color: colors.invitee,
    fontSize: 16,
    fontWeight: 600,
  },
  scoreColumn: {
    flex: 3,
    display: "flex",
    alignItems: "center",
    borderBottom: `1px solid ${colors.invitee}`,
  },
  scoreBar: {
    background: `linear-gradient(to right, ${colors.neutralGradient[0]} 0%, ${colors.neutralGradient[1]} 100%)`,
    height: 40,
  },
  score: {
    marginLeft: 10,
    color: colors.invitee,
    fontSize: 16,
    fontWeight: 600,
  },
});
