import { IQuestionReport } from "@hulanbv/ssllow-packages";
import React, { FC, useEffect, useState } from "react";
import { style } from "typestyle";
import { getLanguage } from "../../constants/i18n/dictionary";
import { IReportProps } from "../../interfaces/report-props.interface";
import { Dictionary } from "../../types/dictionary.type";
import { H1 } from "../typography/h1";
import { IndicatorReportItem } from "./indicator-report-item";

export const IndicatorReport: FC<IReportProps> = (props) => {
  const [questionResults, setQuestionResults] =
    useState<Dictionary<IQuestionReport> | null>(null);

  useEffect(() => {
    setQuestionResults(
      props.unfilteredResults.reduce<Dictionary<IQuestionReport>>(
        (prev, curr) => {
          prev[curr.question.id] = curr;
          return prev;
        },
        {}
      )
    );
  }, [props.unfilteredResults]);

  if (!questionResults) {
    return <></>;
  }
  return (
    <div
      {...props.attributes}
      className={[styles.report, props.attributes?.className].join(" ")}
    >
      {props.results.map((result, i) => (
        <div key={i} style={{ pageBreakAfter: "always" }}>
          <H1>{result.question.name}</H1>

          <div className={styles.indicatorItem}>
            <IndicatorReportItem
              result={result}
              attributes={{ style: { margin: "50px 0" } }}
              section={props.section}
              language={props.report.survey?.language}
            />

            <div className={styles.supportHead}>
              {getLanguage(props.report.survey?.language).supporting_indicators}
            </div>
            <div className={styles.supportContainer}>
              {result.question.triangleIds.map(
                (id, i) =>
                  questionResults[id] && (
                    <IndicatorReportItem
                      key={i}
                      result={questionResults[id]}
                      section={props.section}
                      language={props.report.survey?.language}
                    />
                  )
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const styles = {
  report: style({}),
  indicatorItem: style({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 100,
  }),
  supportHead: style({
    fontSize: 16,
    marginBottom: 40,
  }),
  supportContainer: style({
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
    marginBottom: 50,
  }),
  developmentAdvice: style({
    padding: 20,
    borderRadius: 8,
    background: "#eee",
    marginBottom: 50,
  }),
};
