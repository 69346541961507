import { ICategory, IQuestion } from "@hulanbv/ssllow-packages";
import React, { FC, HTMLAttributes, useEffect, useState } from "react";
import { style } from "typestyle";
import minusIcon from "../../assets/svg/icons/minus.svg";
import plusIcon from "../../assets/svg/icons/plus.svg";
import starOutlineIcon from "../../assets/svg/icons/star-outline.svg";
import starIcon from "../../assets/svg/icons/star.svg";
import { dictionary } from "../../constants/i18n/dictionary";
import { categoryService } from "../../services/category.service";
import { Dictionary } from "../../types/dictionary.type";
import { Checkbox } from "../controls/checkbox";

interface IProps {
  questions: IQuestion[];
  selectedQuestionIds?: string[];
  selectedPriorityQuestionIds?: string[];

  attributes?: HTMLAttributes<HTMLDivElement>;
}

export const QuestionSelector: FC<IProps> = (props) => {
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [categoryQuestions, setCategoryQuestions] = useState<
    Dictionary<IQuestion[]>
  >({});
  const [openedCategories, setOpenedCategories] = useState<Dictionary<boolean>>(
    {}
  );

  const [selectedQuestionIds, setSelectedQuestionIds] = useState<
    Dictionary<boolean>
  >({});
  const [selectedPriorityQuestionIds, setSelectedPriorityQuestionIds] =
    useState<Dictionary<boolean>>({});

  /**
   * Organises the given questions by category
   * @param questions
   */
  const getOrganizedQuestions = (questions: IQuestion[]) =>
    questions.reduce<Dictionary<IQuestion[]>>((prev, curr) => {
      curr.categoryIds.forEach((categoryId) => {
        prev[categoryId] = (prev[categoryId] || []).concat(curr);
      });
      return prev;
    }, {});

  const fetchCategories = async (categoryIds: string[]) => {
    if (categoryIds.length === 0) {
      setCategories([]);
      return;
    }

    const { data } = await categoryService.getMany(categoryIds);
    setCategories(data);
  };

  useEffect(() => {
    const _categoryQuestions = getOrganizedQuestions(props.questions);
    setCategoryQuestions(_categoryQuestions);
    fetchCategories(Object.keys(_categoryQuestions));

    const reduceIds = (prev: Dictionary<boolean>, curr: string) => ({
      ...prev,
      [curr]: true,
    });
    setSelectedQuestionIds(
      props.selectedQuestionIds?.reduce<Dictionary<boolean>>(reduceIds, {}) ??
        {}
    );
    setSelectedPriorityQuestionIds(
      props.selectedPriorityQuestionIds?.reduce<Dictionary<boolean>>(
        reduceIds,
        {}
      ) ?? {}
    );
  }, [
    props.questions,
    props.selectedPriorityQuestionIds,
    props.selectedQuestionIds,
  ]);

  return (
    <div
      {...props.attributes}
      className={[styles.selector, props.attributes?.className].join(" ")}
    >
      {/* hidden inputs for form capabilities */}
      {Object.keys(selectedQuestionIds)
        .filter((id) => selectedQuestionIds[id])
        .map((id) => (
          <input type="hidden" name="questionIds[]" value={id} key={id} />
        ))}
      {Object.keys(selectedPriorityQuestionIds)
        .filter((id) => selectedPriorityQuestionIds[id])
        .map((id) => (
          <input
            type="hidden"
            name="priorityQuestionIds[]"
            value={id}
            key={id}
          />
        ))}

      {/* question selector */}
      {categories.map((category) => {
        const categoryChecked = !!categoryQuestions[category.id].some(
          (question) => selectedQuestionIds[question.id]
        );
        return (
          <div key={category.id}>
            <div>
              <div className={[styles.row, styles.categoryRow].join(" ")}>
                <div className={styles.checkColumn}>
                  <Checkbox
                    attributes={{
                      checked: categoryChecked,
                      onChange: ({ currentTarget }) => {
                        setSelectedQuestionIds((curr) => ({
                          ...curr,
                          ...categoryQuestions[category.id].reduce(
                            (prev, curr) => ({
                              ...prev,
                              [curr.id]: currentTarget.checked,
                            }),
                            {}
                          ),
                        }));
                      },
                    }}
                  />
                </div>
                <div className={styles.categoryName}>{category.name}</div>
                <div
                  className={styles.checkColumn}
                  onClick={() =>
                    setOpenedCategories((curr) => ({
                      ...curr,
                      [category.id]: !openedCategories[category.id],
                    }))
                  }
                >
                  <img
                    src={openedCategories[category.id] ? minusIcon : plusIcon}
                    alt="toggle"
                    className={styles.toggle}
                  />
                </div>
              </div>
            </div>

            <div
              style={{
                display: openedCategories[category.id] ? "block" : "none",
              }}
            >
              {categoryQuestions[category.id].map((question) => (
                <div key={question.id}>
                  <div className={styles.row}>
                    <div className={styles.checkColumn}>
                      <Checkbox
                        attributes={{
                          title: dictionary.include_in_report,
                          checked: selectedQuestionIds[question.id],
                          onChange: ({ currentTarget }) =>
                            setSelectedQuestionIds((curr) => ({
                              ...curr,
                              [question.id]: currentTarget.checked,
                            })),
                        }}
                      />
                    </div>
                    <div className={styles.indicator}>{question.name}</div>
                    <div className={styles.question}>{question.question}</div>
                    <div className={styles.genre}>{question.genre?.name}</div>
                    <div
                      className={styles.checkColumn}
                      onClick={() =>
                        setSelectedPriorityQuestionIds((curr) => ({
                          ...curr,
                          [question.id]:
                            !selectedPriorityQuestionIds[question.id],
                        }))
                      }
                    >
                      <img
                        src={
                          selectedPriorityQuestionIds[question.id]
                            ? starIcon
                            : starOutlineIcon
                        }
                        alt="toggle"
                        className={styles.toggle}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const styles = {
  selector: style({
    fontSize: 12,
  }),

  row: style({
    display: "flex",
    width: "100%",
    alignItems: "center",
    padding: 10,
  }),

  categoryRow: style({
    background: "#F5F5F5",
    borderRadius: 5,
  }),

  categoryName: style({
    flex: 1,
    fontWeight: "bold",
  }),

  indicator: style({
    minWidth: 100,
    flex: 0,
  }),

  question: style({
    flex: 1,
  }),

  genre: style({
    minWidth: 60,
    flex: 0,
  }),

  checkColumn: style({
    flex: 0,
    minWidth: 30,
  }),

  toggle: style({
    cursor: "pointer",
    height: 16,
    opacity: 0.87,
    $nest: {
      "&:hover": {
        opacity: 1,
      },
    },
  }),
};
