import React, { FC } from "react";
import { style } from "typestyle";

interface IProps {
  required?: boolean;
}

export const Label: FC<IProps> = (props) => (
  <label
    className={[styles.label, props?.required ? styles.mandatory : ""].join(
      " "
    )}
  >
    {props.children}
  </label>
);

const styles = {
  mandatory: style({
    $nest: {
      "&::after": {
        content: `' *'`,
        color: "#e94057",
      },
    },
  }),
  label: style({
    fontSize: 12,
    margin: 10,
    display: "block",
  }),
};
