import { IParticipant } from "@hulanbv/ssllow-packages";
import React, { FC, HTMLAttributes } from "react";
import { style } from "typestyle";
import { getLanguage, languages } from "../../constants/i18n/dictionary";
import { getScoreLabels } from "../../constants/score-labels";
import { UserPicture } from "../elements/user-picture";

interface IProps {
  attributes?: HTMLAttributes<HTMLDivElement>;
  participant: IParticipant;
  language?: keyof typeof languages | string;
  color?: string;

  align: "left" | "right";
}

export const DiscussionReportUserHead: FC<IProps> = (props) => {
  return (
    <div
      {...props.attributes}
      className={[styles.userHead, props.attributes?.className].join(" ")}
    >
      <div
        className={styles.userRow}
        style={{
          flexDirection: props.align === "right" ? "row" : "row-reverse",
        }}
      >
        <div className={styles.userDetails}>
          <div style={{ textAlign: props.align, width: "100%" }}>
            <div>
              <strong style={{ textAlign: props.align }}>
                {props.participant.firstName} {props.participant.lastName}
              </strong>
            </div>
            <div className={styles.userRole}>
              {props.participant.isInvitee &&
                getLanguage(props.language).participant}
              {!props.participant.isInvitee &&
              props.language?.toLowerCase() !== "en" ? (
                getLanguage(props.language).partner
              ) : (
                <>&nbsp;</>
              )}
            </div>
          </div>
        </div>
        <div className={styles.userPhotoCol}>
          <UserPicture size={30} user={props.participant} color={props.color} />
        </div>
      </div>

      <div
        className={styles.scoreLabelRow}
        style={{
          flexDirection: props.align === "right" ? "row" : "row-reverse",
        }}
      >
        <div style={{ width: 16 }} />
        {[...getScoreLabels(props.language)].reverse().map((v, i) => (
          <div key={i} style={{ flex: 1 }}>
            {v.length > 7 ? v.substring(0, 4) + "." : v}
          </div>
        ))}
      </div>
    </div>
  );
};

const styles = {
  userHead: style({
    flex: 1,
    marginBottom: 15,
  }),
  userRow: style({
    display: "flex",
    flex: 1,
    width: "100%",
    marginBottom: 25,
  }),
  questionCol: style({
    display: "flex",
    flex: 1,
    minWidth: 180,
    maxWidth: 180,
  }),
  userDetails: style({
    flex: 1,
    fontSize: 14,
    display: "flex",
    alignItems: "center",
    padding: "0 20px",
  }),
  userPhotoCol: style({
    flex: 1,
    maxWidth: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  }),
  userRole: style({
    opacity: 0.87,
    marginTop: 5,
    fontSize: 14,
  }),

  scoreLabelRow: style({
    display: "flex",

    $nest: {
      "& > div": {
        textAlign: "center",
      },
    },
  }),
};
