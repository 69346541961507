import { IOrganisation } from "@hulanbv/ssllow-packages";
import React, { FC } from "react";
import { style } from "typestyle";

interface IProps {
  model: IOrganisation;
}

export const OrganisationOption: FC<IProps> = (props) => (
  <div className={styles.container}>
    <div
      className={styles.asset}
      style={{ backgroundImage: `url(${props.model.imagePath})` }}
    />
    {props.model.name}
  </div>
);

const styles = {
  container: style({
    display: "flex",
    alignItems: "center",
  }),
  asset: style({
    width: 20,
    height: 20,
    backgroundSize: "contain",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    marginRight: 10,
  }),
};
