import { IParticipant } from "@hulanbv/ssllow-packages";
import React, { FC } from "react";
import { style } from "typestyle";
import { UserPicture } from "./user-picture";

interface IProps {
  progress: number;
  participant: IParticipant;
  color?: string;
}

export const ParticipantProgress: FC<IProps> = (props) => (
  <div
    className={styles.container}
    style={{ opacity: 0.5 + (props.progress / 100) * 0.5 }}
  >
    <UserPicture user={props.participant} color={props.color} size={20} />
    {/* <div
      className={styles.image}
      style={{ backgroundImage: `url(${props.participant.imageThumbPath})` }}
    /> */}
    <div className={styles.progressContainer}>
      <div>
        <div className={styles.name}>
          {props.participant.firstName} {props.participant.lastName}
        </div>
        <div className={styles.progress}>{Math.floor(props.progress)}%</div>
      </div>
    </div>
  </div>
);

const styles = {
  container: style({
    display: "flex",
    flex: 1,
    alignItems: "center",

    $nest: {
      "& > div": {
        whiteSpace: "nowrap",
        margin: 5,
        fontSize: 12,
      },
    },
  }),

  name: style({
    margin: "3px 6px",
  }),

  progress: style({
    margin: "3px 6px",
  }),

  progressContainer: style({
    display: "block",
    flex: 1,
    position: "relative",

    $nest: {
      "&>div": {
        display: "flex",
        justifyContent: "space-between",
        borderRadius: 3,
      },
    },
  }),
};
