import {
  IInvite,
  IOrganisation,
  IReportTemplate,
  ISurvey,
  IUser,
} from "@hulanbv/ssllow-packages";
import React, { FC, useState } from "react";
import { inviteContextCodes } from "../../constants/context-code-trees";
import { dictionary } from "../../constants/i18n/dictionary";
import { IFormProps } from "../../interfaces/form-props.interface";
import { organisationService } from "../../services/organisation.service";
import { reportTemplateService } from "../../services/report-template.service";
import { surveyService } from "../../services/survey.service";
import { userService } from "../../services/user.service";
import { Button } from "../controls/button";
import { Checkbox } from "../controls/checkbox";
import { DateInput } from "../controls/date-input";
import { Input } from "../controls/input";
import { OrganisationOption } from "../controls/option-templates/organisation-option";
import { ReportTemplateOption } from "../controls/option-templates/report-template-option";
import { SurveyOption } from "../controls/option-templates/survey-option";
import { UserOption } from "../controls/option-templates/user-option";
import { RichText } from "../controls/rich-text";
import { Search } from "../controls/search";
import { Form } from "./form";
import { FormRow } from "./form-row";

export const InviteForm: FC<IFormProps<IInvite>> = (props) => {
  const [survey, setSurvey] = useState<string | null>(
    props.model?.surveyId ?? null
  );
  return (
    <Form {...props}>
      <input type="hidden" name="id" value={props.model?.id} />
      <input type="hidden" name="_id" value={props.model?.id} />
      <input type="hidden" name="accessCode" value={props.model?.accessCode} />
      <input
        type="hidden"
        name="createdAt"
        value={props.model?.createdAt?.toString()}
      />
      <FormRow>
        <Search<IOrganisation>
          keyField="id"
          label={dictionary.organisation}
          defaultValue={
            props.model?.organisation ? [props.model?.organisation] : []
          }
          find={async (search) =>
            (
              await organisationService.getAll({
                search,
                sort: ["name"],
                limit: 25,
              })
            ).data
          }
          optionTemplate={OrganisationOption}
          disabled={props.disabled || props.model?.id}
          name="organisationId"
          openOnFocus
          required
        />
      </FormRow>

      <FormRow>
        <Search<IUser>
          keyField="id"
          label={dictionary.individual_persons}
          defaultValue={props.model?.user ? [props.model?.user] : []}
          find={async (search) =>
            (
              await userService.getAll({
                search,
                sort: ["firstName"],
                limit: 25,
              })
            ).data
          }
          optionTemplate={UserOption}
          disabled={props.disabled || props.model?.id}
          name="userId"
          openOnFocus
        />
      </FormRow>

      <FormRow>
        <Search<ISurvey>
          keyField="id"
          label={dictionary.reflector}
          defaultValue={props.model?.survey ? [props.model?.survey] : []}
          find={async (search) =>
            (await surveyService.getAll({ search, sort: ["name"], limit: 25 }))
              .data
          }
          onChange={(surveyIds) => setSurvey(surveyIds[0])}
          optionTemplate={SurveyOption}
          disabled={props.disabled}
          name="surveyId"
          required
          openOnFocus
        />
      </FormRow>

      <FormRow>
        <Search<IReportTemplate>
          keyField="id"
          multiple
          label={dictionary.specific_app_templates}
          defaultValue={props.model?.reportTemplates ?? []}
          find={async (search) =>
            (
              await reportTemplateService.getBySurvey(survey ?? "", {
                search,
                sort: ["name"],
                limit: 25,
              })
            ).data
          }
          optionTemplate={ReportTemplateOption}
          disabled={props.disabled || !survey}
          name="reportTemplateIds"
          openOnFocus
        />
      </FormRow>

      <FormRow>
        <Input
          label={dictionary.number_of_participants}
          attributes={{
            type: "number",
            defaultValue: props.model?.maxParticipants,
            name: "maxParticipants",
            required: true,
          }}
        />
      </FormRow>

      <FormRow>
        <DateInput
          label={dictionary.deadline}
          attributes={{
            defaultValue: props.model?.deadline?.toString(),
            name: "deadline",
            required: true,
          }}
        />
      </FormRow>

      <FormRow>
        <RichText
          label={dictionary.app_intro_without_salutation}
          name="appIntro"
          defaultValue={props.model?.appIntro}
          selectionTree={inviteContextCodes}
          outputType="markdown"
        />
      </FormRow>

      <FormRow>
        <RichText
          label={dictionary.reflector_intro}
          name="surveyIntro"
          defaultValue={props.model?.surveyIntro}
          selectionTree={inviteContextCodes}
          outputType="markdown"
        />
      </FormRow>

      <FormRow>
        <RichText
          label={dictionary.reflector_participant_intro}
          name="surveyParticipantIntro"
          defaultValue={props.model?.surveyParticipantIntro}
          selectionTree={inviteContextCodes}
          outputType="markdown"
        />
      </FormRow>

      <FormRow>
        <RichText
          label={dictionary.reflector_ending}
          name="surveyEnding"
          defaultValue={props.model?.surveyEnding}
          selectionTree={inviteContextCodes}
          outputType="markdown"
        />
      </FormRow>

      <FormRow>
        <RichText
          label={dictionary.reflector_participant_ending}
          name="surveyParticipantEnding"
          defaultValue={props.model?.surveyParticipantEnding}
          selectionTree={inviteContextCodes}
          outputType="markdown"
        />
      </FormRow>

      <FormRow>
        <Checkbox
          label={dictionary.show_indicator_during_reflector}
          attributes={{
            disabled: props.disabled,
            checked: props.model?.showIndicator,
            name: "showIndicator",
          }}
        />
      </FormRow>

      <FormRow>
        <Checkbox
          label={dictionary.show_access_code_to_user}
          attributes={{
            disabled: props.disabled,
            checked: props.model?.showAccessCode,
            name: "showAccessCode",
          }}
        />
      </FormRow>

      <FormRow>
        <Checkbox
          label={dictionary.show_participants_during_reflector}
          attributes={{
            disabled: props.disabled,
            checked: props.model?.showCharacters,
            name: "showCharacters",
          }}
        />
      </FormRow>

      <FormRow>
        <Checkbox
          label={dictionary.show_participant_results_to_user}
          attributes={{
            disabled: props.disabled,
            checked: props.model?.showReactions,
            name: "showReactions",
          }}
        />
      </FormRow>

      <FormRow>
        <Checkbox
          label={dictionary.show_action_and_advice}
          attributes={{
            disabled: props.disabled,
            checked: props.model?.showResults,
            name: "showResults",
          }}
        />
      </FormRow>

      <FormRow>
        <Checkbox
          label={dictionary.enable_reports}
          attributes={{
            disabled: props.disabled,
            checked: props.model?.enableReports,
            name: "enableReports",
          }}
        />
      </FormRow>

      <FormRow>
        <Checkbox
          label={dictionary.trial_reflector}
          attributes={{
            disabled: props.disabled,
            checked: props.model?.isTrial,
            name: "isTrial",
          }}
        />
      </FormRow>

      <FormRow>
        <FormRow>
          <Button
            attributes={{ type: "submit", disabled: props.disabled }}
            appearance="primary"
          >
            {dictionary.submit}
          </Button>
        </FormRow>
      </FormRow>
    </Form>
  );
};
