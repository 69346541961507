import React, { FC, HTMLProps } from "react";
import Router from "react-history-router";
import { style } from "typestyle";

export const A: FC<HTMLProps<HTMLAnchorElement>> = (props) => (
  <a
    {...props}
    onClick={(e) => {
      // use the router if the url is internal
      if (props.href && !props.href?.startsWith("http")) {
        e.preventDefault();
        Router.push(props.href);
      }
    }}
    className={[styles.a, props.className].join(" ")}
  >
    {props.children}
  </a>
);

const styles = {
  a: style({
    textDecoration: "none",
  }),
};
