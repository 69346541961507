import { ITeam } from "@hulanbv/ssllow-packages";
import { CrudService, IHttpOptions, IResponse } from "nest-utilities-client";
import { environment } from "../constants/environment.constant";
import { httpService } from "./http.service";

class Service extends CrudService<ITeam> {
  constructor() {
    super([environment.REACT_APP_API_URL, "team"].join("/"), httpService);
  }

  getByOrganisationSurvey(
    organisationId: string,
    surveyId: string,
    options?: IHttpOptions
  ): Promise<IResponse<ITeam[]>> {
    return httpService.get(
      [
        this.controller,
        "organisation",
        organisationId,
        "survey",
        surveyId,
      ].join("/"),
      options
    );
  }
}

export const teamService = new Service();
