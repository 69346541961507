import React, { FC, HTMLAttributes, useEffect, useState } from "react";
import { style } from "typestyle";
import { dictionary } from "../../constants/i18n/dictionary";
import { Select } from "../controls/select";
import { FormRow } from "../forms/form-row";

interface IProps {
  attributes?: HTMLAttributes<HTMLDivElement>;
  disabled?: boolean;
  sortBy: string[];
}

export const TemplateSectionSortSelect: FC<IProps> = (props) => {
  const [sortBy, setSortBy] = useState<string[]>(props.sortBy);

  useEffect(() => {
    setSortBy(props.sortBy);
  }, [props.sortBy]);

  const changeSort = (
    index: number,
    sortKeys: string,
    sortAscending: boolean
  ) => {
    const _sortBy = [...sortBy];
    if (sortKeys) {
      _sortBy[index] = (sortAscending ? "" : "-") + sortKeys;
    } else {
      _sortBy.splice(index);
    }
    setSortBy(_sortBy);
  };

  return (
    <div
      {...props.attributes}
      className={[styles.templateSelection, props.attributes?.className].join(
        " "
      )}
    >
      {sortBy
        .filter(Boolean)
        .concat("")
        .map((sort, i) => {
          const isAscending = !sort.startsWith("-");
          const keys = isAscending ? sort : sort.substring(1);
          return (
            <FormRow key={keys + i + isAscending.toString()}>
              <input type="hidden" name={`sortBy[${i}]`} value={sort} />
              <Select
                options={sortByOptions}
                label={i === 0 ? dictionary.sort_by : undefined}
                attributes={{
                  disabled: props.disabled,
                  defaultValue: keys,
                  onChange: ({ currentTarget }) =>
                    changeSort(i, currentTarget.value, isAscending),
                }}
              />
              <Select
                noEmptyOption
                options={{
                  "1": dictionary.ascending,
                  "0": dictionary.descending,
                }}
                label={i === 0 ? dictionary.direction : undefined}
                attributes={{
                  disabled: props.disabled,
                  defaultValue: isAscending ? "1" : "0",
                  onChange: ({ currentTarget }) =>
                    changeSort(i, keys, currentTarget.value === "1"),
                }}
              />
            </FormRow>
          );
        })}
    </div>
  );
};

const styles = {
  templateSelection: style({}),
};

// todo: extend (scores etc.)
const sortByOptions = {
  "question.name": dictionary.indicator,
  "question.genre.name": dictionary.genre,
  "question.category.name": dictionary.category,
  "userScore,averageScore": dictionary.user_score,
  "averageScore,userScore": dictionary.partner__team_score,
  "question.type": dictionary.question_type_constructive_destructive,
};
