import React, { FC, InputHTMLAttributes } from "react";
import { style } from "typestyle";
import { Label } from "../typography/label";

interface IProps {
  attributes?: InputHTMLAttributes<HTMLTextAreaElement>;
  label?: string;
}

export const Textarea: FC<IProps> = (props) => (
  <div>
    {props.label && (
      <Label required={props.attributes?.required}>{props.label}</Label>
    )}
    <textarea
      {...{
        placeholder: props.label,
        ...props.attributes,
      }}
      className={[styles.input, props.attributes?.className].join(" ")}
    ></textarea>
  </div>
);

const styles = {
  input: style({
    outline: 0,
    borderRadius: 6,
    border: "1px solid #dedede",
    fontSize: 12,
    padding: "15px 20px",
    fontFamily: "Montserrat, sans-serif",
    width: "100%",
    resize: "vertical",
    minHeight: 108,
    $nest: {
      "&::-webkit-input-placeholder": {
        opacity: 0.54,
        color: "#707070",
      },
      "&:disabled": {
        color: "rgba(0,0,0,.4)",
      },
    },
  }),
};
