import React, { FC, useEffect, useState } from "react";
import { style } from "typestyle";
import { IRouteOptions } from "../../interfaces/route-options.interface";
import { routeListener } from "../../observables/route-listener";

export const Location: FC = (props) => {
  const [route, setRoute] = useState<IRouteOptions | null>(null);

  useEffect(() => {
    const listener = routeListener.subscribe(setRoute);
    return () => {
      listener.unsubscribe();
    };
  }, []);

  return <div className={styles.location}>{route?.data?.title}</div>;
};

const styles = {
  location: style({
    fontWeight: 500,
    fontSize: 16,
    textTransform: "uppercase",
  }),
};
