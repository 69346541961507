import { IOrganisation, IUser, Role } from "@hulanbv/ssllow-packages";
import React, { FC } from "react";
import { style } from "typestyle";
import { inviteContextCodes } from "../../constants/context-code-trees";
import { dictionary } from "../../constants/i18n/dictionary";
import { mailContent } from "../../constants/templates/invite.templates";
import {
  appIntro,
  surveyEnding,
  surveyIntro,
  surveyParticipantEnding,
  surveyParticipantIntro,
} from "../../constants/templates/survey.templates";
import { themeLabels } from "../../constants/theme-labels";
import { IFormProps } from "../../interfaces/form-props.interface";
import { userService } from "../../services/user.service";
import { satisfiesRoles } from "../../utilities/satisfies-roles";
import { Button } from "../controls/button";
import { Input } from "../controls/input";
import { UserOption } from "../controls/option-templates/user-option";
import { RichText } from "../controls/rich-text";
import { Search } from "../controls/search";
import { Select } from "../controls/select";
import { Form } from "./form";
import { FormRow } from "./form-row";

export const OrganisationForm: FC<IFormProps<IOrganisation>> = (props) => {
  const canEditTemplates = satisfiesRoles(Role.ADMIN);
  return (
    <Form {...props}>
      <input type="hidden" name="id" value={props.model?.id} />
      <input type="hidden" name="_id" value={props.model?.id} />

      {props.model?.imagePath && (
        <FormRow>
          <div>
            <div
              className={styles.image}
              style={{
                backgroundImage: `url(${props.model.imagePath})`,
              }}
            />
          </div>
        </FormRow>
      )}

      <FormRow>
        <Input
          label={dictionary.brand_logo}
          attributes={{
            disabled: props.disabled,
            name: "file",
            type: "file",
            accept: "image/*",
          }}
        />
      </FormRow>
      <FormRow>
        <Input
          label={dictionary.name}
          attributes={{
            disabled: props.disabled,
            defaultValue: props.model?.name,
            name: "name",
            required: true,
          }}
        />
      </FormRow>
      <FormRow>
        <Input
          label={dictionary.address}
          attributes={{
            disabled: props.disabled,
            defaultValue: props.model?.address,
            name: "address",
            required: true,
          }}
        />
      </FormRow>
      <FormRow>
        <Input
          label={dictionary.city}
          attributes={{
            disabled: props.disabled,
            defaultValue: props.model?.city,
            name: "city",
            required: true,
          }}
        />
        <Input
          label={dictionary.postal_code}
          attributes={{
            disabled: props.disabled,
            defaultValue: props.model?.postalCode,
            name: "postalCode",
            required: true,
          }}
        />
      </FormRow>
      <FormRow>
        <Search<IUser>
          keyField="id"
          label={dictionary.contact}
          defaultValue={props.model?.contact ? [props.model?.contact] : []}
          find={async (search) =>
            (
              await userService.getAll({
                search,
                sort: ["firstName"],
                limit: 25,
              })
            ).data
          }
          optionTemplate={UserOption}
          disabled={props.disabled}
          name="contactId"
          required
        />
      </FormRow>
      <FormRow>
        <Select
          options={themeLabels}
          label={dictionary.theme}
          attributes={{
            disabled: props.disabled,
            defaultValue: props.model?.theme,
            name: "theme",
            required: true,
          }}
        />
      </FormRow>

      <FormRow>
        <RichText
          label={dictionary.app_intro_without_salutation}
          name="appIntro"
          defaultValue={props.model?.appIntro ?? appIntro()}
          selectionTree={inviteContextCodes}
          disabled={!canEditTemplates}
        />
      </FormRow>

      <FormRow>
        <RichText
          label={dictionary.reflector_intro}
          name="surveyIntro"
          defaultValue={props.model?.surveyIntro ?? surveyIntro()}
          selectionTree={inviteContextCodes}
          disabled={!canEditTemplates}
        />
      </FormRow>

      <FormRow>
        <RichText
          label={dictionary.reflector_participant_intro}
          name="surveyParticipantIntro"
          defaultValue={
            props.model?.surveyParticipantIntro ?? surveyParticipantIntro()
          }
          selectionTree={inviteContextCodes}
          disabled={!canEditTemplates}
        />
      </FormRow>

      <FormRow>
        <RichText
          label={dictionary.reflector_ending}
          name="surveyEnding"
          defaultValue={props.model?.surveyEnding ?? surveyEnding()}
          selectionTree={inviteContextCodes}
          disabled={!canEditTemplates}
        />
      </FormRow>

      <FormRow>
        <RichText
          label={dictionary.reflector_participant_ending}
          name="surveyParticipantEnding"
          defaultValue={
            props.model?.surveyParticipantEnding ?? surveyParticipantEnding()
          }
          selectionTree={inviteContextCodes}
          disabled={!canEditTemplates}
        />
      </FormRow>

      <FormRow>
        <RichText
          label={dictionary.invitation_email_without_salutation_and_closing}
          outputType="html"
          name="mailContent"
          defaultValue={props.model?.mailContent ?? mailContent()}
          selectionTree={inviteContextCodes}
          disabled={!canEditTemplates}
        />
      </FormRow>

      <FormRow>
        <FormRow>
          <Button
            attributes={{ type: "submit", disabled: props.disabled }}
            appearance="primary"
          >
            {dictionary.submit}
          </Button>
        </FormRow>
      </FormRow>
    </Form>
  );
};

const styles = {
  image: style({
    width: 300,
    height: 300,
    backgroundSize: "contain",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    margin: "0 auto",
  }),
};
