import { environment } from "../environment.constant";
import { en } from "./en";
import { nl } from "./nl";

export const languages = { nl, en };

export const getLanguage = (language?: keyof typeof languages | string) => {
  if (["en", "nl"].includes(language?.toLowerCase() ?? "")) {
    return languages[language?.toLowerCase() as "en" | "nl"];
  }
  return languages[environment.REACT_APP_LANGUAGE];
};

export const dictionary = languages[environment.REACT_APP_LANGUAGE] ?? en;
