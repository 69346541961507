import {
  IMailConfig,
  IQuestionReport,
  IQuestionReportParticipant,
  IReport,
  IReportTemplateSection,
  QuestionReportPriority,
} from "@hulanbv/ssllow-packages";
import _get from "lodash/get";
import _orderBy from "lodash/orderBy";
import _round from "lodash/round";
import { CrudService, IResponse } from "nest-utilities-client";
import { environment } from "../constants/environment.constant";
import { dictionary } from "../constants/i18n/dictionary";
import { IParticipantAverage } from "../interfaces/participant-average.interface";
import { Dictionary } from "../types/dictionary.type";
import { authenticationService } from "./authentication.service";
import { httpService } from "./http.service";

class Service extends CrudService<IReport> {
  constructor() {
    super([environment.REACT_APP_API_URL, "report"].join("/"), httpService);
  }

  getResults(reportId: string): Promise<IResponse<IQuestionReport[]>> {
    return httpService.get([this.controller, reportId, "results"].join("/"));
  }

  mailTemplatePdfByInvite(
    inviteId: string,
    templateId: string
  ): Promise<IResponse<void>> {
    return httpService.post(
      [
        this.controller,
        "invite",
        inviteId,
        "report-template",
        templateId,
        "mail",
      ].join("/"),
      null
    );
  }

  mailReport(reportId: string, mailConfig: FormData | IMailConfig) {
    return httpService.post(
      [this.controller, reportId, "mail"].join("/"),
      mailConfig
    );
  }

  async downloadPdfBundle(
    templateId: string,
    expertId: string,
    inviteIds: string[]
  ) {
    const response = await fetch(
      [
        this.controller,
        "pdf",
        "template",
        templateId,
        "expert",
        expertId,
        "invites",
        inviteIds.join(","),
      ].join("/"),
      {
        method: "GET",
        headers: new Headers({
          Authorization: authenticationService.getSession()?.token || "",
        }),
      }
    );

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download =
      response.headers.get("x-filename") || `${dictionary.reports}.zip`;
    document.body.appendChild(a);
    a.click();
    a.remove();
  }

  async downloadPdf(reportId: string) {
    const response = await fetch([this.controller, reportId, "pdf"].join("/"), {
      method: "GET",
      headers: new Headers({
        Authorization: authenticationService.getSession()?.token || "",
      }),
    });

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download =
      response.headers.get("x-filename") || `${dictionary.report}.pdf`;
    document.body.appendChild(a);
    a.click();
    a.remove();
  }

  /**
   * Filters and sorts a set of question reports based on the section
   * @param results
   * @param section
   */
  filterResultsBySection(
    results: IQuestionReport[],
    section: IReportTemplateSection,
    report: IReport,
    previousSection?: IReportTemplateSection
  ): IQuestionReport[] {
    const questionIdPair = report.questionIds.reduce<Record<string, boolean>>(
      (prev, curr) => {
        prev[curr] = true;
        return prev;
      },
      {}
    );
    let selection = [...results];

    // filter out questions which aren't selected
    selection = selection.filter(
      ({ question }) => !!questionIdPair[question.id]
    );

    // filter out questions from the previous section
    if (section.ignorePreviousSectionQuestions && previousSection) {
      const previousQuestionIds = this.filterResultsBySection(
        results,
        previousSection,
        report
      ).map(({ question }) => question.id);

      selection = selection.filter(
        ({ question }) => previousQuestionIds.indexOf(question.id) === -1
      );
    }

    // Filter based on user/participant deviation if defined
    if (section.minReviewDeviationFilter) {
      selection = selection.filter((result) => {
        if (result.userScore === undefined) {
          return false;
        }

        const deviations = result.reviews.map(({ score }) =>
          Math.abs(score - (result.userScore ?? 0))
        );
        return (
          Math.max(...deviations) >= (section.minReviewDeviationFilter ?? 0)
        );
      });
    }

    // filter the results based on the question filters
    selection = selection.filter((result) =>
      Object.keys(section.questionFilters || {}).every((key) => {
        const value = section.questionFilters?.[key];
        const resultValue = _get(result, key, "");
        if (!value?.toString() || !resultValue?.toString()) {
          return true;
        }

        // always work with the filter value as an array
        const array = !Array.isArray(value) ? [value] : value;
        if (Array.isArray(resultValue)) {
          return resultValue.some(
            (item) => array.includes(+item) || array.includes(item + "")
          );
        }
        return array.includes(resultValue + "") || array.includes(+resultValue);
      })
    );

    // fill the resultTypes with the partner result types if set
    if (section.showPartnerResultType) {
      selection = selection.map((result) => ({
        ...result,
        resultType: result.partnerResultType,
      }));
    }

    // sort by the defined column and return the list
    const keys = section.sortBy?.join(",").split(",") || [];
    return _orderBy(
      selection,
      keys.map((key) => key.replace(/^-/, "")),
      keys.map((key) => (key.startsWith("-") ? "desc" : "asc"))
    )
      .sort((a, b) => {
        if (a.priority === b.priority) {
          return 0;
        }
        return a.priority === QuestionReportPriority.HIGH ? -1 : 1;
      })
      .slice(0, section.limit || selection.length);
  }

  /**
   * Returns discussion results
   * @param participants
   * @param results
   */
  getDiscussionResults(
    participants: IQuestionReportParticipant[],
    results: IQuestionReport[]
  ): IQuestionReport[][] {
    const discussionResults: IQuestionReport[][] = [];

    for (const participant of participants) {
      const usableResults = [];
      for (const result of results) {
        // create a result with only the invitees' and the participants reviews
        const strippedResult = {
          ...result,
          reviews: result.reviews.filter(
            (review) =>
              review.participant.isInvitee ||
              review.participant.participationId === participant.participationId
          ),
        };
        if (strippedResult.reviews.length === 2) {
          usableResults.push(strippedResult);
        }
      }

      usableResults
        .sort((a, b) => {
          const aDev = Math.abs(a.reviews[0].score - a.reviews[1].score);
          const bDev = Math.abs(b.reviews[0].score - b.reviews[1].score);

          if (aDev === bDev) {
            return 0;
          }
          return aDev > bDev ? -1 : 1;
        })
        .sort((a, b) => {
          if (a.priority === b.priority) {
            return 0;
          }
          return a.priority === QuestionReportPriority.HIGH ? -1 : 1;
        });

      if (usableResults.length) {
        discussionResults.push(usableResults);
      }
    }

    return discussionResults;
  }

  /**
   * get result averages per participant
   * @param results
   */
  getParticipantAverages(results: IQuestionReport[]): IParticipantAverage[] {
    const participantAverages: Dictionary<IParticipantAverage> = {};
    const participantResultCount: Dictionary<number> = {};
    for (const result of results) {
      for (const review of result.reviews) {
        // set defaults for participant
        participantResultCount[review.participant.participationId] =
          (participantResultCount[review.participant.participationId] || 0) + 1;
        participantAverages[review.participant.participationId] =
          participantAverages[review.participant.participationId] || {
            participant: review.participant,
            average: 0,
          };

        participantAverages[review.participant.participationId].average +=
          review.score;
      }
    }

    // calculate averages
    const averages = Object.values(participantAverages);
    for (const average of averages) {
      average.average = _round(
        average.average /
          participantResultCount[average.participant.participationId],
        1
      );
    }

    return averages;
  }
}

export const reportService = new Service();
