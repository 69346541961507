import { ResultType } from "@hulanbv/ssllow-packages";
import { dictionary } from "./i18n/dictionary";

export const resultTypePluralLabels: Record<ResultType, string> = {
  [ResultType.CHERISH]: dictionary.cherish_points,
  [ResultType.DEVELOPMENT]: dictionary.development_points,
  [ResultType.OBSTACLE]: dictionary.obstacle_points,
  [ResultType.STARTING]: dictionary.starting_points,
  [ResultType.UNDEFINED]: dictionary.unanswered,
};
