/* eslint-disable react-hooks/exhaustive-deps */
import { IModel } from "@hulanbv/ssllow-packages";
import { CrudService, IHttpOptions } from "nest-utilities-client";
import React, { FC, useEffect, useState } from "react";
import Router from "react-history-router";
import { dictionary } from "../../constants/i18n/dictionary";
import { IFormProps } from "../../interfaces/form-props.interface";
import { notificationListener } from "../../observables/notification-listener";
import { Button } from "../controls/button";
import { HR } from "../statics/hr";

interface IProps<Model extends IModel> {
  service: CrudService<Model>;
  onDone?: (model?: Model) => any;
  redirectPath?: string;
  redirectParams?: { [key: string]: string };
  form: FC<IFormProps>;
  messageSubject?: string;
  fetchOptions?: IHttpOptions;
  usePut?: boolean;
  readOnly?: boolean;
  canDelete?: boolean;
  useModelObject?: boolean;

  modelId?: string;
  model?: Partial<Model>;
}

export function CrudForm<Model extends IModel>(props: IProps<Model>) {
  const [model, setModel] = useState<Model>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  /**
   * Either create or patch the model object
   * @param modelFormData
   */
  const saveModel = async (modelFormData: FormData, modelObject: Model) => {
    try {
      const { data } = await props.service[
        modelObject?.id || (modelObject as any)?._id
          ? props.usePut
            ? "put"
            : "patch"
          : "post"
      ](props.useModelObject ? modelObject : modelFormData);
      notificationListener.next({
        message: model
          ? dictionary.successfully_saved(props.messageSubject)
          : dictionary.successfully_created(props.messageSubject),
      });

      if (props.redirectPath) {
        Router.push(props.redirectPath, props.redirectParams);
      }
      props.onDone?.(data);
    } catch {
      notificationListener.next({ message: dictionary.something_went_wrong });
    }
  };

  /**
   * Delete the selected license
   */
  const deleteModel = async () => {
    if (model && window.confirm(dictionary.confirm_deletion)) {
      await props.service.delete(model.id);

      notificationListener.next({
        message: dictionary.successfully_deleted(props.messageSubject),
      });

      if (props.redirectPath) {
        Router.replace(props.redirectPath, props.redirectParams);
      }
      props.onDone?.(model);
    }
  };

  useEffect(() => {
    const fetchModel = async (id?: string) => {
      try {
        if (id) {
          const { data } = await props.service.get(
            id,
            props.fetchOptions ?? { populate: [] }
          );
          setModel(data);
        }
        setIsLoading(false);
      } catch {
        if (props.redirectPath) {
          Router.replace(props.redirectPath, props.redirectParams);
        }
        props.onDone?.(model);
      }
    };

    fetchModel(props.modelId);
  }, [
    props.modelId,
    props.redirectPath,
    props.service,
    props.redirectParams,
    props.onDone,
  ]);

  return (
    <>
      <props.form
        key={model?.id}
        model={model || props.model}
        attributes={{ style: { padding: 10 } }}
        onSubmit={saveModel}
        disabled={isLoading || props.readOnly}
      />

      {props.canDelete !== false && model && !props.readOnly && (
        <>
          <HR margin="0 0 30px 0"></HR>
          <Button attributes={{ onClick: deleteModel }} appearance="default">
            {dictionary.delete}
          </Button>
        </>
      )}
    </>
  );
}
