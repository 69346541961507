import { ITeam } from "@hulanbv/ssllow-packages";
import React, { FC } from "react";
import { style } from "typestyle";

interface IProps {
  model: ITeam;
}

export const TeamOption: FC<IProps> = (props) => (
  <div>
    {props.model.name}{" "}
    <span className={styles.secondary}>
      {props.model.members
        ?.map(
          (member) => `${member.firstName[0].toUpperCase()}. ${member.lastName}`
        )
        .join(", ")}
    </span>
  </div>
);

const styles = {
  secondary: style({
    opacity: 0.54,
  }),
};
