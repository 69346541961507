import { IQuestionReport } from "@hulanbv/ssllow-packages";
import React, { FC, useEffect, useState } from "react";
import { style } from "typestyle";
import { colors } from "../../constants/colors";
import { getLanguage } from "../../constants/i18n/dictionary";
import { IReportProps } from "../../interfaces/report-props.interface";
import { reportService } from "../../services/report.service";
import { HideOnPrint } from "../elements/hide-on-print";
import { H1 } from "../typography/h1";
import { DiscussionReportUserHead } from "./discussion-report-user-head";
import { DiscussionReportUserScore } from "./discussion-report-user-score";

export const DiscussionReport: FC<IReportProps> = (props) => {
  const [discussionResults, setDiscussionResults] = useState<
    IQuestionReport[][]
  >([]);

  useEffect(() => {
    setDiscussionResults(
      reportService
        .getDiscussionResults(
          props.report.participations?.map(({ id, participant }) => ({
            ...participant,
            participationId: id,
          })) ?? [],
          props.unfilteredResults
        )
        .map((results) =>
          reportService.filterResultsBySection(
            results,
            props.section,
            props.report
          )
        )
        .filter((result) => !!result.length)
    );
  }, [
    props.report.participations,
    props.results,
    props.section,
    props.unfilteredResults,
    props.report,
  ]);

  if (!props.report.userId) {
    return (
      <HideOnPrint>
        {
          getLanguage(props.report.survey?.language)
            .discussion_reports_arent_compatible_with_team_reports
        }
      </HideOnPrint>
    );
  }
  return (
    <div
      {...props.attributes}
      className={[styles.report, props.attributes?.className].join(" ")}
    >
      {discussionResults.map((results, i) => (
        <div className={styles.userReport} key={i}>
          <div className={styles.row}>
            <div className={styles.userCol}>
              <DiscussionReportUserHead
                language={props.report.survey?.language}
                participant={results[0]?.reviews[0]?.participant}
                color={colors.invitee}
                align={"right"}
              />
            </div>
            <div className={styles.questionCol}>
              <H1 attributes={{ className: styles.questionsHead }}>
                {getLanguage(props.report.survey?.language).questions}
              </H1>
            </div>
            <div className={styles.userCol}>
              <DiscussionReportUserHead
                language={props.report.survey?.language}
                participant={results[0]?.reviews[1]?.participant}
                align={"left"}
                color={
                  props.reviewColors[results[0]?.reviews[1]?.participant.email]
                }
              />
            </div>
          </div>

          {results.map((result, i) => (
            <div className={styles.row} key={i}>
              <div className={styles.userCol}>
                <DiscussionReportUserScore
                  direction="left"
                  result={result}
                  review={result.reviews[0]}
                />
              </div>
              <div
                className={styles.questionCol}
                style={{
                  alignItems: "center",
                }}
              >
                <div className={styles.questionContainer}>
                  <strong>{result.question.name}</strong>
                  <div className={styles.question}>
                    {result.question.question}
                  </div>
                </div>
              </div>
              <div className={styles.userCol}>
                <DiscussionReportUserScore
                  direction="right"
                  result={result}
                  review={result.reviews[1]}
                />
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

const styles = {
  report: style({}),
  userReport: style({
    marginBottom: "25px",
    $nest: {
      "&:not(:last-child)": {
        pageBreakAfter: "always",
      },
    },
  }),
  row: style({
    display: "flex",
  }),
  userCol: style({
    display: "flex",
    flex: 1,
  }),
  questionCol: style({
    display: "flex",
    justifyContent: "center",
    flex: 1,
    minWidth: 180,
    maxWidth: 180,
    fontSize: 12,
    textAlign: "center",
  }),
  question: style({
    padding: "3px 0 5px 0",
    textAlign: "center",
    fontSize: 11,
  }),
  questionContainer: style({
    width: "100%",
  }),
  questionsHead: style({
    alignItems: "start",
    fontSize: 18,
  }),
};
