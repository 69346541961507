import React, { FC, HTMLAttributes } from "react";

interface IProps {
  attributes?: HTMLAttributes<SVGElement>;

  /**
   * Single character grade which is shown inside the
   * magnifying glass.
   */
  grade?: string;
}

export const MagnifyingGlass: FC<IProps> = (props) => (
  <svg
    {...props.attributes}
    viewBox="-0.012 0 26.691 26.696"
    width="26.691"
    height="26.696"
  >
    <g id="Laag_2">
      <g id="Layer_1">
        <path
          fill="#cec7d2"
          d="M17.85,17.36A7.65,7.65,0,0,0,15.92,2.19a7.65,7.65,0,0,1,0,15.17A7.79,7.79,0,0,0,17.85,17.36Z"
        ></path>
        <path
          fill="#4bb4b2"
          d="M.67,26A2.34,2.34,0,0,0,4,26l6.46-6.46a.4.4,0,0,0,0-.56l-.5-.5,1.23-1.23-.55-.55L9.39,17.94l-.66-.65L10,16.05l-.55-.54L8.18,16.74l-.5-.51a.39.39,0,0,0-.55,0L.67,22.69A2.35,2.35,0,0,0,.67,26Zm9-6.7L8.28,20.61,6.06,18.4l1.35-1.34Zm-8.4,6.18a1.58,1.58,0,0,1,0-2.22l4.3-4.3,2.22,2.23-4.31,4.3a1.58,1.58,0,0,1-2.21,0Z"
        ></path>
        <path
          fill="#644570"
          d="M23.81,16.7a9.78,9.78,0,1,0-6.92,2.86A9.77,9.77,0,0,0,23.81,16.7Zm-.55-.55a9,9,0,1,1,0-12.73A9,9,0,0,1,23.26,16.15Z"
        ></path>
        <path
          fill="#644570"
          d="M21.77,12.65a.41.41,0,0,0,.18.53.39.39,0,0,0,.52-.16A6.62,6.62,0,0,0,23,8a.39.39,0,0,0-.48-.27.4.4,0,0,0-.27.49,5.87,5.87,0,0,1-.42,4.37Z"
        ></path>
        <path
          fill="#644570"
          d="M22.14,7a.39.39,0,0,0,.13-.53c-.1-.17-.21-.33-.33-.5h0a.41.41,0,0,0-.41,0,.39.39,0,0,0-.2.52c.1.14.2.29.29.44h0a.4.4,0,0,0,.53.13Z"
        ></path>
        <path
          fill="#644570"
          d="M11.18,4.1a8,8,0,1,0,11.35,0,7.91,7.91,0,0,0-4.78-2.3h0a.4.4,0,1,0,0,.79A7.14,7.14,0,0,1,22,4.67h0a7.26,7.26,0,1,1-10.27,0,7.44,7.44,0,0,1,3.36-1.91.38.38,0,0,0,.29-.39h0a.38.38,0,0,0,0-.1A.39.39,0,0,0,14.92,2,7.94,7.94,0,0,0,11.2,4.1Z"
        ></path>
      </g>
    </g>
    <text
      style={{
        fill: "rgb(100, 69, 112)",
        fontFamily: "RNS Sanz",
        fontSize: 10,
        fontWeight: 700,
        whiteSpace: "pre",
      }}
      x="12.625"
      y="13.633"
    >
      {props.grade}
    </text>
  </svg>
);
