import { ICategory } from "@hulanbv/ssllow-packages";
import React, { FC } from "react";
import { Router } from "react-history-router";
import { Button } from "../components/controls/button";
import { routes } from "../components/routing/routes";
import { CrudTable } from "../components/tables/crud-table";
import { CategoryRow } from "../components/tables/custom-rows/category-row";
import { dictionary } from "../constants/i18n/dictionary";
import { IViewProps } from "../interfaces/view-props.interface";
import { categoryService } from "../services/category.service";

export const CategoriesView: FC<IViewProps> = (props) => (
  <CrudTable<ICategory>
    service={categoryService}
    sortKey="name"
    options={{ populate: ["subcategories"] }}
    headings={{
      name: dictionary.name,
      subcategoryIds: dictionary.subcategories,
    }}
    customRow={(category) => (
      <CategoryRow
        key={category.id}
        category={category}
        attributes={{
          onClick: () =>
            Router.push(routes.editCategory.path, { id: category.id }),
        }}
      />
    )}
    customControls={
      <Button
        attributes={{
          onClick: () => Router.push(routes.createCategory.path),
        }}
        appearance={"primary"}
      >
        {dictionary.new}
      </Button>
    }
  />
);
