import { ICategory } from "@hulanbv/ssllow-packages";
import React, { FC } from "react";
import { dictionary } from "../../constants/i18n/dictionary";
import { IFormProps } from "../../interfaces/form-props.interface";
import { categoryService } from "../../services/category.service";
import { Button } from "../controls/button";
import { Input } from "../controls/input";
import { CategoryOption } from "../controls/option-templates/category-option";
import { Search } from "../controls/search";
import { Form } from "./form";
import { FormRow } from "./form-row";

export const CategoryForm: FC<IFormProps<ICategory>> = (props) => (
  <Form {...props}>
    <input type="hidden" name="id" value={props.model?.id} />
    <input type="hidden" name="_id" value={props.model?.id} />

    <FormRow>
      <Input
        label={dictionary.name}
        attributes={{
          disabled: props.disabled,
          defaultValue: props.model?.name,
          name: "name",
          required: true,
        }}
      />
    </FormRow>
    <FormRow>
      <Search<ICategory>
        keyField="id"
        label={dictionary.subcategories}
        defaultValue={props.model?.subcategories || []}
        find={async (search) =>
          (await categoryService.getAll({ search, sort: ["name"], limit: 25 }))
            .data
        }
        optionTemplate={CategoryOption}
        disabled={props.disabled}
        name="subcategoryIds"
        multiple
      />
    </FormRow>

    <FormRow>
      <FormRow>
        <Button
          attributes={{ type: "submit", disabled: props.disabled }}
          appearance="primary"
        >
          {dictionary.submit}
        </Button>
      </FormRow>
    </FormRow>
  </Form>
);
