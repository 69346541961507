import dayjs from "dayjs";
import React, { FC, InputHTMLAttributes, useEffect, useState } from "react";
import { dictionary } from "../../constants/i18n/dictionary";
import { formatDate } from "../../utilities/date.utils";
import { Input } from "./input";

dayjs.extend(require("dayjs-ext/plugin/customParseFormat"));

interface IProps {
  attributes?: InputHTMLAttributes<HTMLInputElement>;
  label?: string;
}

export const DateInput: FC<IProps> = (props) => {
  const [date, setDate] = useState<string>("");
  const [formattedDate, setformattedDate] = useState<string>("");

  /**
   * Formats a string to a date string and sets it to the date state
   * @param value
   */
  const stringToDate = (value: string) => {
    const prependedValue = value
      .split("-")
      .map((part) => (part.length < 2 ? "0" + part : part))
      .join("-");
    const date = dayjs(prependedValue, dictionary.date_format, "en");
    setDate(date.isValid() ? date.toISOString() : "");
  };

  useEffect(() => {
    // format the date if applicable
    const value = props.attributes?.value || props.attributes?.defaultValue;
    if (value) {
      setDate(new Date(value.toString()).toISOString());
      setformattedDate(formatDate(value.toString(), dictionary.date_format));
    }
  }, [props.attributes]);

  return (
    <>
      <input type="hidden" value={date} name={props.attributes?.name} />
      <Input
        {...props}
        key={formattedDate}
        attributes={{
          ...props.attributes,
          placeholder: dictionary.date_format_string,
          name: undefined,
          defaultValue: formattedDate,
          value: undefined,
          onChange: (e) => stringToDate(e.currentTarget.value),
          onBlur: (e) => {
            if (!date) {
              setformattedDate("");
              e.currentTarget.value = "";
            }
          },
        }}
      />
    </>
  );
};
