import React, { FC, HTMLAttributes } from "react";
import { style } from "typestyle";

interface IProps {
  attributes?: HTMLAttributes<HTMLDivElement>;
}

export const TR: FC<IProps> = (props) => (
  <div
    {...props.attributes}
    className={[styles.tr, props.attributes?.className].join(" ")}
  >
    {props.children}
  </div>
);

const styles = {
  tr: style({
    display: "table-row",
  }),
};
