import { ITeachingAid } from "@hulanbv/ssllow-packages";
import { CrudService } from "nest-utilities-client";
import { environment } from "../constants/environment.constant";
import { httpService } from "./http.service";

class Service extends CrudService<ITeachingAid> {
  constructor() {
    super(
      [environment.REACT_APP_API_URL, "teaching-aid"].join("/"),
      httpService
    );
  }

  async getLabels(): Promise<string[]> {
    const { data } = await teachingAidService.getAll({
      pick: ["label"],
      distinct: "label",
    });

    return Array.from(
      new Set(data.map(({ label }) => label?.toLowerCase()).filter(Boolean))
    );
  }
}

export const teachingAidService = new Service();
