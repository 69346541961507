import { IReportTemplate } from "@hulanbv/ssllow-packages";
import React, { FC } from "react";
import { Router } from "react-history-router";
import { routes } from "../components/routing/routes";
import { CrudTable } from "../components/tables/crud-table";
import { ReportTemplateRow } from "../components/tables/custom-rows/report-template-row";
import { dictionary } from "../constants/i18n/dictionary";
import { IViewProps } from "../interfaces/view-props.interface";
import { reportTemplateService } from "../services/report-template.service";

export const ReportTemplatesView: FC<IViewProps> = (props) => (
  <CrudTable<IReportTemplate>
    service={reportTemplateService}
    sortKey="createdAt"
    options={{ populate: [] }}
    headings={{
      name: dictionary.name,
      surveyIds: dictionary.reflectors,
      ownerId: dictionary.expert,
      isPublic: dictionary.is_public,
    }}
    customRow={(reportTemplate) => (
      <ReportTemplateRow
        key={reportTemplate.id}
        reportTemplate={reportTemplate}
        attributes={{
          onClick: () =>
            Router.push(routes.reportTemplateEdit.path, {
              id: reportTemplate.id,
            }),
        }}
      />
    )}
  />
);
