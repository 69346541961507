import { ISurvey, Role } from "@hulanbv/ssllow-packages";
import React, { FC } from "react";
import { Router } from "react-history-router";
import { Button } from "../components/controls/button";
import { routes } from "../components/routing/routes";
import { CrudTable } from "../components/tables/crud-table";
import { SurveyRow } from "../components/tables/custom-rows/survey-row";
import { dictionary } from "../constants/i18n/dictionary";
import { IViewProps } from "../interfaces/view-props.interface";
import { surveyService } from "../services/survey.service";
import { satisfiesRoles } from "../utilities/satisfies-roles";

export const SurveysView: FC<IViewProps> = (props) => (
  <CrudTable<ISurvey>
    service={surveyService}
    sortKey="name"
    sortAscending={true}
    headings={{
      name: dictionary.name,
    }}
    customRow={(survey) => (
      <SurveyRow
        survey={survey}
        attributes={{
          onClick: () =>
            Router.push(routes.surveyDetails.path, { id: survey.id }),
        }}
      />
    )}
    customControls={
      <>
        {satisfiesRoles(Role.ADMIN) && (
          <Button
            attributes={{
              onClick: () => Router.push(routes.createSurvey.path),
            }}
            appearance={"primary"}
          >
            {dictionary.new}
          </Button>
        )}
      </>
    }
  />
);
