import { IQuestionReport, ResultType } from "@hulanbv/ssllow-packages";
import React, { FC } from "react";
import { style, classes } from "typestyle";
import { colors } from "../../constants/colors";
import { IReportProps } from "../../interfaces/report-props.interface";
import { resultTypePluralLabels } from "../../constants/result-type-plural-labels";

export const DotReport: FC<IReportProps> = (props) => {
  const questionResultMap = props.results.reduce<
    Record<string, IQuestionReport>
  >((map, result) => {
    map[result.question.id] = result;
    return map;
  }, {});

  const resultTypeKey = props.section.showPartnerResultType
    ? "partnerResultType"
    : "resultType";

  const gridItemsPerRow =
    100 / Math.ceil(Math.sqrt(props.unfilteredResults.length));
  const resultTypes = [
    ResultType.CHERISH,
    ResultType.DEVELOPMENT,
    ResultType.OBSTACLE,
    ResultType.STARTING,
  ].filter(
    (type) =>
      !props.section.questionFilters?.[resultTypeKey]?.length ||
      props.section.questionFilters[resultTypeKey].includes(type.toString())
  );

  return (
    <div
      {...props.attributes}
      className={[styles.report, props.attributes?.className].join(" ")}
    >
      {resultTypes.map((resultType, i) => {
        const numberOfQuestions = props.unfilteredResults.filter(
          ({ question }) =>
            questionResultMap[question.id]?.[resultTypeKey] === resultType
        ).length;
        return (
          <div
            key={resultType}
            className={classes(
              styles.reportItem,
              i <= 1 && styles.firstRow,
              i % 2 === 0 && styles.evenItem,
              i % 2 !== 0 && styles.oddItem,
              resultTypes.length - 2 <= i && styles.lastRow
            )}
          >
            <div className={styles.title}>
              {numberOfQuestions} {resultTypePluralLabels[resultType]}
            </div>
            <div className={styles.dotGrid}>
              {props.unfilteredResults.map((result) => {
                const questionResultType =
                  questionResultMap[result.question.id]?.[resultTypeKey];
                return (
                  <div
                    key={result.question.id}
                    className={styles.dotContainer}
                    style={{ width: `${gridItemsPerRow}%` }}
                  >
                    <div
                      className={styles.dot}
                      style={{
                        background:
                          resultType === questionResultType
                            ? colors.resultTypesPosOrNeg[resultType]
                            : undefined,
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const styles = {
  report: style({
    pageBreakInside: "avoid",
    marginBottom: 25,
  }),
  reportItem: style({
    padding: 25,
    border: `1px solid ${colors.invitee}`,
    minWidth: "50%",
    maxWidth: "50%",
    height: 270,
    flex: 1,
    display: "inline-flex",
    flexDirection: "column",
    borderBottomWidth: 0,
    borderTopStyle: "dashed",
  }),
  title: style({
    textTransform: "uppercase",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "1.2rem",
    color: colors.invitee,
    flex: 0,
    marginBottom: 10,
  }),

  dotGrid: style({
    display: "flex",
    flexWrap: "wrap",
    color: colors.invitee,
    flex: 1,
  }),
  dotContainer: style({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }),
  dot: style({
    width: 15,
    height: 15,
    borderRadius: "100%",
  }),
  evenItem: style({
    borderRightStyle: "dashed",
    borderTopRightRadius: "0 !important",
    borderBottomRightRadius: "0 !important",
  }),
  oddItem: style({
    borderLeftWidth: 0,
    borderTopLeftRadius: "0 !important",
    borderBottomLeftRadius: "0 !important",
  }),
  firstRow: style({
    borderRadius: "5px 5px 0 0",
    borderTopStyle: "solid",
  }),
  lastRow: style({
    borderBottomWidth: 1,
    borderRadius: "0 0 5px 5px",
  }),
};
