import { Role } from "@hulanbv/ssllow-packages";
import React, { FC } from "react";
import { style } from "typestyle";
import { Card } from "../components/containers/card";
import { AssetForm } from "../components/forms/asset-form";
import { CrudForm } from "../components/forms/crud-form";
import { routes } from "../components/routing/routes";
import { dictionary } from "../constants/i18n/dictionary";
import { IViewProps } from "../interfaces/view-props.interface";
import { assetService } from "../services/asset.service";
import { satisfiesRoles } from "../utilities/satisfies-roles";

export const MotivationEditView: FC<IViewProps> = (props) => (
  <Card attributes={{ className: styles.card }}>
    <CrudForm
      messageSubject={dictionary.motivation}
      form={AssetForm}
      modelId={props.routing.params.id}
      redirectPath={routes.motivations.path}
      service={assetService}
      readOnly={!satisfiesRoles(Role.ADMIN, Role.EXPERT)}
    />
  </Card>
);

const styles = {
  card: style({
    maxWidth: 550,
    margin: "0 auto",
  }),
};
