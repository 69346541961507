import React, { FC } from "react";
import { keyframes, style } from "typestyle";
import { INotification } from "../../interfaces/notification.interface";

interface IProps {
  notification: INotification;
}

export const Notification: FC<IProps> = (props) => (
  <div
    className={[
      styles.notification,
      style({
        animationDuration: `${props.notification.duration}ms`,
        $nest: {
          "&:before": {
            animationDuration: `${props.notification.duration}ms`,
          },
        },
      }),
    ].join(" ")}
  >
    {props.notification.message}
  </div>
);

const shrinkAnimation = keyframes({
  "0%": { width: "100%" },
  "100%": { width: "0%" },
});
const houdiniAnimation = keyframes({
  "0%": { opacity: 0 },
  "3%": { opacity: 1 },
  "97%": { opacity: 1 },
  "100%": { opacity: 0 },
});

const styles = {
  notification: style({
    animationName: houdiniAnimation,
    animationTimingFunction: "linear",
    padding: 20,
    background: "rgba(0,0,0,.83)",
    borderRadius: 6,
    margin: 10,
    color: "#FFF",
    position: "relative",
    overflow: "hidden",
    maxWidth: 500,

    $nest: {
      "&:before": {
        content: "''",
        animationName: shrinkAnimation,
        animationTimingFunction: "linear",
        position: "absolute",
        left: 0,
        top: 0,
        height: "100%",
        width: "100%",
        background: "rgba(0,0,0,.3)",
        zIndex: -1,
      },
    },
  }),
};
