import { ISurvey, Role } from "@hulanbv/ssllow-packages";
import React, { FC, useState } from "react";
import Router from "react-history-router";
import { style } from "typestyle";
import { Card } from "../components/containers/card";
import { SurveyOption } from "../components/controls/option-templates/survey-option";
import { Search } from "../components/controls/search";
import { CrudForm } from "../components/forms/crud-form";
import { FormRow } from "../components/forms/form-row";
import { SurveyForm } from "../components/forms/survey-form";
import { routes } from "../components/routing/routes";
import { dictionary } from "../constants/i18n/dictionary";
import { IViewProps } from "../interfaces/view-props.interface";
import { notificationListener } from "../observables/notification-listener";
import { surveyService } from "../services/survey.service";
import { satisfiesRoles } from "../utilities/satisfies-roles";

export const SurveyEditView: FC<IViewProps> = (props) => {
  const [baseSurveyId, setBaseSurveyId] = useState<string | null>(null);
  const submitForm = async (data: FormData) => {
    if (baseSurveyId) {
      await surveyService.duplicate(baseSurveyId, data);
    } else {
      await surveyService.post(data);
    }
    notificationListener.next({
      message: dictionary.successfully_created(dictionary.reflector),
    });
    Router.push(routes.surveys.path);
  };

  return (
    <Card attributes={{ className: styles.card }}>
      {props.routing.params.id && (
        <CrudForm
          messageSubject={dictionary.reflector}
          form={SurveyForm}
          modelId={props.routing.params.id}
          redirectPath={routes.surveys.path}
          service={surveyService}
          readOnly={!satisfiesRoles(Role.ADMIN, Role.EXPERT)}
        />
      )}
      {!props.routing.params.id && (
        <>
          <FormRow>
            <Search<ISurvey>
              keyField="id"
              label={dictionary.base_statements_on_reflector}
              find={async (search) =>
                (
                  await surveyService.getAll({
                    search,
                    sort: ["name"],
                    limit: 25,
                  })
                ).data
              }
              optionTemplate={SurveyOption}
              onChange={(selection) => setBaseSurveyId(selection[0] || null)}
            />
          </FormRow>
          <SurveyForm onSubmit={submitForm} />
        </>
      )}
    </Card>
  );
};

const styles = {
  card: style({
    maxWidth: 550,
    margin: "0 auto",
  }),
};
