import React, { FC, useEffect } from "react";
import Router from "react-history-router";
import { style } from "typestyle";
import brandLogo from "../assets/images/logo.png";
import { Card } from "../components/containers/card";
import { LoginForm } from "../components/forms/login-form";
import { routes } from "../components/routing/routes";
import { dictionary } from "../constants/i18n/dictionary";
import { IViewProps } from "../interfaces/view-props.interface";
import { notificationListener } from "../observables/notification-listener";
import { authenticationService } from "../services/authentication.service";

export const LoginView: FC<IViewProps> = (props) => {
  const authenticate = async (credentials: FormData) => {
    try {
      await authenticationService.login(credentials);
      Router.replace(routes.home.path);
    } catch {
      notificationListener.next({
        message: dictionary.incorrect_credentials,
        duration: 4000,
      });
    }
  };

  useEffect(() => {
    // navigate to the home page if the user has a session
    if (authenticationService.getSession()) {
      Router.replace(routes.home.path);
    }
  }, []);

  return (
    <>
      <div className={styles.logo} />
      <Card attributes={{ className: styles.card }}>
        <LoginForm
          attributes={{ style: { padding: 10 } }}
          onSubmit={authenticate}
        />
      </Card>
    </>
  );
};

const styles = {
  card: style({
    width: 450,
    margin: "0 auto",
  }),
  welcome: style({
    flex: 1,
  }),
  textCenter: style({
    textAlign: "center",
  }),
  logo: style({
    backgroundImage: `url(${brandLogo})`,
    backgroundPosition: "center center",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    margin: "0 auto",
    width: 200,
    height: 100,
    marginBottom: 30,
  }),
};
