import { IInvite, Role } from "@hulanbv/ssllow-packages";
import React, { FC, useCallback, useEffect, useState } from "react";
import { Router } from "react-history-router";
import { Button } from "../components/controls/button";
import { OrganisationOption } from "../components/controls/option-templates/organisation-option";
import { SurveyOption } from "../components/controls/option-templates/survey-option";
import { TeamOption } from "../components/controls/option-templates/team-option";
import { OrganisationInvitesUpdateForm } from "../components/forms/organisation-invites-update-form";
import { PdfBundleForm } from "../components/forms/pdf-bundle-form";
import { routes } from "../components/routing/routes";
import { CrudTable } from "../components/tables/crud-table";
import { InviteRow } from "../components/tables/custom-rows/invite-row";
import { dictionary } from "../constants/i18n/dictionary";
import { IBundlePdfArguments } from "../interfaces/bundle-pdf-arguments.interface";
import { IFilter } from "../interfaces/filter.interface";
import { IViewProps } from "../interfaces/view-props.interface";
import { notificationListener } from "../observables/notification-listener";
import { inviteService } from "../services/invite.service";
import { organisationService } from "../services/organisation.service";
import { participationService } from "../services/participation.service";
import { reportService } from "../services/report.service";
import { surveyService } from "../services/survey.service";
import { teamService } from "../services/team.service";
import { dialog } from "../singletons/dialog";
import { formDataToObject } from "../utilities/form.utils";
import { satisfiesRoles } from "../utilities/satisfies-roles";

export const InvitesView: FC<IViewProps> = (props) => {
  const [iteration, setIteration] = useState<number>(0);
  const [selectedOrganisationId, setSelectedOrganisationId] = useState<
    string | null
  >(null);
  const [filterTeamIds, setFilterTeamIds] = useState<string[]>([]);
  const [selectedTeamId, setSelectedTeamId] = useState<string | null>(
    props.routing.queries["filter[user.team._id]"] ?? null
  );
  const [filters, setFilters] = useState<IFilter[]>();
  useEffect(() => {
    (async () => {
      setFilters([
        {
          key: "organisationId",
          label: dictionary.organisation,
          searchProps: {
            onChange: (selection) =>
              setSelectedOrganisationId(selection[0] ?? null),
            find: async (search) =>
              (
                await organisationService.getAll({
                  search,
                  searchScope: ["name"],
                  limit: 15,
                })
              ).data,
            keyField: "id",
            optionTemplate: OrganisationOption,
            openOnFocus: true,
          },
        },
        {
          key: "user.team._id",
          label: dictionary.team,
          searchProps: {
            onChange: (selection) => setSelectedTeamId(selection[0] ?? null),
            disabled: !filterTeamIds.length,
            defaultValue: selectedTeamId
              ? (await teamService.getMany([selectedTeamId])).data
              : undefined,
            find: async (search) =>
              (
                await teamService.getMany(filterTeamIds, {
                  search,
                  searchScope: ["name"],
                  limit: 15,
                })
              ).data,
            keyField: "id",
            optionTemplate: TeamOption,
            openOnFocus: true,
          },
          excludeFromOptions: true,
        },
        {
          key: "surveyId",
          label: dictionary.reflector,
          searchProps: {
            find: async (search) =>
              (
                await surveyService.getAll({
                  search,
                  searchScope: ["name"],
                  limit: 15,
                })
              ).data,
            keyField: "id",
            optionTemplate: SurveyOption,
            openOnFocus: true,
          },
        },
      ]);
    })();
  }, [filterTeamIds, selectedTeamId]);

  useEffect(() => {
    if (selectedOrganisationId) {
      organisationService
        .get(selectedOrganisationId ?? "", { pick: ["teamIds"] })
        .then(({ data }) => setFilterTeamIds(data.teamIds ?? []));
    }
  }, [selectedOrganisationId]);

  const deleteParticipation = async (id: string) => {
    await participationService.delete(id);
    setIteration(iteration + 1);
  };

  const handleOrganisationInvitesUpdate = useCallback(
    async (data: FormData, model: Partial<IInvite>, inviteIds: string[]) => {
      for (const inviteId of inviteIds) {
        try {
          await inviteService.patch({ ...model, _id: inviteId } as any);
        } catch {}
      }
      notificationListener.next({
        message: `${inviteIds.length} uitnodigingen aangepast`,
      });
      dialog.unmount();
      setIteration(iteration + 1);
    },
    [iteration]
  );
  if (!filters) {
    return <></>;
  }
  return (
    <CrudTable<IInvite>
      key={iteration}
      service={inviteService}
      sortKey="-createdAt"
      headings={{
        userId: dictionary.user,
        team: dictionary.team,
        organisationId: dictionary.organisation,
        surveyId: dictionary.reflector,
        accessCode: dictionary.partner_code,
        participations: dictionary.participants,
        createdAt: "",
      }}
      filters={filters}
      customFind={
        selectedTeamId
          ? (options) => inviteService.getByTeam(selectedTeamId, options)
          : undefined
      }
      options={{
        searchScope: [
          "user.fullName",
          "user.teams.name",
          "survey.name",
          "organisation.name",
        ],
        populate: [
          "user.teams",
          "organisation",
          "survey",
          "participations",
          "exportRequests.report.template",
        ],
      }}
      customRow={(invite) => (
        <InviteRow
          key={invite.id}
          invite={invite}
          onDeleteParticipation={deleteParticipation}
        />
      )}
      customControls={
        <>
          {satisfiesRoles(Role.ADMIN, Role.EXPERT) && (
            <Button
              size="small"
              attributes={{
                onClick: () => Router.push(routes.createReport.path),
              }}
              appearance={"primary"}
            >
              {dictionary.team_report}
            </Button>
          )}
          {satisfiesRoles(Role.ADMIN, Role.EXPERT) && (
            <Button
              size="small"
              attributes={{
                onClick: () =>
                  dialog.mount({
                    width: 600,
                    title: dictionary.update_organisation_invites,
                    children: (
                      <OrganisationInvitesUpdateForm
                        onSubmit={handleOrganisationInvitesUpdate}
                      />
                    ),
                  }),
              }}
            >
              {dictionary.update_organisation_invites}
            </Button>
          )}
          {satisfiesRoles(Role.ADMIN, Role.EXPERT) && (
            <Button
              size="small"
              attributes={{
                onClick: () =>
                  dialog.mount({
                    width: 600,
                    title: dictionary.download_selection,
                    children: (
                      <PdfBundleForm
                        onSubmit={async (data) => {
                          const args =
                            formDataToObject<IBundlePdfArguments>(data);
                          await reportService.downloadPdfBundle(
                            args.templateId,
                            args.expertId,
                            args.inviteIds
                          );
                          dialog.unmount();
                        }}
                      />
                    ),
                  }),
              }}
            >
              {dictionary.download_selection}
            </Button>
          )}
        </>
      }
    />
  );
};
