import { IUser } from "@hulanbv/ssllow-packages";
import React, { FC, HTMLAttributes } from "react";
import { style } from "typestyle";
import { roleLabels } from "../../../constants/role-labels";
import { StyledTR } from "../styled-table-row";
import { TD } from "../table-data";

interface IProps {
  user: IUser;
  attributes?: HTMLAttributes<HTMLDivElement>;
}

export const UserRow: FC<IProps> = (props) => (
  <StyledTR attributes={props.attributes}>
    <TD attributes={{ className: styles.imageTd }}>
      <div
        className={styles.image}
        style={{
          backgroundImage: props.user.imageThumbPath
            ? `url(${props.user.imageThumbPath})`
            : undefined,
        }}
      />
    </TD>
    <TD>{props.user.fullName}</TD>
    <TD>{props.user.email}</TD>
    <TD>{props.user.language}</TD>
    <TD>{roleLabels[props.user.role]}</TD>
  </StyledTR>
);

const styles = {
  imageTd: style({
    padding: 20,
    paddingRight: 0,
    width: 30,
  }),
  image: style({
    width: 35,
    height: 35,
    borderRadius: 35,
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
  }),
};
