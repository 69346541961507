import { IOrganisation } from "@hulanbv/ssllow-packages";
import React, { FC } from "react";
import { style } from "typestyle";
import { dictionary } from "../../constants/i18n/dictionary";
import { formatDate } from "../../utilities/date.utils";
import { A } from "../controls/a";
import { routes } from "../routing/routes";
import { Row } from "./row";

interface IProps {
  organisation: IOrganisation;
}

export const OrganisationData: FC<IProps> = (props) => (
  <div>
    {props.organisation.imagePath && (
      <div
        className={styles.image}
        style={{
          backgroundImage: `url(${props.organisation.imagePath})`,
        }}
      />
    )}
    <Row label={dictionary.name}>{props.organisation.name}</Row>
    <Row label={dictionary.address}>{props.organisation.address}</Row>
    <Row label={dictionary.city}>{props.organisation.city}</Row>
    <Row label={dictionary.postal_code}>{props.organisation.postalCode}</Row>
    {props.organisation.contact && (
      <Row label={dictionary.contact}>
        <A
          href={routes.userDetails.path.replace(
            ":id",
            props.organisation.contactId
          )}
        >
          {props.organisation.contact.fullName}
        </A>
      </Row>
    )}
    <Row label={dictionary.created_at}>
      {formatDate(
        props.organisation.createdAt!.toString(),
        dictionary.date_format
      )}
    </Row>
  </div>
);
const styles = {
  image: style({
    width: "100%",
    height: 150,
    marginBottom: 30,
    backgroundSize: "contain",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    margin: "0 auto",
  }),
};
