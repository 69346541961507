import {
  IInvite,
  IReportTemplate,
  IUser,
  Role,
} from "@hulanbv/ssllow-packages";
import React, { FC } from "react";
import { dictionary } from "../../constants/i18n/dictionary";
import { IBundlePdfArguments } from "../../interfaces/bundle-pdf-arguments.interface";
import { IFormProps } from "../../interfaces/form-props.interface";
import { authenticationService } from "../../services/authentication.service";
import { inviteService } from "../../services/invite.service";
import { reportTemplateService } from "../../services/report-template.service";
import { userService } from "../../services/user.service";
import { satisfiesRoles } from "../../utilities/satisfies-roles";
import { Button } from "../controls/button";
import { InviteOption } from "../controls/option-templates/invite-option";
import { ReportTemplateOption } from "../controls/option-templates/report-template-option";
import { UserOption } from "../controls/option-templates/user-option";
import { Search } from "../controls/search";
import { Form } from "./form";
import { FormRow } from "./form-row";

export const PdfBundleForm: FC<IFormProps<IBundlePdfArguments>> = (props) => {
  return (
    <Form {...props}>
      <FormRow>
        <Search<IUser>
          keyField="id"
          label={dictionary.in_name_of_expert}
          find={async (search) =>
            (
              await userService.getAll({
                search,
                filter: { role: "!" + Role.USER },
                limit: 15,
              })
            ).data
          }
          disabled={props.disabled || !satisfiesRoles(Role.ADMIN)}
          defaultValue={
            !satisfiesRoles(Role.ADMIN)
              ? [authenticationService.getUser()!]
              : undefined
          }
          optionTemplate={UserOption}
          name="expertId"
          openOnFocus
          required
        />
      </FormRow>

      <FormRow>
        <Search<IReportTemplate>
          keyField="id"
          label={dictionary.choose_report_template}
          find={async (search) =>
            (
              await reportTemplateService.getAll({
                search,
                sort: ["name"],
                limit: 25,
              })
            ).data
          }
          optionTemplate={ReportTemplateOption}
          name="templateId"
          openOnFocus
          placeholder={dictionary.choose_base_template}
          required
        />
      </FormRow>

      <FormRow>
        <Search<IInvite>
          keyField="id"
          label={dictionary.choose_invites}
          find={async (search) =>
            (
              await inviteService.getAll({
                search,
                searchScope: [
                  "accessCode",
                  "user.fullName",
                  "user.email",
                  "organisation.name",
                  "survey.name",
                ],
                populate: [],
                limit: 10,
              })
            ).data
          }
          optionTemplate={InviteOption}
          name="inviteIds"
          multiple
          fullWidthSelection
          openOnFocus
          placeholder={dictionary.choose_invites}
          required
        />
      </FormRow>

      <FormRow>
        <FormRow>
          <Button
            attributes={{ type: "submit", disabled: props.disabled }}
            appearance="primary"
          >
            {dictionary.submit}
          </Button>
        </FormRow>
      </FormRow>
    </Form>
  );
};
