import { ISurvey } from "@hulanbv/ssllow-packages";
import React, { FC } from "react";
import { stylesheet } from "typestyle";
import { languageLabels } from "../../../constants/language-labels";

interface IProps {
  model: ISurvey;
}

export const SurveyOption: FC<IProps> = (props) => (
  <div className={styles.row}>
    {props.model.name}
    <div className={styles.language}>
      {languageLabels[props.model.language]}
    </div>
  </div>
);

const styles = stylesheet({
  row: {
    display: "flex",
    alignItems: "center",
  },
  language: {
    opacity: 0.5,
    marginLeft: 10,
  },
});
