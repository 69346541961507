import React, { FC, useState } from "react";
import { style } from "typestyle";
import { Button } from "./button";

interface IProps {
  accept?: string;
  onChange?: (contents: string[]) => any;
}

let fileSelectId = 0;

export const FileSelect: FC<IProps> = (props) => {
  const [id] = useState(fileSelectId++);
  const [reader] = useState(new FileReader());

  const selectedFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.currentTarget;
    const files = Array.from(target.files ?? []);
    const contents: string[] = await new Promise((res, rej) => {
      const fileContents: string[] = [];
      reader.onload = function () {
        fileContents.push(reader.result?.toString() ?? "");
        if (fileContents.length === files.length) {
          res(fileContents);
        }
      };
      for (const file of files) {
        reader.readAsBinaryString(file);
      }
    });
    target.value = "";
    props.onChange?.(contents);
  };

  return (
    <Button size="small" attributes={{ className: styles.button }}>
      <label htmlFor={`file-select-${id}`} className={styles.buttonLabel}>
        {props.children}
      </label>
      <input
        style={{ display: "none" }}
        accept={props.accept}
        id={`file-select-${id}`}
        type="file"
        onChange={selectedFile}
      />
    </Button>
  );
};

const styles = {
  button: style({
    padding: 0,
  }),
  buttonLabel: style({
    padding: "6px 10px",
    display: "block",
    cursor: "pointer",
  }),
};
