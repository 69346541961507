import React, { FC } from "react";
import { classes, style } from "typestyle";
import { IReportProps } from "../../interfaces/report-props.interface";
import { FloatingCard } from "../containers/floating-card";
import { HighlightReportItem } from "./highlight-report-item";

export const HighlightReport: FC<IReportProps> = (props) => {
  return (
    <div
      {...props.attributes}
      className={[styles.report, props.attributes?.className].join(" ")}
    >
      <FloatingCard
        attributes={{ className: classes(styles.topCard, styles.list) }}
      >
        {props.results.slice(0, 3).map((result, i) => (
          <HighlightReportItem
            key={i}
            result={result}
            report={props.report}
            section={props.section}
            attributes={{ className: styles.item }}
          />
        ))}
      </FloatingCard>

      <div className={styles.list}>
        {props.results.slice(3).map((result, i) => (
          <HighlightReportItem
            key={i}
            result={result}
            report={props.report}
            section={props.section}
            attributes={{ className: styles.item }}
          />
        ))}
      </div>
    </div>
  );
};

const styles = {
  report: style({}),
  topCard: style({
    paddingTop: 15,
    paddingBottom: 15,
  }),
  list: style({
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    paddingLeft: 20,
    paddingRight: 20,
  }),
  item: style({
    padding: "15px 0",
  }),
};
