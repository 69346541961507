import { IInvite, IReport, Role } from "@hulanbv/ssllow-packages";
import React, { FC, HTMLAttributes, useState } from "react";
import Router from "react-history-router";
import { style } from "typestyle";
import closeIcon from "../../../assets/svg/icons/close.svg";
import { colors } from "../../../constants/colors";
import { dictionary } from "../../../constants/i18n/dictionary";
import { mailPartners } from "../../../constants/templates/mail-partners.templates";
import { IDropdownOption } from "../../../interfaces/dropdown-option.interface";
import { notificationListener } from "../../../observables/notification-listener";
import { inviteService } from "../../../services/invite.service";
import { participationService } from "../../../services/participation.service";
import { reportService } from "../../../services/report.service";
import { dialog } from "../../../singletons/dialog";
import { formatDate } from "../../../utilities/date.utils";
import { satisfiesRoles } from "../../../utilities/satisfies-roles";
import { Button } from "../../controls/button";
import { DropdownButton } from "../../controls/dropdown-button";
import { DropdownOptionOption } from "../../controls/option-templates/dropdown-option-option";
import { Pill } from "../../controls/pill";
import { ParticipantProgress } from "../../elements/participant-progress";
import { CompareInvitesForm } from "../../forms/compare-invites-form";
import { MailParticipantsForm } from "../../forms/mail-participants-form";
import { routes } from "../../routing/routes";
import { StyledTR } from "../styled-table-row";
import { TD } from "../table-data";

interface IProps {
  invite: IInvite;
  attributes?: HTMLAttributes<HTMLDivElement>;

  onDeleteParticipation?: (id: string) => any;
}

export const InviteRow: FC<IProps> = (props) => {
  const [iteration, setIteration] = useState(0);

  const partnersRemaining =
    props.invite.maxParticipants -
    (props.invite.participations?.length ?? 0) +
    1;

  const compareReports = async (_report: IReport) => {
    const { data: report } = await reportService.post(_report);
    Router.push(routes.editReport.path, { id: report.id || "" });
  };

  const resendMail = async () => {
    setIteration(iteration + 1);
    if (window.confirm(dictionary.confirm_send_reminder)) {
      await inviteService.sendInvitationMail(props.invite.id);
      notificationListener.next({
        message: dictionary.mail_successfully_sent,
      });
    }
  };

  const mailParticipants = async (data: FormData) => {
    await participationService.mailParticipants(data);

    dialog.unmount();
    notificationListener.next({
      message: dictionary.mail_successfully_sent,
    });
  };

  return (
    <StyledTR
      attributes={{
        ...{
          ...props.attributes,
          className: [styles.row, props.attributes?.className].join(" "),
        },
      }}
    >
      <TD>{props.invite.user?.fullName}</TD>
      <TD>{props.invite?.user?.teams?.[0]?.name}</TD>
      <TD>{props.invite.organisation?.name}</TD>
      <TD>{props.invite.survey?.name}</TD>
      <TD>{props.invite.accessCode}</TD>
      <TD>
        {props.invite?.participations?.map((participation, i) => (
          <div className={styles.progressRow} key={participation.id}>
            <ParticipantProgress
              progress={participation.progress}
              participant={participation.participant}
              color={
                participation.participant.isInvitee
                  ? colors.invitee
                  : colors.participantColors[i]
              }
            />
            {props.onDeleteParticipation && (
              <div
                className={styles.delete}
                onClick={() => {
                  if (window.confirm(dictionary.confirm_deletion)) {
                    props.onDeleteParticipation?.(participation.id);
                  }
                }}
              >
                <img src={closeIcon} alt={dictionary.delete} height={15} />
              </div>
            )}
          </div>
        ))}
        {partnersRemaining > 0 && (
          <div className={styles.remainingPartners}>
            {partnersRemaining} {dictionary.partners_remaining.toLowerCase()}
          </div>
        )}
      </TD>
      <TD>
        <Button
          size={"small"}
          attributes={{
            className: styles.button,
            onClick: () => {
              Router.push(routes.inviteReportConfiguration.path, {
                id: props.invite.id,
              });
            },
          }}
        >
          {dictionary.report}
        </Button>
        {satisfiesRoles(Role.ADMIN, Role.EXPERT) && (
          <DropdownButton<IDropdownOption>
            key={iteration.toString()}
            label={dictionary.options}
            optionTemplate={DropdownOptionOption}
            options={[
              {
                label: dictionary.compare_with_preceeding,
                onClick: () =>
                  dialog.mount({
                    title: dictionary.compare_with_preceeding,
                    children: (
                      <CompareInvitesForm
                        invite={props.invite}
                        onSubmit={compareReports}
                      />
                    ),
                  }),
              },
              {
                label: dictionary.send_reminder,
                onClick: resendMail,
              },
              {
                label: dictionary.mail_partners,
                onClick: () =>
                  dialog.mount({
                    title: dictionary.mail_partners,
                    children: (
                      <MailParticipantsForm
                        inviteId={props.invite.id}
                        defaultValue={mailPartners(
                          props.invite.user?.firstName ?? "",
                          props.invite.survey?.name ?? "",
                          props.invite.accessCode,
                          props.invite.deadline
                        )}
                        defaultParticipations={
                          props.invite.participations?.filter(
                            ({ participant }) => !participant.isInvitee
                          ) || []
                        }
                        onSubmit={mailParticipants}
                      />
                    ),
                  }),
              },
              {
                label: dictionary.edit,
                onClick: () =>
                  Router.push(routes.editInvite.path, { id: props.invite.id }),
              },
            ]}
          />
        )}
        <div>
          {!!props.invite.exportRequests?.length && (
            <DropdownButton<IDropdownOption>
              key={iteration.toString()}
              label={dictionary.report_requests}
              disableStyles
              optionTemplate={(option) => (
                <div className={styles.unclickable}>{option.model.label}</div>
              )}
              options={props.invite.exportRequests.map((request) => ({
                label: (
                  <div>
                    {request.report?.template?.name}{" "}
                    <span
                      className={styles.secondary}
                      title={new Date(
                        request.createdAt ?? Date.now()
                      ).toLocaleTimeString()}
                    >
                      {formatDate(
                        new Date(request.createdAt ?? Date.now()),
                        dictionary.date_format
                      )}
                    </span>
                  </div>
                ),
              }))}
            />
          )}

          {props.invite.isTrial && (
            <Pill attributes={{ className: styles.trialPill }}>
              {dictionary.trial_reflector}
            </Pill>
          )}
        </div>
      </TD>
    </StyledTR>
  );
};

const styles = {
  progressRow: style({
    display: "flex",
    alignItems: "center",
  }),
  delete: style({
    cursor: "pointer",
    opacity: 0.54,
    marginBottom: -2,

    $nest: {
      "&:hover": {
        opacity: 1,
      },
    },
  }),
  button: style({
    width: "auto",
    margin: "0 5px 5px 0",
  }),
  remainingPartners: style({
    opacity: 0.54,
    textAlign: "center",
  }),

  row: style({
    fontSize: 12,
  }),

  trialPill: style({
    padding: 5,
    fontSize: 11,
    display: "inline-block",
    backgroundColor: "#f39c12",
    color: "#fff",
    marginLeft: 5,
  }),

  unclickable: style({
    padding: "15px 30px",
    whiteSpace: "nowrap",
  }),

  secondary: style({
    opacity: 0.54,
    marginLeft: 5,
  }),
};
