import { ITeam, IUser, Role } from "@hulanbv/ssllow-packages";
import React, { FC } from "react";
import { style } from "typestyle";
import { dictionary } from "../../constants/i18n/dictionary";
import { teamService } from "../../services/team.service";
import { userService } from "../../services/user.service";
import { dialog } from "../../singletons/dialog";
import { Button } from "../controls/button";
import { UserOption } from "../controls/option-templates/user-option";
import { Search } from "../controls/search";
import { CrudForm } from "../forms/crud-form";
import { TeamForm } from "../forms/team-form";
import { H1 } from "../typography/h1";
import { Row } from "./row";

interface IProps {
  team: ITeam;
  onChange?: (team: ITeam) => any;
  disabled?: boolean;
}

export const TeamData: FC<IProps> = (props) => {
  const saveTeam = async (memberIds: string[]) => {
    const { data } = await teamService.patch({ id: props.team.id, memberIds });
    props.onChange?.(data);
  };

  return (
    <div>
      <H1>
        <span className={styles.head}>
          {props.team.name}
          <span className={styles.contact}>
            {" "}
            - {props.team.expert?.fullName}
          </span>
        </span>
        {!props.disabled && (
          <Button
            size={"small"}
            attributes={{
              onClick: () => {
                dialog.mount({
                  title: dictionary.edit_team,
                  children: (
                    <CrudForm
                      messageSubject={dictionary.team}
                      form={TeamForm}
                      service={teamService}
                      modelId={props.team.id}
                      onDone={(team) => {
                        if (team) {
                          props.onChange?.(team);
                        }
                        dialog.unmount();
                      }}
                    />
                  ),
                });
              },
            }}
          >
            {dictionary.edit_team}
          </Button>
        )}
      </H1>
      <Row>
        <Search<IUser>
          keyField="id"
          find={async (search) =>
            (
              await userService.getAll({
                search,
                filter: { role: Role.USER },
                sort: ["firstName"],
                limit: 25,
              })
            ).data
          }
          defaultValue={props.team.members}
          optionTemplate={UserOption}
          name="teams"
          multiple
          disabled={props.disabled}
          onChange={saveTeam}
        />
      </Row>
    </div>
  );
};

const styles = {
  head: style({
    whiteSpace: "nowrap",
  }),
  contact: style({
    fontSize: 14,
    opacity: 0.54,
  }),
};
