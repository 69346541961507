/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, HTMLAttributes, useEffect, useRef, useState } from "react";
import { classes, style } from "typestyle";
import searchIcon from "../../assets/svg/icons/search.svg";
import { dictionary } from "../../constants/i18n/dictionary";
import { Label } from "../typography/label";
import { Dropdown } from "./dropdown";
import { Pill } from "./pill";

export type IOptionTemplate<SearchType = any> = { model: SearchType };

export interface ISearchProps<SearchType> {
  find: (query: string) => Promise<SearchType[]> | SearchType[];
  optionTemplate: FC<IOptionTemplate<SearchType>>;
  keyField: keyof SearchType;

  defaultValue?: SearchType[];
  name?: string;
  label?: string;
  required?: boolean;
  placeholder?: string;
  onChange?: (selection: any[]) => any;
  multiple?: boolean;
  disabled?: boolean;
  openOnFocus?: boolean;
  fullWidthSelection?: boolean;
  autocomplete?: boolean;

  attributes?: HTMLAttributes<HTMLDivElement>;
}

export function Search<SearchType>(props: ISearchProps<SearchType>) {
  const [query, setQuery] = useState<string>("");
  const [results, setResults] = useState<SearchType[]>([]);
  const [selection, setSelection] = useState<SearchType[]>([]);
  const $container = useRef<HTMLDivElement>(null);
  const $input = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener("click", closeSearch);
    if (!props.autocomplete) {
      setSelection([...(props.defaultValue || [])]);
    }

    return () => {
      document.removeEventListener("click", closeSearch);
    };
  }, []);

  useEffect(() => {
    if (props.autocomplete) {
      const value = (props.defaultValue?.[0]?.[props.keyField] ?? "") + "";
      setQuery(value);
      if ($input.current) {
        $input.current.innerHTML = value;
      }
    }
  }, [$input.current]);

  /**
   * Search using the current input query
   * @param query
   */
  const search = async (query: string) => {
    if (!query && !props.openOnFocus) {
      resetInput();
    } else {
      setQuery(query);
      setResults(await props.find(query));
    }
  };

  /**
   * Focus the search input
   */
  const focusInput = () => $input.current?.focus();

  /**
   * Close the search dropdown
   */
  const closeSearch = (e: MouseEvent) => {
    if (!$container.current?.contains(e.target as HTMLDivElement)) {
      resetInput();
    }
  };

  /**
   * Reset the search input
   */
  const resetInput = () => {
    if (props.autocomplete) {
      setResults([]);
    } else {
      if ($input.current) {
        $input.current.innerHTML = "";
      }
      setQuery("");
      setResults([]);
    }
  };

  /**
   * Delete the selection on the provided index
   * @param index
   */
  const deleteIndex = (index: number) => {
    setSelection((old) => {
      old.splice(index, 1);
      const newSelection = [...old];
      props.onChange?.(newSelection.map((item) => item[props.keyField]));

      return newSelection;
    });
  };

  /**
   * Add a specific item to the selection
   * @param item
   */
  const selectItem = (item: SearchType) => {
    if (props.autocomplete) {
      const value = item[props.keyField] + "";
      setQuery(value);
      if ($input.current) {
        $input.current.innerHTML = value;
      }
      resetInput();
    } else {
      setSelection((old) => {
        const newSelection = [...old, item];
        props.onChange?.(newSelection.map((item) => item[props.keyField]));

        resetInput();

        return newSelection;
      });
    }
  };

  /**
   * If backspace is press on an empty query, remove the last item
   * @param keyCode
   */
  const checkEmptyBackspace = (keyCode: number) => {
    if (keyCode === 8 && !query) {
      const _selection = [...(selection ?? [])];
      _selection.pop();
      setSelection(_selection);
    }
  };

  return (
    <div ref={$container}>
      {/* inputs form form data values */}
      {!props.autocomplete &&
        props.name &&
        props.multiple &&
        selection.map((selected, i) => (
          <input
            key={i}
            name={`${props.name}[${i}]`}
            value={selected[props.keyField] + ""}
            type="hidden"
          />
        ))}
      {!props.autocomplete &&
        props.name &&
        !props.multiple &&
        !!selection.length && (
          <input
            name={props.name}
            value={(selection[0]?.[props.keyField] ?? "") + ""}
            type="hidden"
          />
        )}

      {props.autocomplete && props.name && (
        <input name={props.name} type="hidden" value={query} />
      )}

      {props.label && <Label required={props.required}>{props.label}</Label>}
      <div
        {...{
          ...props.attributes,
          className: classes(
            styles.wrapper,
            props.disabled && styles.disabled,
            props.attributes?.className
          ),
        }}
        onClick={focusInput}
      >
        {/* input for required setting */}
        {props.required &&
          ((!props.autocomplete && !selection.length) ||
            (props.autocomplete && !query)) && (
            <input
              className={styles.requiredInput}
              onFocus={focusInput}
              required
            />
          )}

        {/* selected items */}
        {selection.map((item, i) => (
          <Pill
            key={i}
            attributes={{
              className: [
                styles.selected,
                !props.multiple || props.fullWidthSelection
                  ? styles.fullWidth
                  : "",
              ].join(" "),
            }}
            onDelete={!props.disabled ? () => deleteIndex(i) : undefined}
          >
            <props.optionTemplate model={item} />
          </Pill>
        ))}

        {/* input for the search bar */}
        {(props.multiple || selection.length === 0) && (
          <div className={styles.inputWrapper}>
            {!props.disabled && (
              <div
                ref={$input}
                className={styles.input}
                contentEditable
                onFocus={props.openOnFocus ? () => search(query) : undefined}
                onKeyDown={(e) => checkEmptyBackspace(e.keyCode)}
                onInput={(e) => search(e.currentTarget.innerHTML)}
              />
            )}
            {!query && (
              <span className={styles.placeholder}>
                <img src={searchIcon} alt="" className={styles.searchIcon} />{" "}
                {props.placeholder ||
                  `${dictionary.search} ${props.label?.toLowerCase() ?? ""}`}
              </span>
            )}
          </div>
        )}
      </div>

      {/* dropdown to show results */}
      <div className={styles.dropdownContainer}>
        {!!results.length && (
          <Dropdown<SearchType>
            attributes={{ className: styles.dropdown }}
            optionTemplate={props.optionTemplate}
            options={results.filter(
              (item) =>
                !selection.some(
                  (selected) =>
                    selected[props.keyField] === item[props.keyField]
                )
            )}
            onClick={(item: SearchType, e) => {
              if (props.multiple) {
                e.preventDefault();
              }

              selectItem(item);
            }}
          />
        )}
      </div>
    </div>
  );
}

const styles = {
  wrapper: style({
    borderRadius: 6,
    border: "1px solid #dedede",
    fontSize: 12,
    fontFamily: "Montserrat, sans-serif",
    width: "100%",
    backgroundColor: "#fff",
    display: "flex",
    flexWrap: "wrap",
    cursor: "text",
    padding: "0 2px",
    position: "relative",
  }),
  inputWrapper: style({
    display: "flex",
    padding: 15,
  }),
  disabled: style({
    background: "#eee",
  }),
  requiredInput: style({
    position: "absolute",
    zIndex: -1,
    opacity: 0,
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  }),
  selected: style({
    cursor: "default",
  }),
  dropdownContainer: style({ position: "relative" }),
  placeholder: style({ opacity: 0.54, display: "flex", alignItems: "center" }),
  input: style({ outline: 0, minWidth: 1 }),
  fullWidth: style({ width: "100%" }),
  dropdown: style({ position: "absolute", left: 0, top: 0, minWidth: "100%" }),
  searchIcon: style({ height: 11, opacity: 0.8, marginRight: 6 }),
};
