import React, { FC, useEffect, useState } from "react";
import { style } from "typestyle";
import { INotification } from "../../interfaces/notification.interface";
import { notificationListener } from "../../observables/notification-listener";
import { Notification } from "./notification";

interface IProps {}

export const NotificationCenter: FC<IProps> = (props) => {
  const [notifications, setNotifications] = useState<{
    [timestamp: string]: INotification;
  }>({});

  /**
   * Clean up all expired notifications
   */
  const cleanup = () => {
    setNotifications((notifications) => {
      const _notifications: typeof notifications = {};
      Object.keys(notifications).forEach((key) => {
        if (Date.now() < +key + (notifications[key].duration ?? 0)) {
          _notifications[key] = notifications[key];
        }
      });

      return _notifications;
    });
  };

  useEffect(() => {
    const addNotification = (notification: INotification) => {
      // force a duration
      notification.duration =
        notification.duration ??
        Math.floor(notification.message.length / 10) * 1000;

      setNotifications((prev) => ({ ...prev, [Date.now()]: notification }));
      setTimeout(cleanup, notification.duration);
    };

    const listener = notificationListener.subscribe(addNotification);
    return () => listener.unsubscribe();
  }, []);

  return (
    <div className={styles.notificationCenter}>
      {Object.keys(notifications)
        .reverse()
        .map((key) => (
          <Notification key={key} notification={notifications[key]} />
        ))}
    </div>
  );
};

const styles = {
  notificationCenter: style({
    position: "fixed",
    right: 30,
    bottom: 30,
  }),
};
