import { IInvite } from "@hulanbv/ssllow-packages";
import React, { FC } from "react";
import { style } from "typestyle";
import { dictionary } from "../../../constants/i18n/dictionary";
import { formatDate } from "../../../utilities/date.utils";
import { Pill } from "../pill";

interface IProps {
  model: IInvite;
}

export const InviteOption: FC<IProps> = (props) => (
  <div>
    <div className={styles.primary}>
      {props.model.user?.fullName}{" "}
      <span className={styles.secondary}>
        {formatDate(
          new Date(props.model.createdAt ?? ""),
          dictionary.date_format
        )}
      </span>
    </div>
    <Pill attributes={{ className: styles.pill }}>
      {props.model.organisation?.name}
    </Pill>
    <Pill attributes={{ className: styles.pill }}>
      {props.model.survey?.name}
    </Pill>
    <Pill attributes={{ className: styles.pill }}>
      {props.model.participations?.length ?? 0}{" "}
      {dictionary.participants.toLowerCase()}
    </Pill>
  </div>
);

const styles = {
  primary: style({
    marginBottom: 8,
  }),
  secondary: style({
    opacity: 0.54,
  }),
  pill: style({
    display: "inline-block",
    fontSize: 11,
    padding: 3,
  }),
};
