import React, { FC, HTMLAttributes } from "react";
import { style } from "typestyle";
import { TR } from "./table-row";

interface IProps {
  attributes?: HTMLAttributes<HTMLDivElement>;
}

export const StyledTR: FC<IProps> = (props) => (
  <TR
    attributes={{
      ...props.attributes,
      className: [
        styles.row,
        props.attributes?.className,
        props.attributes?.onClick ? styles.clickable : "",
      ].join(" "),
    }}
  >
    {props.children}
  </TR>
);

const styles = {
  clickable: style({
    cursor: "pointer",
    $nest: {
      "&:hover > *:after": {
        background: "#fafafa",
      },
    },
  }),
  row: style({
    display: "table-row",
    width: "100%",
    borderRadius: 10,
    margin: 10,

    $nest: {
      "&>*": {
        position: "relative",
        padding: "30px 20px",

        $nest: {
          "&:after": {
            background: "#fff",
            display: "block",
            content: "''",
            position: "absolute",
            left: 0,
            top: 8,
            right: 0,
            bottom: 8,
            zIndex: -1,
            border: "0 solid #dedede",
            borderTopWidth: 1,
            borderBottomWidth: 1,
          },
          "&:first-child:after": {
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: 10,
            borderLeftWidth: 1,
          },
          "&:last-child:after": {
            borderTopRightRadius: 10,
            borderBottomRightRadius: 10,
            borderRightWidth: 1,
          },
        },
      },
    },
  }),
};
