import { ICategory } from "@hulanbv/ssllow-packages";
import React, { FC } from "react";

interface IProps {
  model: ICategory;
}

export const CategoryOption: FC<IProps> = (props) => (
  <div>{props.model.name}</div>
);
