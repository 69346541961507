import React, { FC, useLayoutEffect, useRef } from "react";
import { IFormProps } from "../../interfaces/form-props.interface";
import { formDataToObject } from "../../utilities/form.utils";

const setDisabledState = (form: HTMLFormElement, disabled: boolean) => {
  const elements = Array.from(form.elements);
  for (const element of elements) {
    if (disabled) {
      element.setAttribute("disabled", "true");
    } else {
      element.removeAttribute("disabled");
    }
  }
};

const prefixInputNames = (form: HTMLElement, prefix: string) => {
  const elements = [
    ...Array.from(form.getElementsByTagName("input")),
    ...Array.from(form.getElementsByTagName("select")),
  ];
  for (const element of elements) {
    if (
      !element.getAttribute("name") ||
      element.getAttribute("name")?.startsWith(prefix)
    ) {
      continue;
    }

    const name =
      prefix +
      (element.getAttribute("name") ?? "")
        .replace(/\]/g, "")
        .split("[")
        .map((v) => `[${v}]`)
        .join("");

    element.setAttribute("name", name);
  }
};

export const Form: FC<IFormProps> = (props) => {
  const formRef = useRef<HTMLElement>(null);

  useLayoutEffect(() => {
    if (props.namePrefix && formRef.current) {
      prefixInputNames(formRef.current, props.namePrefix);
    }
  });
  if (props.omitFormTag) {
    return <div ref={formRef as any}>{props.children} </div>;
  }
  return (
    <form
      ref={formRef as any}
      onSubmit={async (e) => {
        e.preventDefault();

        const form = e.currentTarget;
        const formData = new FormData(form);

        setDisabledState(form, true);
        try {
          await props.onSubmit?.(formData, formDataToObject(formData));
        } catch {}
        setDisabledState(form, false);
      }}
      {...props.attributes}
      children={props.children}
    />
  );
};
