import { IReportTemplate, ISurvey, Role } from "@hulanbv/ssllow-packages";
import React, { FC, useRef, useState } from "react";
import { dictionary } from "../../constants/i18n/dictionary";
import { IFormProps } from "../../interfaces/form-props.interface";
import { authenticationService } from "../../services/authentication.service";
import { surveyService } from "../../services/survey.service";
import { formDataToObject } from "../../utilities/form.utils";
import { satisfiesRoles } from "../../utilities/satisfies-roles";
import { Button } from "../controls/button";
import { Checkbox } from "../controls/checkbox";
import { Input } from "../controls/input";
import { SurveyOption } from "../controls/option-templates/survey-option";
import { Search } from "../controls/search";
import { FormRow } from "./form-row";

export const ReportTemplateForm: FC<IFormProps<IReportTemplate>> = (props) => {
  const isDefault = props.model?.id && !props.model.ownerId;
  const isNew = !props.model?.id;
  const isAdmin = satisfiesRoles(Role.ADMIN);

  const formRef = useRef<HTMLFormElement>(null);
  const [isExpertPublic, setIsExpertPublic] = useState<boolean>(
    !props.model?.ownerId
  );

  const saveTemplate = (overwrite = true) => {
    if (!formRef.current) {
      return;
    }

    const data = new FormData(formRef.current);
    if (overwrite && props.model?.id) {
      data.append("_id", props.model?.id);
      data.append("id", props.model?.id);
    }

    const model = {
      ...props.model,
      surveyIds: [],
      _id: undefined,
      id: undefined,
      ...formDataToObject<Partial<IReportTemplate>>(data),
    };
    props.onSubmit?.(data, model as IReportTemplate);
  };

  return (
    <form ref={formRef}>
      {(!isExpertPublic || !isAdmin) && (
        <input
          type="hidden"
          value={authenticationService.getUser()?.id}
          name="ownerId"
        />
      )}

      <FormRow>
        <Input
          label={dictionary.name}
          attributes={{
            disabled: props.disabled,
            defaultValue: props.model?.name,
            name: "name",
          }}
        />
      </FormRow>

      <FormRow>
        <Search<ISurvey>
          keyField="id"
          label={dictionary.reflectors}
          find={async (search) =>
            (await surveyService.getAll({ search, sort: ["name"], limit: 25 }))
              .data
          }
          defaultValue={props.model?.surveys}
          optionTemplate={SurveyOption}
          disabled={props.disabled}
          name="surveyIds"
          multiple
          openOnFocus
        />
      </FormRow>

      {isAdmin && (
        <FormRow>
          <Checkbox
            label={dictionary.available_to_other_experts}
            attributes={{
              disabled: props.disabled,
              checked: isExpertPublic,
              onChange: (e) => setIsExpertPublic(e.currentTarget.checked),
            }}
          />
        </FormRow>
      )}
      <FormRow>
        <Checkbox
          label={dictionary.publicly_available_for_users}
          attributes={{
            name: "isPublic",
            disabled: props.disabled,
            checked: props.model?.isPublic,
          }}
        />
      </FormRow>
      <FormRow>
        <Checkbox
          label={dictionary.requires_partner_reviews}
          attributes={{
            name: "requiresPartnerReviews",
            disabled: props.disabled,
            checked: props.model?.requiresPartnerReviews,
          }}
        />
      </FormRow>

      <FormRow>
        {(isNew || !isDefault || isAdmin) && (
          <FormRow>
            <Button
              attributes={{
                type: "button",
                disabled: props.disabled,
                onClick: () => saveTemplate(false),
              }}
            >
              {dictionary.save_as_new_template}
            </Button>
          </FormRow>
        )}
        {!isNew && (
          <FormRow>
            <Button
              attributes={{
                type: "button",
                disabled: props.disabled,
                onClick: () => saveTemplate(),
              }}
              appearance="primary"
            >
              {dictionary.save_template}
            </Button>
          </FormRow>
        )}
      </FormRow>
    </form>
  );
};
