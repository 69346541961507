import { INewsItem } from "@hulanbv/ssllow-packages";
import React, { FC, HTMLAttributes } from "react";
import { style } from "typestyle";
import { dictionary } from "../../../constants/i18n/dictionary";
import { formatDate } from "../../../utilities/date.utils";
import { H1 } from "../../typography/h1";
import { StyledTR } from "../styled-table-row";
import { TD } from "../table-data";

interface IProps {
  newsItem: INewsItem;
  attributes?: HTMLAttributes<HTMLDivElement>;
}

export const NewsRow: FC<IProps> = (props) => (
  <StyledTR attributes={props.attributes}>
    <TD>
      <H1>{props.newsItem.title}</H1>
      {props.newsItem.image && (
        <div
          className={styles.image}
          style={{ backgroundImage: `url(${props.newsItem.image})` }}
        />
      )}
      <p>{props.newsItem.text}</p>

      <div className={styles.details}>
        {props.newsItem.author?.fullName} -{" "}
        {formatDate(props.newsItem.createdAt!, dictionary.date_format)}
      </div>
    </TD>
  </StyledTR>
);

const styles = {
  image: style({
    width: 300,
    height: 300,
    backgroundSize: "contain",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    margin: "0 auto",
  }),
  details: style({
    opacity: 0.54,
    marginTop: 30,
    fontSize: 14,
  }),
};
