import { IImportedUser, IUser } from "@hulanbv/ssllow-packages";
import { CrudService, IResponse } from "nest-utilities-client";
import { environment } from "../constants/environment.constant";
import { httpService } from "./http.service";

class Service extends CrudService<IUser> {
  constructor() {
    super([environment.REACT_APP_API_URL, "user"].join("/"), httpService);
  }

  createForOrganisation(organisationId: string, users: IImportedUser[]) {
    return httpService.post(
      [this.controller, "organisation", organisationId, "many"].join("/"),
      users
    );
  }

  resetPassword(id: string): Promise<IResponse<void>> {
    return httpService.post(
      [this.controller, id, "password", "reset"].join("/"),
      null
    );
  }
}

export const userService = new Service();
