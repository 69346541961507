/* eslint-disable react-hooks/exhaustive-deps */
import marked from "marked";
import Quill from "quill";
import React, { FC, useEffect, useRef, useState } from "react";
import TurndownService from "turndown";
import { style } from "typestyle";
import { popup } from "../../singletons/popup";
import { Label } from "../typography/label";
import { SelectionTree } from "./selection-tree";

interface IProps {
  onChange?: (value: string) => any;
  outputType?: "markdown" | "html";
  name?: string;
  defaultValue?: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  hideToolbar?: boolean;
  maxLength?: number;

  selectionTree?: Record<string, any>;
}

const turndown = new TurndownService({ br: "<br />" });
export const RichText: FC<IProps> = (props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [value, setValue] = useState<string>(props.defaultValue ?? "");
  const [length, setLength] = useState<number>(0);

  const change = (changedValue: string) => {
    if (props.outputType !== "html") {
      changedValue = turndown.turndown(changedValue);
    }
    props.onChange?.(changedValue);
    setValue(changedValue);
  };

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    const quill = new Quill(containerRef.current, {
      readOnly: props.disabled,
      modules: {
        toolbar: !props.hideToolbar
          ? [
              [{ header: [1, 2, false] }],
              ["bold", "italic"],
              [{ list: "ordered" }, { list: "bullet" }],
              ["link"],
              [{ text: 1 }, { header: 2 }],
            ]
          : false,
      },
      placeholder: props.placeholder ?? props.label,
      theme: "snow",
    });

    setLength(quill.getLength());

    quill.on("text-change", (delta, oldDelta, source) => {
      change(quill.root.innerHTML);

      if (props.maxLength) {
        const length = quill.getLength();
        setLength(length);
        if (length > props.maxLength) {
          quill.deleteText(props.maxLength, length);
        }
      }

      if (props.selectionTree) {
        const inserted = delta.ops.filter((op) => !!op.insert)[0]?.insert;
        const selection = quill.getSelection();
        popup.unmount();
        if (selection && source === "user" && inserted === "{") {
          popup.mount({
            target: containerRef.current!,
            children: (
              <SelectionTree
                tree={props.selectionTree}
                onSelect={(value) => {
                  if (value) {
                    value = value + "}";
                    quill.insertText(selection.index, value);
                    setTimeout(
                      () =>
                        quill.setSelection({
                          index: selection.index + value!.length,
                          length: 0,
                        }),
                      1
                    );
                  }
                  popup.unmount();
                }}
              />
            ),
          });
        }
      }
    });
  }, [props.disabled]);

  return (
    <div className={styles.container} key={(!!props.disabled).toString()}>
      <input type="hidden" name={props.name} value={value} />
      {props.label && <Label required={props.required}>{props.label}</Label>}
      <div
        className={styles.editor}
        {...(props.maxLength
          ? { "data-count": [length - 1, props.maxLength].join(" / ") }
          : {})}
        ref={containerRef}
        dangerouslySetInnerHTML={{
          __html: marked(props.defaultValue ?? "", { mangle: false }),
        }}
      />
      <input
        required={props.required}
        className={styles.requiredInput}
        tabIndex={-1}
        value={value}
        onChange={() => {}}
      />
    </div>
  );
};

const styles = {
  container: style({
    display: "flex",
    flexDirection: "column",
  }),
  requiredInput: style({
    opacity: 0,
    height: 1,
    width: 1,
    padding: 0,
    margin: 0,
  }),
  editor: style({
    position: "relative",
    $nest: {
      // use the data-count attribute as content for the after selector
      "&[data-count]::after": {
        content: `attr(data-count)`,
        right: 10,
        bottom: 10,
        fontSize: 11,
        opacity: 0.5,
        position: "absolute",
      },
    },
  }),
};
