import { IQuestion } from "@hulanbv/ssllow-packages";
import React, { FC, HTMLAttributes } from "react";
import { style } from "typestyle";
import { StyledTR } from "../styled-table-row";
import { TD } from "../table-data";

interface IProps {
  question: IQuestion;
  attributes?: HTMLAttributes<HTMLDivElement>;
}

export const QuestionRow: FC<IProps> = (props) => (
  <StyledTR
    attributes={{
      ...props.attributes,
      className: [styles.tr, props.attributes?.className || undefined].join(
        " "
      ),
    }}
  >
    <TD>{props.question.name}</TD>
    <TD>{props.question.question}</TD>
    <TD>{props.question.participantQuestion}</TD>
    <TD>{props.question.categories?.map(({ name }) => name).join(", ")}</TD>
    <TD>{props.question.genre?.name}</TD>
    <TD>{props.question.classification}</TD>
  </StyledTR>
);

const styles = {
  tr: style({
    fontSize: 12,
  }),
};
