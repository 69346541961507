import React, { FC } from "react";
import { style } from "typestyle";
import { colors } from "../../constants/colors";

interface IProps {
  page: number;
  totalPages: number;
  maxButtons?: number;

  onPageChange: (page: number) => void;
}

export const Pagination: FC<IProps> = (props) => {
  const maxButtons = props.maxButtons ?? 7;
  const pages = Array(props.totalPages)
    .fill(0)
    .map((x, i) => i + 1);

  const leastShift = Math.ceil(maxButtons / 2);
  const leastPop = Math.floor(maxButtons / 2);

  // shift from 1 to x
  const toShift = Math.max(
    0,
    props.page -
      leastShift -
      Math.max(0, leastPop - (pages.length - props.page))
  );
  pages.splice(1, toShift);

  pages.reverse();

  // pop from x to len-1
  const toPop = Math.max(
    0,
    pages.length -
      (props.page - toShift) -
      leastPop -
      Math.max(0, leastShift - toShift - props.page)
  );
  pages.splice(1, toPop);

  pages.reverse();

  let lastPage = pages[0];
  return (
    <div className={styles.pagination}>
      {pages.map((page) => {
        const activeClass =
          props.page === page ? styles.active : styles.clickable;
        const tags = [];

        if (Math.abs(lastPage - page) > 1) {
          tags.push(
            <div className={styles.tag} key={page + "_fill"}>
              ...
            </div>
          );
        }

        tags.push(
          <div
            onClick={() => props.onPageChange(page)}
            className={[styles.tag, activeClass].join(" ")}
            key={page}
          >
            {page}
          </div>
        );

        lastPage = page;
        return tags;
      })}
    </div>
  );
};

const styles = {
  pagination: style({
    display: "flex",
  }),
  clickable: style({
    cursor: "pointer",
    $nest: {
      "&:hover": {
        backgroundColor: "#fafafa",
      },
    },
  }),
  tag: style({
    background: "#fff",
    width: 45,
    height: 45,
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    border: "1px solid #c4bec4",
    borderLeftWidth: 0,
    transition: ".2s background-color",
    userSelect: "none",
    $nest: {
      "&:first-child": {
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
        borderLeftWidth: 1,
      },
      "&:last-child": {
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
      },
    },
  }),
  active: style({
    color: "#fff",
    background: colors.primary,
  }),
};
