import React, { FC, useMemo } from "react";
import { style, classes } from "typestyle";
import { IReportProps } from "../../interfaces/report-props.interface";
import { dictionary } from "../../constants/i18n/dictionary";
import { H1 } from "../typography/h1";
import { Snail } from "../elements/snail";
import { colors } from "../../constants/colors";

export const DevelopmentCapacityReport: FC<IReportProps> = (props) => {
  const capacity = useMemo(() => {
    const sum = props.results.reduce(
      (sum, result) =>
        sum +
        (result[
          props.section.showPartnerResultType ? "averageScore" : "userScore"
        ] ?? 0),
      0
    );
    return Math.round(sum / props.results.length);
  }, [props.results, props.section.showPartnerResultType]);

  return (
    <div
      {...props.attributes}
      className={classes(styles.report, props.attributes?.className)}
    >
      <Snail shellContent={`${capacity}%`} />
      <H1 attributes={{ className: styles.capacityLabel }}>
        {dictionary.development_potential}
      </H1>
    </div>
  );
};

const styles = {
  report: style({
    pageBreakInside: "avoid",
    marginBottom: 20,
    marginTop: 15,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }),
  capacityLabel: style({
    marginLeft: 10,
    paddingBottom: 0,
    color: colors.invitee,
  }),
};
