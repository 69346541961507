import { IMailConfig, IParticipation } from "@hulanbv/ssllow-packages";
import { CrudService, IHttpOptions, IResponse } from "nest-utilities-client";
import { environment } from "../constants/environment.constant";
import { httpService } from "./http.service";

class Service extends CrudService<IParticipation> {
  constructor() {
    super(
      [environment.REACT_APP_API_URL, "participation"].join("/"),
      httpService
    );
  }

  mailParticipants(body: IMailConfig | FormData) {
    return httpService.post(
      [this.controller, "mail", "participant"].join("/"),
      body
    );
  }

  getByTeamSurvey(
    teamId: string,
    surveyId: string,
    options?: IHttpOptions
  ): Promise<IResponse<IParticipation[]>> {
    return httpService.get(
      [this.controller, "team", teamId, "survey", surveyId].join("/"),
      options
    );
  }
}

export const participationService = new Service();
