import {
  IOrganisation,
  IPublicInvite,
  IReportTemplate,
  ISurvey,
  Role,
} from "@hulanbv/ssllow-packages";
import React, { FC, useEffect, useState } from "react";
import { dictionary } from "../../constants/i18n/dictionary";
import { FormRow } from "./form-row";
import { Button } from "../controls/button";
import { Input } from "../controls/input";
import { Form } from "./form";
import { IFormProps } from "../../interfaces/form-props.interface";
import { inviteContextCodes } from "../../constants/context-code-trees";
import { organisationService } from "../../services/organisation.service";
import { reportTemplateService } from "../../services/report-template.service";
import { surveyService } from "../../services/survey.service";
import { Checkbox } from "../controls/checkbox";
import { OrganisationOption } from "../controls/option-templates/organisation-option";
import { ReportTemplateOption } from "../controls/option-templates/report-template-option";
import { SurveyOption } from "../controls/option-templates/survey-option";
import { RichText } from "../controls/rich-text";
import { Search } from "../controls/search";
import { InfoRow } from "../statics/info-row";
import { satisfiesRoles } from "../../utilities/satisfies-roles";
import { environment } from "../../constants/environment.constant";
import { style } from "typestyle";
import { Label } from "../typography/label";

interface IProps extends IFormProps<Partial<IPublicInvite>> {}

export const PublicInviteForm: FC<IProps> = (props) => {
  const [reportTemplates, setReportTemplates] = useState<
    IReportTemplate[] | null
  >(null);

  const canEditTexts = satisfiesRoles(Role.ADMIN);
  const [organisation, setOrganisation] = useState<IOrganisation | null>(
    props.model?.organisation ?? null
  );
  const [survey, setSurvey] = useState<string | null>(
    props.model?.surveyId ?? null
  );

  /**
   * Fetch the options created by selecting an organisation
   * @param organisationId
   */
  const fetchOptions = async (organisationId: string) => {
    try {
      const { data } = await organisationService.get(organisationId, {
        populate: ["teams", "members"],
      });
      setOrganisation(data || null);
    } catch {
      setOrganisation(null);
    }
  };

  useEffect(() => {
    const fetchReportTemplates = async () => {
      if (props.model?.reportTemplateIds?.length) {
        const { data } = await reportTemplateService.getMany(
          props.model?.reportTemplateIds ?? []
        );
        setReportTemplates(data);
      } else {
        setReportTemplates([]);
      }
    };
    fetchReportTemplates();
  }, [props.model]);

  if (reportTemplates === null) {
    return null;
  }

  return (
    <Form {...props}>
      <input type="hidden" name="id" value={props.model?.id} />
      <input type="hidden" name="_id" value={props.model?.id} />

      {props.model?.id && (
        <FormRow>
          <div>
            <Label>
              {dictionary.share_the_following_url_with_potential_users}
            </Label>
            <div className={styles.url}>
              <a
                href={environment.REACT_APP_APP_REGISTRATION_URL.replace(
                  ":publicInviteId",
                  props.model.id ?? ""
                )}
                target="__blank"
              >
                {environment.REACT_APP_APP_REGISTRATION_URL.replace(
                  ":publicInviteId",
                  props.model.id ?? ""
                )}
              </a>
            </div>
          </div>
        </FormRow>
      )}

      <FormRow>
        <Search<IOrganisation>
          keyField="id"
          label={dictionary.organisation}
          defaultValue={
            props.model?.organisation ? [props.model?.organisation] : []
          }
          find={async (search) =>
            (
              await organisationService.getAll({
                search,
                searchScope: ["name"],
                sort: ["name"],
                limit: 25,
              })
            ).data
          }
          onChange={(ids: string[]) => fetchOptions(ids[0])}
          optionTemplate={OrganisationOption}
          disabled={props.disabled}
          name="organisationId"
          openOnFocus
          required
        />
      </FormRow>

      <FormRow>
        <Search<ISurvey>
          keyField="id"
          label={dictionary.reflector}
          defaultValue={props.model?.survey ? [props.model?.survey] : []}
          find={async (search) =>
            (await surveyService.getAll({ search, sort: ["name"], limit: 25 }))
              .data
          }
          onChange={(ids: string[]) => setSurvey(ids[0])}
          optionTemplate={SurveyOption}
          disabled={props.disabled}
          name="surveyId"
          openOnFocus
          required
        />
      </FormRow>

      <FormRow>
        <Search<IReportTemplate>
          keyField="id"
          multiple
          label={dictionary.specific_app_templates}
          defaultValue={reportTemplates}
          find={async (search) =>
            (
              await reportTemplateService.getBySurvey(survey ?? "", {
                search,
                sort: ["name"],
                limit: 25,
              })
            ).data
          }
          optionTemplate={ReportTemplateOption}
          disabled={props.disabled || !survey}
          name="reportTemplateIds"
          openOnFocus
        />
      </FormRow>

      <FormRow>
        <Input
          label={dictionary.number_of_participants}
          attributes={{
            type: "number",
            defaultValue: props.model?.maxParticipants,
            name: "maxParticipants",
            required: true,
            min: 0,
            max: 4,
          }}
        />
      </FormRow>

      {!organisation && <InfoRow>{dictionary.select_an_organisation}</InfoRow>}
      {!!organisation && (
        <>
          <FormRow>
            <RichText
              label={dictionary.app_intro_without_salutation}
              name="appIntro"
              defaultValue={props.model?.appIntro ?? organisation?.appIntro}
              selectionTree={inviteContextCodes}
              outputType="markdown"
              disabled={!canEditTexts}
              hideToolbar={!canEditTexts}
            />
          </FormRow>

          <FormRow>
            <RichText
              label={dictionary.reflector_intro}
              name="surveyIntro"
              defaultValue={
                props.model?.surveyIntro ?? organisation?.surveyIntro
              }
              selectionTree={inviteContextCodes}
              outputType="markdown"
              disabled={!canEditTexts}
              hideToolbar={!canEditTexts}
            />
          </FormRow>

          <FormRow>
            <RichText
              label={dictionary.reflector_participant_intro}
              name="surveyParticipantIntro"
              defaultValue={
                props.model?.surveyParticipantIntro ??
                organisation?.surveyParticipantIntro
              }
              selectionTree={inviteContextCodes}
              outputType="markdown"
              disabled={!canEditTexts}
              hideToolbar={!canEditTexts}
            />
          </FormRow>

          <FormRow>
            <RichText
              label={dictionary.reflector_ending}
              name="surveyEnding"
              defaultValue={
                props.model?.surveyEnding ?? organisation?.surveyEnding
              }
              selectionTree={inviteContextCodes}
              outputType="markdown"
              disabled={!canEditTexts}
              hideToolbar={!canEditTexts}
            />
          </FormRow>

          <FormRow>
            <RichText
              label={dictionary.reflector_participant_ending}
              name="surveyParticipantEnding"
              defaultValue={
                props.model?.surveyParticipantEnding ??
                organisation?.surveyParticipantEnding
              }
              selectionTree={inviteContextCodes}
              outputType="markdown"
              disabled={!canEditTexts}
              hideToolbar={!canEditTexts}
            />
          </FormRow>
        </>
      )}

      <FormRow>
        <Checkbox
          label={dictionary.show_indicator_during_reflector}
          attributes={{
            disabled: props.disabled,
            checked: props.model?.showIndicator,
            name: "showIndicator",
          }}
        />
      </FormRow>

      <FormRow>
        <Checkbox
          label={dictionary.show_access_code_to_user}
          attributes={{
            disabled: props.disabled,
            checked: props.model?.showAccessCode,
            name: "showAccessCode",
          }}
        />
      </FormRow>

      <FormRow>
        <Checkbox
          label={dictionary.show_participants_during_reflector}
          attributes={{
            disabled: props.disabled,
            checked: props.model?.showCharacters,
            name: "showCharacters",
          }}
        />
      </FormRow>

      <FormRow>
        <Checkbox
          label={dictionary.show_participant_results_to_user}
          attributes={{
            disabled: props.disabled,
            checked: props.model?.showReactions,
            name: "showReactions",
          }}
        />
      </FormRow>

      <FormRow>
        <Checkbox
          label={dictionary.show_action_and_advice}
          attributes={{
            disabled: props.disabled,
            checked: props.model?.showResults,
            name: "showResults",
          }}
        />
      </FormRow>

      <FormRow>
        <Checkbox
          label={dictionary.enable_reports}
          attributes={{
            disabled: props.disabled,
            checked: props.model?.enableReports,
            name: "enableReports",
          }}
        />
      </FormRow>

      <FormRow>
        <FormRow>
          <Button
            attributes={{ type: "submit", disabled: props.disabled }}
            appearance="primary"
          >
            {dictionary.submit}
          </Button>
        </FormRow>
      </FormRow>
    </Form>
  );
};

const styles = {
  url: style({
    padding: 10,
    background: "#f5f5f5",
    border: "1px solid #c4bec4",
    borderRadius: 6,
  }),
};
