import { IInvite, IOrganisation } from "@hulanbv/ssllow-packages";
import React, { FC, FormEvent, useCallback, useState } from "react";
import { dictionary } from "../../constants/i18n/dictionary";
import { inviteService } from "../../services/invite.service";
import { organisationService } from "../../services/organisation.service";
import { formDataToObject } from "../../utilities/form.utils";
import { Button } from "../controls/button";
import { Checkbox } from "../controls/checkbox";
import { OrganisationOption } from "../controls/option-templates/organisation-option";
import { Search } from "../controls/search";
import { FormRow } from "./form-row";

interface IProps {
  disabled?: boolean;
  onSubmit?: (
    data: FormData,
    model: Partial<IInvite>,
    inviteIds: string[]
  ) => Promise<void>;
}

export const OrganisationInvitesUpdateForm: FC<IProps> = (props) => {
  const [inviteIds, setInviteIds] = useState<string[]>([]);
  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const formData = new FormData(event.currentTarget);

      props.onSubmit?.(formData, formDataToObject(formData), inviteIds);
    },
    [inviteIds, props.onSubmit]
  );

  const handleOrganisationChange = useCallback(async (value: string[]) => {
    if (!value[0]) {
      setInviteIds([]);
      return;
    }

    const invites = await inviteService.getAll({
      filter: { organisationId: value[0] },
      pick: ["id"],
    });

    setInviteIds(invites.data.map(({ id }) => id));
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <FormRow>
        <Search<IOrganisation>
          keyField="id"
          onChange={handleOrganisationChange}
          label={dictionary.organisation}
          find={async (search) =>
            (
              await organisationService.getAll({
                search,
                sort: ["name"],
                limit: 25,
              })
            ).data
          }
          openOnFocus
          optionTemplate={OrganisationOption}
          disabled={props.disabled}
          required
        />
      </FormRow>
      <FormRow>
        <Checkbox
          label={dictionary.show_indicator_during_reflector}
          attributes={{
            disabled: props.disabled,
            name: "showIndicator",
          }}
        />
      </FormRow>

      <FormRow>
        <Checkbox
          label={dictionary.show_access_code_to_user}
          attributes={{
            disabled: props.disabled,
            name: "showAccessCode",
          }}
        />
      </FormRow>

      <FormRow>
        <Checkbox
          label={dictionary.show_participants_during_reflector}
          attributes={{
            disabled: props.disabled,
            name: "showCharacters",
          }}
        />
      </FormRow>

      <FormRow>
        <Checkbox
          label={dictionary.show_participant_results_to_user}
          attributes={{
            disabled: props.disabled,
            name: "showReactions",
          }}
        />
      </FormRow>

      <FormRow>
        <Checkbox
          label={dictionary.show_action_and_advice}
          attributes={{
            disabled: props.disabled,
            name: "showResults",
          }}
        />
      </FormRow>

      <FormRow>
        <Checkbox
          label={dictionary.enable_reports}
          attributes={{
            disabled: props.disabled,
            name: "enableReports",
          }}
        />
      </FormRow>

      <FormRow>
        <Checkbox
          label={dictionary.trial_reflector}
          attributes={{
            disabled: props.disabled,
            name: "isTrial",
          }}
        />
      </FormRow>
      <FormRow>
        <Button
          attributes={{
            type: "submit",
            disabled: props.disabled || inviteIds.length === 0,
          }}
          appearance="primary"
        >
          {dictionary.submit}
        </Button>
      </FormRow>
    </form>
  );
};
