import { INewsItem } from "@hulanbv/ssllow-packages";
import React, { FC } from "react";
import { style } from "typestyle";
import { dictionary } from "../../constants/i18n/dictionary";
import { IFormProps } from "../../interfaces/form-props.interface";
import { Button } from "../controls/button";
import { Input } from "../controls/input";
import { Textarea } from "../controls/textarea";
import { Form } from "./form";
import { FormRow } from "./form-row";

export const NewsForm: FC<IFormProps<INewsItem>> = (props) => (
  <Form {...props}>
    <input type="hidden" name="id" value={props.model?.id} />
    <input type="hidden" name="_id" value={props.model?.id} />

    {props.model?.image && (
      <FormRow>
        <div>
          <div
            className={styles.image}
            style={{
              backgroundImage: `url(${props.model.image})`,
            }}
          />
        </div>
      </FormRow>
    )}

    <FormRow>
      <Input
        label={dictionary.image}
        attributes={{
          disabled: props.disabled,
          name: "file",
          type: "file",
          accept: "image/*",
        }}
      />
    </FormRow>
    <FormRow>
      <Input
        label={dictionary.title}
        attributes={{
          disabled: props.disabled,
          defaultValue: props.model?.title,
          name: "title",
          required: true,
        }}
      />
    </FormRow>
    <FormRow>
      <Textarea
        label={dictionary.content}
        attributes={{
          disabled: props.disabled,
          defaultValue: props.model?.text,
          name: "text",
          required: true,
        }}
      />
    </FormRow>
    <FormRow>
      <FormRow>
        <Button
          attributes={{ type: "submit", disabled: props.disabled }}
          appearance="primary"
        >
          {dictionary.submit}
        </Button>
      </FormRow>
    </FormRow>
  </Form>
);

const styles = {
  image: style({
    width: 300,
    height: 300,
    backgroundSize: "contain",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    margin: "0 auto",
  }),
};
