import React, { FC, InputHTMLAttributes, useEffect, useState } from "react";
import { style } from "typestyle";
import { Label } from "../typography/label";

interface IProps {
  attributes?: InputHTMLAttributes<HTMLInputElement>;
  label?: string;
}

export const Checkbox: FC<IProps> = (props) => {
  const [checked, setChecked] = useState<boolean>(false);

  useEffect(() => {
    setChecked(!!props.attributes?.checked);
  }, [props.attributes]);

  return (
    <div className={styles.inputWrapper} key={checked.toString()}>
      <input
        type="hidden"
        name={props.attributes?.name}
        value={(checked || false).toString()}
      />
      <Label required={props.attributes?.required}>
        <input
          {...{
            ...props,
            label: undefined,
            onChange: (e) => {
              setChecked(!checked);
              props.attributes?.onChange?.(e);
            },
            type: "checkbox",
            name: undefined,
            className: styles.checkbox,
            value: (checked || false).toString(),
            checked,
          }}
        />
        {props.label}
      </Label>
    </div>
  );
};

const styles = {
  checkbox: style({ width: 15, marginRight: 10, display: "inline-block" }),
  inputWrapper: style({
    display: "flex",
    alignItems: "center",
  }),
};
