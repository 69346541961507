import { ITeachingAid, TeachingAidType } from "@hulanbv/ssllow-packages";
import React, { FC, useEffect, useState } from "react";
import { teachingAidTypeLabels } from "../../constants/asset-type-labels";
import { dictionary } from "../../constants/i18n/dictionary";
import { IFormProps } from "../../interfaces/form-props.interface";
import { IOption } from "../../interfaces/option.interface";
import { teachingAidService } from "../../services/teaching-aid.service";
import { Button } from "../controls/button";
import { Input } from "../controls/input";
import { OptionOption } from "../controls/option-templates/option-option";
import { RichText } from "../controls/rich-text";
import { Search } from "../controls/search";
import { Select } from "../controls/select";
import { AssetUpload } from "../elements/asset-upload";
import { Form } from "./form";
import { FormRow } from "./form-row";

export const TeachingAidForm: FC<IFormProps<ITeachingAid>> = (props) => {
  const [type, setType] = useState(props.model?.type ?? TeachingAidType.TEXT);
  const [labels, setLabels] = useState<string[]>([]);

  useEffect(() => {
    (async () => {
      const labels = await teachingAidService.getLabels();
      setLabels(labels);
    })();
  }, []);

  return (
    <Form {...props}>
      <input type="hidden" name="id" value={props.model?.id} />
      <input type="hidden" name="_id" value={props.model?.id} />

      <FormRow>
        <Search<IOption>
          label={`${dictionary.label} (${dictionary.internal_use})`}
          disabled={props.disabled}
          openOnFocus
          defaultValue={
            props.model?.label
              ? [{ key: props.model.label, value: props.model.label }]
              : undefined
          }
          keyField="value"
          name="label"
          required={true}
          optionTemplate={OptionOption}
          autocomplete
          find={(query) =>
            labels
              .filter((label) =>
                label.toLowerCase().includes(query.toLowerCase())
              )
              .map((label) => ({ key: label, value: label }))
          }
          attributes={{
            defaultValue: props.model?.label,
            placeholder: "plaatjes/grafieken/ondernemerschap",
          }}
        />
      </FormRow>

      <FormRow>
        <Input
          label={dictionary.title}
          attributes={{
            disabled: props.disabled,
            defaultValue: props.model?.title,
            name: "title",
            required: true,
          }}
        />
      </FormRow>

      <FormRow>
        <Select
          label={dictionary.type}
          options={teachingAidTypeLabels}
          noEmptyOption
          attributes={{
            disabled: props.disabled,
            defaultValue: type,
            name: "type",
            required: true,
            onChange: (e) => {
              setType(+e.currentTarget.value);
            },
          }}
        />
      </FormRow>

      {type === TeachingAidType.IMAGE && (
        <FormRow>
          <AssetUpload
            name="assetId"
            asset={props.model?.asset}
            required
            disabled={props.disabled}
          />
        </FormRow>
      )}

      {type === TeachingAidType.LINK && (
        <FormRow>
          <Input
            label={dictionary.url}
            attributes={{
              disabled: props.disabled,
              defaultValue: props.model?.url,
              name: "url",
              required: true,
            }}
          />
        </FormRow>
      )}

      {type === TeachingAidType.VIDEO && (
        <FormRow>
          <Input
            label={dictionary.video}
            attributes={{
              disabled: props.disabled,
              defaultValue: props.model?.url,
              name: "url",
              required: true,
            }}
          />
        </FormRow>
      )}

      <FormRow>
        <RichText
          disabled={props.disabled}
          label={dictionary.text}
          name="description"
          defaultValue={props.model?.description}
          required={TeachingAidType.TEXT === type}
        />
      </FormRow>

      <FormRow>
        <Input
          label={dictionary.learning_duration_minutes}
          attributes={{
            type: "number",
            disabled: props.disabled,
            defaultValue: props.model?.duration,
            name: "duration",
          }}
        />
      </FormRow>

      <FormRow>
        <FormRow>
          <Button
            attributes={{ type: "submit", disabled: props.disabled }}
            appearance="primary"
          >
            {dictionary.submit}
          </Button>
        </FormRow>
      </FormRow>
    </Form>
  );
};
