import { ResultType } from "@hulanbv/ssllow-packages";

export const colors = {
  primary: "#f9b32f",

  invitee: "#68416d",

  constructive: "#c04a6a",
  destructive: "#3f7c81",

  // the start/end gradient values for a constructive color
  constructiveGradient: ["#f09fb0", "#c67795"] as [string, string],
  // the start/end gradient values for a destructive color
  destructiveGradient: ["#4eadad", "#608495"] as [string, string],
  // the start/end gradient values for a neutral color
  neutralGradient: ["#f2b038", "#d49650"] as [string, string],

  cherish: "#c04a6a",
  development: "#3f7c81",
  obstacle: "#3f7c81",
  starting_point: "#c04a6a",

  ResultTypes: {
    [ResultType.CHERISH]: "#c04a6a",
    [ResultType.STARTING]: "#c04a6a",
    [ResultType.DEVELOPMENT]: "#417a81",
    [ResultType.OBSTACLE]: "#417a81",
    [ResultType.UNDEFINED]: "#c4bec4",
  },
  resultTypesPosOrNeg: {
    [ResultType.CHERISH]: "#c04a6a",
    [ResultType.STARTING]: "#c04a6a",
    [ResultType.DEVELOPMENT]: "#417a81",
    [ResultType.OBSTACLE]: "#417a81",
    [ResultType.UNDEFINED]: "#c4bec4",
  },

  participantColors: [
    "#EB5C43",
    "#F28D4D",
    "#FAB430",
    "#F3A4B3",
    "#C4A2CC",
    "#67416B",
    "#3E4899",
    "#94B9E3",
    "#32B2B2",
    "#69BA7A",
  ],
};
