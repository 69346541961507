import { IUser, Role } from "@hulanbv/ssllow-packages";
import React, { FC } from "react";
import { style } from "typestyle";
import { genderLabels } from "../../constants/gender-labels";
import { dictionary } from "../../constants/i18n/dictionary";
import { languageLabels } from "../../constants/language-labels";
import { roleLabels } from "../../constants/role-labels";
import { IFormProps } from "../../interfaces/form-props.interface";
import { Dictionary } from "../../types/dictionary.type";
import { satisfiesRoles } from "../../utilities/satisfies-roles";
import { Button } from "../controls/button";
import { DateInput } from "../controls/date-input";
import { Input } from "../controls/input";
import { RichText } from "../controls/rich-text";
import { Select } from "../controls/select";
import { Form } from "./form";
import { FormRow } from "./form-row";

interface IProps extends IFormProps<IUser> {
  availableRoles?: Role[];
}

export const UserForm: FC<IProps> = (props) => {
  let roles: Role[] = [Role.USER];

  if (satisfiesRoles(Role.ADMIN)) {
    roles = roles.concat([Role.EXPERT, Role.ADMIN, Role.VIEWER]);
  }
  roles = props.availableRoles ?? roles;

  return (
    <Form {...props}>
      <input type="hidden" name="id" value={props.model?.id} />
      <input type="hidden" name="_id" value={props.model?.id} />

      {props.model?.imagePath && (
        <FormRow>
          <div>
            <div
              className={styles.image}
              style={{
                backgroundImage: `url(${props.model.imagePath})`,
              }}
            />
          </div>
        </FormRow>
      )}

      <FormRow>
        <Input
          label={dictionary.profile_picture}
          attributes={{
            disabled: props.disabled,
            name: "file",
            type: "file",
            accept: "image/*",
          }}
        />
      </FormRow>
      <FormRow>
        <Input
          label={dictionary.firstName}
          attributes={{
            disabled: props.disabled,
            defaultValue: props.model?.firstName,
            name: "firstName",
            required: true,
          }}
        />
        <Input
          label={dictionary.lastName}
          attributes={{
            disabled: props.disabled,
            defaultValue: props.model?.lastName,
            name: "lastName",
            required: true,
          }}
        />
      </FormRow>
      <FormRow>
        <Input
          label={dictionary.email}
          attributes={{
            disabled: props.disabled,
            defaultValue: props.model?.email,
            name: "email",
            type: "email",
            required: true,
          }}
        />
      </FormRow>
      <FormRow>
        <Select
          options={genderLabels}
          label={dictionary.gender}
          attributes={{
            disabled: props.disabled,
            defaultValue: props.model?.gender,
            name: "gender",
            required: true,
          }}
        />
      </FormRow>
      <FormRow>
        <Select
          options={languageLabels}
          label={dictionary.language}
          attributes={{
            disabled: props.disabled,
            defaultValue: props.model?.language ?? "NL",
            name: "language",
            required: true,
          }}
        />
      </FormRow>
      <FormRow>
        <DateInput
          label={dictionary.birthdate}
          attributes={{
            disabled: props.disabled,
            defaultValue: props.model?.dateOfBirth?.toString(),
            name: "dateOfBirth",
          }}
        />
      </FormRow>
      <FormRow>
        <Input
          label={dictionary.phone_number}
          attributes={{
            disabled: props.disabled,
            defaultValue: props.model?.phoneNumber,
            name: "phoneNumber",
          }}
        />
      </FormRow>
      <FormRow>
        <RichText
          label={dictionary.biography}
          name="biography"
          outputType="html"
          defaultValue={props.model?.biography}
        />
      </FormRow>
      <FormRow>
        <Input
          label={dictionary.website}
          attributes={{
            disabled: props.disabled,
            defaultValue: props.model?.website,
            name: "website",
          }}
        />
      </FormRow>
      <FormRow>
        <Select
          options={roles.reduce<Dictionary<string>>((prev, curr) => {
            prev[curr] = roleLabels[curr];
            return prev;
          }, {})}
          label={dictionary.role}
          attributes={{
            disabled: props.disabled,
            defaultValue:
              props.model?.role ?? (roles.length === 1 ? roles[0] : undefined),
            name: "role",
            required: true,
          }}
        />
      </FormRow>
      <FormRow>
        <FormRow>
          <Button
            attributes={{ type: "submit", disabled: props.disabled }}
            appearance="primary"
          >
            {dictionary.submit}
          </Button>
        </FormRow>
      </FormRow>
    </Form>
  );
};

const styles = {
  image: style({
    width: 200,
    height: 200,
    borderRadius: 200,
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundColor: "#c4bec4",
    margin: "0 auto",
    border: "1px solid #c4bec4",
  }),
};
