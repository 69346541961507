import { IUser } from "@hulanbv/ssllow-packages";
import React, { FC } from "react";

interface IProps {
  model: IUser;
}

export const UserOption: FC<IProps> = (props) => (
  <div title={props.model.email}>{props.model.fullName}</div>
);
