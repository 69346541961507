import { IQuestionReport } from "@hulanbv/ssllow-packages";
import React, { FC } from "react";
import { style } from "typestyle";
import { colors } from "../../constants/colors";
import { getLanguage } from "../../constants/i18n/dictionary";
import { IReportProps } from "../../interfaces/report-props.interface";
import { Dictionary } from "../../types/dictionary.type";

export const OverviewReport: FC<IReportProps> = (props) => {
  const questionResult: Dictionary<IQuestionReport> = {};
  for (const result of props.unfilteredResults) {
    questionResult[result.question.id] = result;
  }

  return (
    <div
      {...props.attributes}
      className={[styles.report, props.attributes?.className].join(" ")}
    >
      <table className={styles.table}>
        <thead>
          <tr>
            <th className={styles.numberCol}></th>
            <th className={styles.indicatorCol}>
              {getLanguage(props.report.survey?.language).indicator}
            </th>
            <th className={styles.indicatorCol}>
              {getLanguage(props.report.survey?.language).derivative}
            </th>
            <th className={styles.indicatorCol}>
              {getLanguage(props.report.survey?.language).obstructive}
            </th>
            <th className={styles.indicatorCol}>
              {getLanguage(props.report.survey?.language).stimulative}
            </th>
          </tr>
        </thead>
        <tbody>
          {props.results.map((result, i) => (
            <tr key={i}>
              <td>{i + 1}.</td>
              <td>
                <div
                  className={styles.resultType}
                  style={{
                    background:
                      colors.resultTypesPosOrNeg[
                        (props.section.showPartnerResultType
                          ? result.partnerResultType
                          : result?.resultType) ?? result?.resultType!
                      ],
                  }}
                />{" "}
                {result.question.name}
              </td>
              {Array(3)
                .fill(null)
                .map((x, i) => {
                  const triangleResult =
                    questionResult[result.question.triangleIds[i]];
                  return (
                    <td key={i}>
                      <div
                        className={styles.resultType}
                        style={{
                          background:
                            colors.resultTypesPosOrNeg[
                              (props.section.showPartnerResultType
                                ? triangleResult?.partnerResultType
                                : triangleResult?.resultType) ??
                                triangleResult?.resultType!
                            ],
                        }}
                      />{" "}
                      {triangleResult?.question.name}
                    </td>
                  );
                })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const styles = {
  report: style({
    fontSize: 14,
  }),

  numberCol: style({
    width: "8%",
  }),
  indicatorCol: style({
    width: "23%",
  }),

  resultType: style({
    width: 10,
    height: 10,
    marginRight: 5,
    borderRadius: "100%",
    display: "inline-block",
  }),

  table: style({
    width: "100%",
    borderCollapse: "collapse",

    $nest: {
      "& th, & td": {
        padding: 8,
      },
      "& th": {
        textAlign: "left",
        paddingBottom: 2,
      },
      "& td": {
        borderLeft: "1px solid #2d2d2d",
        borderBottom: "1px solid #2d2d2d",
      },
      "& td:first-child": {
        borderLeftWidth: 0,
      },

      "& tr:nth-child(even) td": {
        background: "#f5f5f5",
      },
    },
  }),
};
