import { IOrganisation, Role } from "@hulanbv/ssllow-packages";
import React, { FC } from "react";
import { Router } from "react-history-router";
import { Button } from "../components/controls/button";
import { routes } from "../components/routing/routes";
import { CrudTable } from "../components/tables/crud-table";
import { OrganisationRow } from "../components/tables/custom-rows/organisation-row";
import { dictionary } from "../constants/i18n/dictionary";
import { IViewProps } from "../interfaces/view-props.interface";
import { organisationService } from "../services/organisation.service";
import { satisfiesRoles } from "../utilities/satisfies-roles";

export const OrganisationsView: FC<IViewProps> = (props) => (
  <CrudTable<IOrganisation>
    service={organisationService}
    sortKey="createdAt"
    headings={{
      imageThumbPath: "",
      name: dictionary.name,
      city: dictionary.city,
    }}
    customRow={(organisation) => (
      <OrganisationRow
        key={organisation.id}
        organisation={organisation}
        attributes={{
          onClick: () =>
            Router.push(routes.organisationDetails.path, {
              id: organisation.id,
            }),
        }}
      />
    )}
    customControls={
      satisfiesRoles(Role.ADMIN, Role.EXPERT) && (
        <Button
          attributes={{
            onClick: () => Router.push(routes.createOrganisation.path),
          }}
          appearance={"primary"}
        >
          {dictionary.new}
        </Button>
      )
    }
  />
);
