export const questionContextCodes = {
  "Naam deelnemer": "name",
  "Hij/zij o.b.v. geslacht deelnemer": "refer",
  "Hem/haar o.b.v. geslacht deelnemer": "referobjective",
  "Zijn/haar o.b.v. geslacht deelnemer": "possessive",
  "nooit/incidenteel/soms/meestal/altijd o.b.v. antwoord": "answer",
};

export const reportContextCodes = {
  "Expert codes": {
    "Voornaam expert": "expert.firstName",
    "Achternaam expert": "expert.lastName",
    "Email expert": "expert.email",
    "Telefoonnummer expert": "expert.phoneNumber",
    "Biografie expert": "expert.biography",
    "Website expert": "expert.website",
  },
  "Deelnemer codes": {
    "Voornaam deelnemer": "user.firstName",
    "Achternaam deelnemer": "user.lastName",
    "Email deelnemer": "user.email",
    "Telefoonnummer deelnemer": "user.phoneNumber",
    "Biografie deelnemer": "user.biography",
    "Website deelnemer": "user.website",
  },
  "Gesprekspartner codes": {
    "Voornamen van alle gesprekspartners": "participants.firstName",
    "Achternamen van alle gesprekspartners": "participants.lastName",
    "Emails van alle gesprekspartners": "participants.email",
  },
  "Team codes": {
    "Naam van het team": "team.name",
  },
  "Survey codes": {
    "Naam van de survey": "survey.name",
  },
  "Organisatie codes": {
    "Naam van de organisatie": "organisation.name",
    "Postcode van de organisatie": "organisation.postalCode",
    "Adres van de organisatie": "organisation.address",
    "Vestigingsplaats van de organisatie": "organisation.city",
  },
  "Sjabloon codes": {
    "Naam van het sjabloon": "template.name",
  },
};

export const inviteContextCodes = {
  "Deelnemer codes": {
    "Voornaam deelnemer": "user.firstName",
    "Achternaam deelnemer": "user.lastName",
    "Email deelnemer": "user.email",
    "Telefoonnummer deelnemer": "user.phoneNumber",
    "Biografie deelnemer": "user.biography",
    "Website deelnemer": "user.website",
  },
  "Survey codes": {
    "Naam van de survey": "survey.name",
  },
  "Organisatie codes": {
    "Naam van de organisatie": "organisation.name",
    "Postcode van de organisatie": "organisation.postalCode",
    "Adres van de organisatie": "organisation.address",
    "Vestigingsplaats van de organisatie": "organisation.city",
  },
  "Uitnodiging codes": {
    "Deadline van de uitnodiging": "invite.deadline",
    "Maximaal aantal gesprekspartners": "invite.maxParticipants",
    "Toegangscode van de uitnodiging": "invite.accessCode",
  },
};
