import { IOrganisation } from "@hulanbv/ssllow-packages";
import { CrudService } from "nest-utilities-client";
import { environment } from "../constants/environment.constant";
import { httpService } from "./http.service";

class Service extends CrudService<IOrganisation> {
  constructor() {
    super(
      [environment.REACT_APP_API_URL, "organisation"].join("/"),
      httpService
    );
  }
}

export const organisationService = new Service();
