import React, { FC, useEffect, useState } from "react";
import Router from "react-history-router";
import { style } from "typestyle";
import { IRouteOptions } from "../../interfaces/route-options.interface";
import { routeListener } from "../../observables/route-listener";

interface IProps {
  route: IRouteOptions;
}

export const NavigationItem: FC<IProps> = (props) => {
  const [path, setPath] = useState<string>(document.location.pathname);
  const isActive = path.includes(props.route.path);

  useEffect(() => {
    const listener = routeListener.subscribe(() => {
      setPath(document.location.pathname);
    });

    return () => listener.unsubscribe();
  }, []);

  return (
    <a
      className={[styles.a, isActive ? styles.active : ""].join(" ")}
      onClick={(e) => {
        e.preventDefault();
        Router.push(props.route.path);
      }}
      href={props.route.path}
    >
      {props.children}
    </a>
  );
};

const styles = {
  a: style({
    display: "flex",
    cursor: "pointer",
    marginBottom: 30,
    transition: ".1s opacity",
    textDecoration: "none",
    color: "#FFF",
    opacity: 0.6,
    fontWeight: 400,
    $nest: {
      "&:hover": {
        opacity: 0.8,
      },
      img: {
        height: 20,
        marginRight: 15,
      },
    },
  }),

  active: style({
    opacity: 1,
  }),
};
