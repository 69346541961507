import { IQuestion, QuestionType } from "@hulanbv/ssllow-packages";
import { CrudService, IHttpOptions, IResponse } from "nest-utilities-client";
import { environment } from "../constants/environment.constant";
import { languages } from "../constants/i18n/dictionary";
import { getScoreLabels } from "../constants/score-labels";
import { httpService } from "./http.service";

class Service extends CrudService<IQuestion> {
  constructor() {
    super([environment.REACT_APP_API_URL, "question"].join("/"), httpService);
  }

  getBySurvey(
    surveyId: string,
    options?: IHttpOptions
  ): Promise<IResponse<IQuestion[]>> {
    return httpService.get(
      [this.controller, "survey", surveyId].join("/"),
      options
    );
  }

  /**
   * Gets the closest answer label to a score.
   * Constructive answers get the corresponding point or the nearest higher point
   * Destructive answers get the corresponding point or the nearest lower point
   *
   * for example a score of 62:
   *
   * Constructive: [0, 20, 40, 60, 80]
   * Would return: 80 -> dictionary.always
   *
   * Destructive: [100, 80, 60, 40, 20]
   * Would return: 40 -> dictionary.mostly
   *
   * @param question
   * @param score
   */
  getClosestAnswer(
    question: IQuestion,
    score?: number,
    language?: keyof typeof languages | string
  ): string {
    const scoreLabels = getScoreLabels(language);
    if (score === undefined) {
      return "";
    }

    // subtract 100 points from answers to destructive questions
    if (question.type === QuestionType.DESTRUCTIVE) {
      score -= 100;
    }

    // sort the points array to support positive destructive questions
    const points = [...question.points].sort((a, b) =>
      question.type === QuestionType.CONSTRUCTIVE ? a - b : b - a
    );
    for (const point of points) {
      if (
        (question.type === QuestionType.CONSTRUCTIVE && score <= point) ||
        (question.type === QuestionType.DESTRUCTIVE && score >= point)
      ) {
        return scoreLabels[question.points.indexOf(point)];
      }
    }
    return scoreLabels[points.pop() || 0];
  }
}

export const questionService = new Service();
