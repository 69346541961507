import React, { FC } from "react";

interface IProps {
  background: [string, string?];
}

// configure wave frequency and size
const size = 750;
const waveH = 150;
const nWaves = 2;

const waves: number[] = [];
const waveSteps = [0, waveH / 2, waveH, waveH / 2];
for (let i = 0; i < nWaves; i++) {
  for (let j = 0; j < 4; j++) {
    waves.push(((1 * i + i * 3 + j) * (size / nWaves)) / 3);
    waves.push(waveSteps[j]);
  }
}

export const Wave: FC<IProps> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox={`0 0 ${size} ${size + waveH}`}
  >
    <defs>
      <radialGradient id="radialGradient">
        <stop offset="0%" stopColor={props.background[0]} />
        <stop
          offset="120%"
          stopColor={props.background[1] ?? props.background[0]}
        />
      </radialGradient>
    </defs>

    <path
      fill="url(#radialGradient)"
      vectorEffect="non-scaling-stroke"
      d={`M 0 ${waveH / 2} Q ${waves.join(" ")} L ${size} ${size + waveH} 0 ${
        size + waveH
      } 0 0`}
    />
  </svg>
);
