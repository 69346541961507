import {
  Gender,
  IInvite,
  IOrganisation,
  ISurvey,
  IUser,
} from "@hulanbv/ssllow-packages";
import _get from "lodash/get";
import { getLanguage, languages } from "../constants/i18n/dictionary";

export const hydrateString = (
  text: string,
  data: {
    organisation?: IOrganisation | null;
    survey?: ISurvey | null;
    user?: Partial<IUser> | null;
    expert?: IUser | null;
    invite?: IInvite | null;
  },
  language?: keyof typeof languages | string
): string => {
  const scopedDictionary = getLanguage(language);
  if (!text) {
    return "";
  }

  if (!data.expert && data.organisation) {
    data.expert = data.organisation?.experts?.[0];
  }

  if (data.user) {
    const isMale = data.user.gender === Gender.MALE;
    text = text.replace(/\{name\}/gi, data.user.firstName ?? "");
    text = text.replace(
      /\{possessive\}/gi,
      isMale
        ? scopedDictionary.his.toLowerCase()
        : scopedDictionary.her.toLowerCase()
    );
    text = text.replace(
      /\{refer\}/gi,
      isMale
        ? scopedDictionary.he.toLowerCase()
        : scopedDictionary.she.toLowerCase()
    );
    text = text.replace(
      /\{referObjective\}/gi,
      isMale
        ? scopedDictionary.him.toLowerCase()
        : scopedDictionary.her.toLowerCase()
    );
  }

  // select all occurrences of {...} in the string
  const selectors = Array.from(new Set(text.match(/\{[^{]*\}/g) || []));
  selectors.forEach((selector) => {
    const regex = new RegExp(selector, "g");
    const path = selector.replace(/(\{|\})/g, "");
    let value = _get(data, path, "-");

    // add an exception for the invite deadline
    // if (path === "invite.deadline") {
    //   value = moment(value).add(2, "hours").format("DD-MM-YYYY");
    // }

    text = text.replace(regex, value);
  });

  return text;
};
