import { IReport } from "@hulanbv/ssllow-packages";
import React, { FC, useEffect, useState } from "react";
import Router from "react-history-router";
import { style } from "typestyle";
import { Card } from "../components/containers/card";
import { ReportForm } from "../components/forms/report-form";
import { routes } from "../components/routing/routes";
import { IViewProps } from "../interfaces/view-props.interface";
import { reportService } from "../services/report.service";

export const ReportEditView: FC<IViewProps> = (props) => {
  const [report, setReport] = useState<IReport | null>(null);

  const saveReport = async (report: FormData) => {
    const { data } = await reportService[
      props.routing.params.id ? "patch" : "post"
    ](report);
    Router.push(routes.reportDetails.path, { id: data.id });
  };

  useEffect(() => {
    const fetchReport = async () => {
      if (!props.routing.params.id) {
        return;
      }

      try {
        const { data } = await reportService.get(props.routing.params.id, {
          populate: [],
        });
        setReport(data);
      } catch {
        Router.replace(routes.reports.path);
      }
    };

    fetchReport();
  }, [props.routing.params.id]);

  if (props.routing.params.id && !report) {
    return <></>;
  }
  return (
    <Card attributes={{ className: styles.card }}>
      <ReportForm
        model={report || undefined}
        isTeamReport={!props.routing.params.id || !report?.userId}
        onSubmit={saveReport}
      />
    </Card>
  );
};

const styles = {
  card: style({
    maxWidth: 650,
    margin: "0 auto",
  }),
};
