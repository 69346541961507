import React, { FC, HTMLAttributes } from "react";
import ReactDOMServer from "react-dom/server";
import { style } from "typestyle";
import { toRadialGradient } from "../../utilities/to-radial-gradient.utils";
import { Wave } from "./wave";

interface IProps {
  attributes?: HTMLAttributes<HTMLDivElement>;
  filled: number;
  foreground: [string, string?];
  background: [string, string?];
}

export const Globe: FC<IProps> = (props) => (
  <div
    {...props.attributes}
    className={[styles.globe, props.attributes?.className].join(" ")}
    style={{
      background: toRadialGradient(props.background),
      ...props.attributes?.style,
    }}
  >
    <div className={styles.content}>{props.children}</div>
    <div
      className={styles.background}
      style={{
        top: `${100 - props.filled}%`,

        backgroundImage:
          props.filled <= 100
            ? `url('data:image/svg+xml;utf8,${encodeURIComponent(
                ReactDOMServer.renderToStaticMarkup(
                  <Wave background={props.foreground} />
                )
              )}')`
            : undefined,
      }}
    />
  </div>
);

const styles = {
  globe: style({
    borderRadius: "100%",
    width: 250,
    height: 250,
    border: "4px solid #fff",
    backgroundColor: "#d0d0d0",
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "column",
    position: "relative",
    overflow: "hidden",
  }),

  background: style({
    width: "100%",
    height: "100%",
    position: "absolute",
    backgroundSize: "cover",
    left: 0,
    top: 0,
  }),

  content: style({
    width: "100%",
    height: "100%",
    position: "absolute",
    left: 0,
    top: 0,
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexDirection: "column",
  }),
};
