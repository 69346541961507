import { IGenre } from "@hulanbv/ssllow-packages";
import React, { FC } from "react";
import { Router } from "react-history-router";
import { Button } from "../components/controls/button";
import { routes } from "../components/routing/routes";
import { CrudTable } from "../components/tables/crud-table";
import { dictionary } from "../constants/i18n/dictionary";
import { IViewProps } from "../interfaces/view-props.interface";
import { genreService } from "../services/genre.service";

export const GenresView: FC<IViewProps> = (props) => (
  <CrudTable<IGenre>
    service={genreService}
    sortKey="name"
    headings={{
      name: dictionary.name,
    }}
    onRowClick={(genre) => Router.push(routes.editGenre.path, { id: genre.id })}
    customControls={
      <Button
        attributes={{
          onClick: () => Router.push(routes.createGenre.path),
        }}
        appearance={"primary"}
      >
        {dictionary.new}
      </Button>
    }
  />
);
