import {
  IQuestionReport,
  IReport,
  IReportTemplateSection,
  SectionType,
} from "@hulanbv/ssllow-packages";
import React, { FC, useMemo } from "react";
import { classes, style } from "typestyle";
import { dictionary } from "../../constants/i18n/dictionary";
import { dialog } from "../../singletons/dialog";
import { Button } from "../controls/button";
import { Pill } from "../controls/pill";
import { HideOnPrint } from "../elements/hide-on-print";
import { ReportTemplateSectionForm } from "../forms/report-template-section-form";
import { Page } from "./page";
import { SectionBundleItem } from "./section-bundle-item";
import { reportService } from "../../services/report.service";

interface IProps {
  sections: IReportTemplateSection[];
  previousSections?: IReportTemplateSection[];
  results: IQuestionReport[];
  report: IReport;
  reviewColors: Record<string, string>;

  onDelete?: (sections: IReportTemplateSection[]) => void | Promise<void>;
  onUpdate?: (section: IReportTemplateSection) => void | Promise<void>;
}

export const SectionBundle: FC<IProps> = (props) => {
  const filteredResults = useMemo(() => {
    return props.sections.map((section, i) =>
      reportService.filterResultsBySection(
        props.results,
        section,
        props.report,

        props.sections[i - 1] ??
          props.previousSections?.[props.previousSections.length - 1]
      )
    );
  }, [props.previousSections, props.report, props.results, props.sections]);
  const isHiddenOnPrint: boolean = filteredResults.every(
    (results, i) =>
      ![
        SectionType.TEXT,
        SectionType.EXTENDED_LEGEND,
        SectionType.LEGEND,
      ].includes(props.sections[i].type) && results.length === 0
  );

  return (
    <Page
      attributes={{
        className: classes(styles.page, isHiddenOnPrint && styles.hideOnPrint),
      }}
    >
      <HideOnPrint>
        <div className={styles.controls}>
          {isHiddenOnPrint && (
            <Pill size="small">{dictionary.is_hidden_on_print}</Pill>
          )}
          {props.onDelete && (
            <Button
              size="small"
              attributes={{
                onClick: () => {
                  if (window.confirm(dictionary.confirm_deletion)) {
                    props.onDelete?.(props.sections);
                  }
                },
              }}
            >
              {dictionary.delete}
            </Button>
          )}
          {props.onUpdate && props.sections.length === 1 && (
            <Button
              size="small"
              attributes={{
                onClick: () =>
                  dialog.mount({
                    title: dictionary.edit_section,
                    children: (
                      <ReportTemplateSectionForm
                        model={props.sections[0]}
                        onSubmit={async (data, model) => {
                          await props.onUpdate?.(model);
                          dialog.unmount();
                        }}
                      />
                    ),
                  }),
              }}
            >
              {dictionary.edit}
            </Button>
          )}
        </div>
      </HideOnPrint>

      <div className={styles.bundleWrapper}>
        {props.sections.map((section, i) => (
          <SectionBundleItem
            bundleIndex={i}
            bundleSize={props.sections.length}
            key={i}
            report={props.report}
            section={section}
            results={props.results}
            filteredResults={filteredResults[i]}
            reviewColors={props.reviewColors}
            previousSection={
              props.sections[i - 1] ??
              props.previousSections?.[props.previousSections.length - 1]
            }
          />
        ))}
      </div>
    </Page>
  );
};

const styles = {
  page: style({
    flex: 1,
  }),
  pageBreak: style({
    position: "relative",
    margin: "0 auto",
    display: "block",
  }),
  controls: style({
    display: "flex",
    flexDirection: "row-reverse",

    $nest: {
      "& > *": {
        width: "auto",
        margin: "0 5px",
      },
    },
  }),
  center: style({
    display: "block",
    textAlign: "center",
  }),
  hideOnPrint: "hide-on-print",
  userTag: style({
    position: "relative",
    margin: "0 auto",
    width: "fit-content",
  }),
  bundleWrapper: style({}),
};
